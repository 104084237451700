<template>
  <div id="machineryManagement">
    <div class="flex row top-bar_wrapper">
      <div
        v-for="(item,index) in barList"
        :key="index"
        class="progress-name  pointer"
        :class="{'progress-name-active':item.name === barText}"
        @click="barText = item.name"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="com-wrapper" v-if="barText === '大型机械管理' ">
      <regionMachineManagement></regionMachineManagement>
    </div>
    <div class="com-wrapper" v-if="barText === '季度机械设备检查通报' ">
      <machineReportList></machineReportList>
    </div>
  </div>
</template>
<script>
import machineReportList from './machineReportList'
import regionMachineManagement from './regionMachineManagement'

let _this = void 0
export default {
  name: 'machineryManagement',
  mixins: [],
  components: {
    regionMachineManagement,
    machineReportList,
  },
  props: {},
  data() {
    return {
      barText: '大型机械管理',
      barList: [{ name: '大型机械管理' }, { name: '季度机械设备检查通报' }]
      
    }
  },
  computed: {},
  watch: {},
  directives: {},
  created() {
    _this = this
  },
  mounted() {
  },
  destroy() {
  },
  methods: {}
}
</script>
<style
  lang="scss"
  scoped
>
#machineryManagement {
  height: 100%;
}
.com-wrapper{
  height: 100%;
}
::v-deep .el-link.el-link--primary {
  color: #005BAC;
}
</style>
<style lang="scss"></style>
