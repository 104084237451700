<template>
  <div id="machineryManagement">
    <div class="flex row top-bar_wrapper">
      <div
        v-for="(item,index) in barList"
        :key="index"
        class="progress-name  pointer"
        :class="{'progress-name-active':item.name === barText}"
        @click="barText = item.name"
      >
        {{ item.name }}
      </div>
    </div>
    <div
      class="com-wrapper"
      v-if="barText === '施工现场标准化相关文件' "
    >
      <standardizationFiles></standardizationFiles>
    </div>
    <div
      class="com-wrapper"
      v-if="barText === '施工标准化过程检查通报' "
    >
      <standardizationNotification></standardizationNotification>
    </div>
    <div
      class="com-wrapper"
      v-if="barText === '创新和曝光做法' "
    >
      <innovationAndExposure></innovationAndExposure>
    </div>
  </div>
</template>
<script>
import standardizationNotification from './standardizationNotification'
import innovationAndExposure from './innovationAndExposure'
import standardizationFiles from './standardizationFiles'

let _this = void 0
export default {
  name: 'siteStandardization',
  mixins: [],
  components: {
    standardizationNotification,
    innovationAndExposure,
    standardizationFiles
  },
  props: {},
  data() {
    return {
      barText: '施工现场标准化相关文件',
      barList: [{ name: '施工现场标准化相关文件' }, { name: '施工标准化过程检查通报' }, { name: '创新和曝光做法' }]

    }
  },
  computed: {},
  watch: {},
  directives: {},
  created() {
    _this = this
  },
  mounted() {
  },
  destroy() {
  },
  methods: {}
}
</script>
<style
  lang="scss"
  scoped
>
#machineryManagement {
  height: 100%;
}

.com-wrapper {
  height: 100%;
}

::v-deep .el-link.el-link--primary {
  color: #005BAC;
}
</style>
<style lang="scss"></style>
