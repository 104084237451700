<template>
  <div class="standardization-notification-wrapper">
    <div
      class="flex flex1 justify-between item-center button-title__box"
    >
      <div class="flex justify-start">
        <GreenButton
          class="button__default"
          text="填报"
          @click="createChange"
        ></GreenButton>
      </div>
      <el-select
        v-model="categoryName"
        class="flex justify-end items-center"
        placeholder="请选择"
        size="small"
        @change="categoryChange"
      >
        <el-option
          v-for="({ label, value },categoryIndex) in categoryNameOptions"
          :key="`${label}-${value}-${categoryIndex}`"
          :label="label"
          :value="value"
        ></el-option>
      </el-select>
    </div>
    <el-table
      :data="projectData"
      :header-cell-style="{background: 'rgba(0,0,0,0.02)',borderTop:'1px solid rgba(0,0,0,0.1)'}"
      :loading="projectLoading"
      class="table-box"
      height="calc(100% - 165px)"
    >
      <el-table-column
        label="图片名称"
        prop="FileName"
      >
        <template #default="scope">
          <div
            class="file-download pointer"
            @click="downloadFileChange(scope.row)"
          >{{ scope.row['FileName'] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="图片描述"
        prop="Content"
      ></el-table-column>
      <el-table-column
        label="类型"
        prop="Category"
        width="140"
      ></el-table-column>
      <el-table-column
        label="创建时间"
        prop="CreationDate"
        width="140"
      >
        <template #default="scope">
          <span>{{ scope.row['CreationDate'] | timeDateFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="160"
      >
        <template #default="scope">
          <el-link
            type="primary"
            @click="editInfo(scope.row)"
          >
            编辑
          </el-link>
          <el-link
            style="margin-left: 1em;"
            type="primary"
            @click="deleteInfo(scope.row)"
          >
            删除
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <div
      class="table-box__pagination flex flex1 justify-end items-center"
    >
      <el-pagination
        :current-page.sync="pageIndex"
        :page-size.sync="pageSize"
        :total="allTotal"
        layout="total, prev, pager, next"
        @current-change="onPageIndexChange"
      ></el-pagination>
    </div>
    <CreateExposureDialog
      :id="currentID"
      :is-edit="isCreateEdit"
      :visible="createDialogVisible"
      @closeDialog="onImportDialogClose"
      @refresh="onRefreshDialogClose"
    ></CreateExposureDialog>
    <el-image-viewer
      v-if="isShowImage"
      :on-close="
          () => {
            isShowImage = false
          }
        "
      :url-list="srcList"
    ></el-image-viewer>
  </div>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'
import CreateExposureDialog from '@/components/dialog/CreateExposureDialog'
import { mapMutations } from 'vuex'

export default {
  name: 'innovationAndExposure',
  components: {
    GreenButton,
    CreateExposureDialog
  },
  filters: {
    timeDateFilter(date) {
      return typeof date === 'string' ? date.split('T')[0] : ''
    }
  },
  data() {
    return {
      pageIndex: 1,
      pageSize: 11,
      allTotal: 0,
      projectLoading: false,
      projectData: [],
      createDialogVisible: false,
      isCreateEdit: false,
      currentID: -1,
      categoryName: '',
      categoryNameOptions: [
        { label: '全部', value: '' },
        { label: '创新', value: 'Innovate' },
        { label: '曝光', value: 'Exposure' }
      ],
      isShowImage: false,
      srcList: []
    }
  },
  mounted() {
    this.getProjectInfo()
  },
  destroy() {
  },
  methods: {
    ...mapMutations('management', ['setInnovationAndExposureObjectInfo']),
    /**
     * 获取 创新和曝光做法 数据
     * */
    async getProjectInfo() {
      this.projectLoading = true
      const { success, data } = await this.$http({
        url: this.$api.getPageQueryConstructionStandard,
        params: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          category: this.categoryName
        }
      })
      if (success) {
        const { rows, total } = data
        if (data.hasOwnProperty('rows')) {
          this.projectData = [...rows]
          if (data.hasOwnProperty('total')) {
            this.allTotal = total
          }
        }
      }
      this.projectLoading = false
    },
    /**
     * 切换分页
     * */
    onPageIndexChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getProjectInfo()
    },
    /**
     * 填报 选择
     * */
    createChange() {
      this.createDialogVisible = true
    },
    /**
     * 填报 关闭dialog回调
     * */
    onImportDialogClose() {
      this.isCreateEdit = false
      this.createDialogVisible = false
    },
    /**
     * 填报 刷新dialog回调
     * */
    onRefreshDialogClose() {
      this.isCreateEdit = false
      this.createDialogVisible = false
      this.categoryName = ''
      this.pageIndex = 1
      this.getProjectInfo()
    },
    /**
     * 编辑信息
     * */
    async editInfo(item) {
      const { ID } = item
      await this.setInnovationAndExposureObjectInfo(item)
      this.currentID = ID
      this.isCreateEdit = true
      this.createDialogVisible = true
    },
    /**
     * 删除信息
     * */
    deleteInfo(item) {
      const { ID } = item
      this.$confirm('是否确认删除', `删除${this.categoryName === 'Innovate' ? '创新做法' : '曝光做法'}`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '删除中...'
            setTimeout(async () => {
              await this.deleteChange(ID)
              done()
              setTimeout(() => {
                instance.confirmButtonLoading = false
              }, 300)
            }, 300)
          }
          else {
            done()
          }
        }
      })
    },
    /**
     * 确认删除
     * */
    async deleteChange(ID) {
      const { success } = await this.$http({
        url: this.$api.goDeleteConstructionStandard,
        params: {
          id: ID
        }
      })
      if (success) {
        this.$message.success('删除成功')
        this.pageIndex = 1
        await this.getProjectInfo()
      }
    },
    /**
     * 下拉选择
     * */
    categoryChange(value) {
      this.pageIndex = 1
      this.categoryName = value
      this.getProjectInfo()
    },
    /**
     * 下载文件
     * */
    downloadFileChange(item) {
      const { FilePath: RemotePath, FileName: Name } = item
      if (RemotePath !== '') {
        this.srcList = []
        this.isShowImage = true
        const newUrl = `${WEB_CONFIG.BASE_URL}${RemotePath}`
        this.srcList.push(newUrl)
      }
      else {
        this.$message.error('下载失败')
      }
    }
  }
}
</script>
<style scoped>
.standardization-notification-wrapper {
  height: 100%
}

::v-deep .el-link.el-link--primary {
  color: #005BAC;
}
</style>
