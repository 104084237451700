<template>
  <el-dialog
    :append-to-body="true"
    :before-close="onBeforeClose"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :modal="false"
    title="季度机械设备检查通报"
    :visible.sync="dialogVisible"
    custom-class="common-dialog"
    top="10vh"
    width="500px"
  >
    <div class="tab">
      <div class="tab__content">
        <div class="flex justify-start">
          <span class="dialog-label">附件</span>
          <el-upload
            ref="upload"
            :auto-upload="false"
            :before-upload="beforeAvatarUpload"
            :file-list="linksList"
            :http-request="uploadFile"
            action=""
            :limit="1"
          >
            <div class="flex justify-start">
              <GreenButton
                slot="trigger"
                class="button__default"
                text="附件"
              ></GreenButton>
            </div>
          </el-upload>
        </div>
      </div>
      <div class="tab__footer flex row justify-end items-center">
        <GreenButton
          :options="{
                    borderColor: 'rgba(26,89,184,0.33)',
                    borderRadius: '5px',
                    color: '#1A59B8 !important'
          }"
          class="button__cancel  button__gap"
          text="取消"
          @click="cancelClick"
        ></GreenButton>
        <GreenButton
          :loading="createLoading"
          class="button__default"
          loading-text="处理中"
          text="确定"
          @click="onCreateClick"
        ></GreenButton>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'
import { mapGetters } from 'vuex'

export default {
  name: 'MachineryManagementDialog',
  mixins: [],
  components: {
    GreenButton
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      dialogVisible: false,
      createLoading: false,
      linksList: [],
      fileData: {}
    }
  },
  computed: {},
  watch: {
    visible(visible) {
      if (visible) {
        this.dialogVisible = true
        this.linksList = []
      }
    }
  },
  methods: {
    ...mapGetters('user', {
      USER_INFO: 'USER_INFO',
    }),
    onBeforeClose(done) {
      this.$emit('closeDialog')
      this.clearClick()
      done()
    },
    /**
     * 创建 季度机械设备检查通报
     * */
    async onCreateClick() {
      if (this.$refs.upload.uploadFiles.length === 0) {
        this.$message.error('请上传附件')
      }
      else {
        let tempDta = this.fileData
        this.fileData = new FormData()
        await this.$refs.upload.submit()
        await this.uploadChange()
      }
    },
    /**
     *  取消 事件
     * */
    cancelClick() {
      this.$emit('closeDialog')
      this.clearClick()
    },
    /**
     *  清空 事件
     * */
    clearClick() {
      this.linksList = []
      this.dialogVisible = false
      this.$refs.upload.clearFiles()
    },
    /**
     * 上传附件之前
     */
    beforeAvatarUpload() {
      //    beforeAvatarUpload(file) {
      //      const testing = file.name.substring(file.name.lastIndexOf('.') + 1)
      //      const extension = testing === 'xls'
      //      const extension2 = testing === 'xlsx'
      //      if (!extension && !extension2) {
      //        this.$message.error('只能上传xls与xlsx文件')
      //        return false
      //      }
    },
    /**
     * 上传附件
     */
    async uploadFile(files) {
      const { file } = files
      this.fileData.append('file', file)
    },
    /**
     * 上传 事件
     * */
    async uploadChange() {
      this.createLoading = true
      const { ID } = this.USER_INFO()
      this.fileData.append('UserID', ID)
      const { success } = await this.$http({
        url: this.$api.uploadCheckReport,
        method: 'POST',
        data: this.fileData
      })
      if (success) {
        this.$emit('refresh')
        this.$message.success('填报成功')
        this.clearClick()
      }
      else {
        this.$refs.upload.clearFiles()
      }
      this.createLoading = false
    }
  }
}
</script>
<style
  lang="scss"
  scoped
>
$tabsDefaultColor: #FFFFFF;
$tabsActiveColor: #42DEFD;
$borderColor: rgba(3, 175, 252, 0.66);

.tab {
  height: 160px;

  &__content {
    overflow: auto;
    height: calc(100% - 74px);
  }

  &__footer {
    height: 59px;
    margin: 1em -2.4em 0;
    padding: 0 2em;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    background-color: #FFFFFF;
  }
}

//dialog
::v-deep.el-dialog__wrapper {
  background-color: rgba(0, 0, 0, .5);
}

::v-deep .el-dialog__title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.85);
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.02) !important;
}

::v-deep .el-dialog__body {
  padding: 30px 34px 0;
}

//dialog
::v-deep .el-tabs {
  margin-bottom: 1em;

  &__header {
    margin: 0;
  }

  &__nav-wrap::after {
    background-color: transparent;
  }

  &__item {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    height: 31px;
    padding: 0 1em;
    color: $tabsDefaultColor;

    &.is-active {
      color: $tabsActiveColor;
    }
  }

  &__active-bar {
    color: $tabsActiveColor;
  }

  &__content {
    display: none;
  }
}

::v-deep .el-upload-list__item {
  max-width: 320px;
}
</style>
