<template>
  <div id="projectOrganization">
    <div class="flex row top-bar_wrapper">
      <div
        class="progress-name progress-name-active pointer"
      >项目群管理
      </div>
    </div>
    <div
      class="flex flex1 justify-between item-center button-title__box"
    >
      <div class="flex justify-start">
        <GreenButton
          class="button__default button__gap"
          text="导入"
          @click="importChange"
        ></GreenButton>
        <GreenButton
          :loading="downloadLoading"
          class="button__default"
          text="下载模板"
          loading-text="下载中"
          @click="downloadChange"
        ></GreenButton>
      </div>
    </div>
    <el-table
      :data="projectData"
      :header-cell-style="{background: 'rgba(0,0,0,0.02)',borderTop:'1px solid rgba(0,0,0,0.1)'}"
      :loading="projectLoading"
      class="table-box"
      height="calc(100% - 165px)"
      row-key="ID"
      :expand-row-keys="expands"
      :tree-props="{children: 'ProjectItemList'}"
    >
      <el-table-column
        label="组织名称"
        prop="Name"
      ></el-table-column>
      <el-table-column
        label="项目编号"
        prop="ProjectCode"
        width="150"
      ></el-table-column>
      <el-table-column
        label="负责人"
        prop="Manager"
        width="150"
      ></el-table-column>
      <el-table-column
        label="项目群指挥长"
        prop="Leader"
        width="150"
      ></el-table-column>
      <el-table-column
        label="项目群书记"
        prop="Secretary"
        width="150"
      ></el-table-column>
      <el-table-column
        label="操作"
        width="200"
      >
        <template #default="scope">
          <div v-if="!scope.row.hasOwnProperty('Checked')">
            <el-link
              type="primary"
              @click="editInfo(scope.row)"
            >
              查看管理汇报
            </el-link>
            <el-link
              type="primary"
              style="margin-left: 1em;"
              @click="managementProjectChange(scope.row)"
            >
              管理
            </el-link>
            <!--            <el-link-->
            <!--              style="margin-left: 1em;"-->
            <!--              type="primary"-->
            <!--              @click="deleteInfo(scope.row)"-->
            <!--            >-->
            <!--              删除-->
            <!--            </el-link>-->
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div
      class="wot__footer flex flex1 justify-end items-center"
    >
      <el-pagination
        :current-page.sync="pageIndex"
        :page-size.sync="pageSize"
        :total="allTotal"
        layout="total, prev, pager, next"
        @current-change="onPageIndexChange"
      ></el-pagination>
    </div>
    <ImportOrganizationDialog
      :visible="importDialogVisible"
      @closeDialog="onImportDialogClose('import')"
      @refresh="onRefreshDialogClose('import')"
    ></ImportOrganizationDialog>
    <ProjectOrganizationDialog
      :id="currentID"
      :is-edit="isCreateEdit"
      :visible="createDialogVisible"
      @closeDialog="onImportDialogClose('create')"
      @refresh="onRefreshDialogClose('create')"
    ></ProjectOrganizationDialog>
    <ManagementProjectDialog
      :id="currentID"
      :visible="managementDialogVisible"
      @closeDialog="onImportDialogClose('management')"
      @refresh="onRefreshDialogClose('management')"
    ></ManagementProjectDialog>
  </div>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'
import StatusTag from '@/components/StatusTag'
import ImportOrganizationDialog from '@/components/dialog/ImportOrganizationDialog'
import ProjectOrganizationDialog from '@/components/dialog/ProjectOrganizationDialog'
import ManagementProjectDialog from '@/components/dialog/ManagementProjectDialog'


let _this = void 0
//import { mapState, mapMutations } from 'vuex'

export default {
  name: 'projectOrganization',
  mixins: [],
  components: {
    GreenButton,
    StatusTag,
    ImportOrganizationDialog,
    ProjectOrganizationDialog,
    ManagementProjectDialog
  },
  props: {},
  data() {
    return {
      pageIndex: 1,
      pageSize: 11,
      allTotal: 0,
      projectLoading: false,
      projectData: [],
      importDialogVisible: false,
      createDialogVisible: false,
      managementDialogVisible: false,
      isCreateEdit: false,
      currentID: -1,
      expands: [],
      downloadLoading: false
    }
  },
  computed: {
    //...mapState('', {}),
  },
  watch: {},
  filters: {},
  directives: {},
  created() {
    _this = this
  },
  mounted() {
    this.getProjectInfo()
  },
  destroy() {
  },
  methods: {
    //...mapMutations('', []),
    /**
     * 获取 项目群组织 数据
     * */
    async getProjectInfo() {
      this.projectLoading = true
      this.expands = []
      const { success, data } = await this.$http({
        url: this.$api.getPageQueryProjectCluster,
        params: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
        }
      })
      if (success) {
        const { rows, total } = data
        if (data.hasOwnProperty('rows')) {
          this.projectData = [...rows]
          if (data.hasOwnProperty('total')) {
            this.allTotal = total
          }
          if (rows.length > 0) {
            this.expands.push(rows[0].ID.toString())
          }
        }
      }
      this.projectLoading = false
    },
    /**
     * 切换分页
     * */
    onPageIndexChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getProjectInfo()
    },
    /**
     * 导入选择
     * */
    importChange() {
      this.importDialogVisible = true
    },
    /**
     * 下载模板 选择
     * */
    async downloadChange() {
      this.downloadLoading = true
      const { success, data } = await this.$http({
        url: this.$api.goDownloadTemplatePath
      })
      if (success) {
        window.downloadFile(`${WEB_CONFIG.BASE_URL}${data}`)
        this.$message.success('下载成功')
      }
      this.downloadLoading = false
    },
    /**
     * 填报 选择
     * */
    createChange() {
      this.createDialogVisible = true
    },
    /**
     * 导入、填报和管理 关闭dialog回调
     * */
    onImportDialogClose(type) {
      if (type === 'import') {
        this.importDialogVisible = false
      }
      else if (type === 'create') {
        this.isCreateEdit = false
        this.createDialogVisible = false
      }
      else if (type === 'management') {
        this.managementDialogVisible = false
      }
    },
    /**
     * 导入、填报和管理 刷新dialog回调
     * */
    onRefreshDialogClose(type) {
      if (type === 'import') {
        this.importDialogVisible = false
      }
      else if (type === 'create') {
        this.isCreateEdit = false
        this.createDialogVisible = false
      }
      else if (type === 'management') {
        this.managementDialogVisible = false
      }
      this.pageIndex = 1
      this.getProjectInfo()
    },
    /**
     * 管理项目
     * */
    managementProjectChange(item) {
      const { ID } = item
      this.currentID = ID
      this.managementDialogVisible = true
    },
    /**
     * 编辑信息
     * */
    editInfo(item) {
      const { ID } = item
      this.currentID = ID
      this.isCreateEdit = true
      this.createDialogVisible = true
    },
    /**
     * 删除信息 暂时不用
     * */
    deleteInfo(item) {
      const { ID } = item
      this.$confirm('是否确认删除', '删除项目群组织', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '删除中...'
            setTimeout(async () => {
              await this.deleteChange(ID)
              done()
              setTimeout(() => {
                instance.confirmButtonLoading = false
              }, 300)
            }, 300)
          }
          else {
            done()
          }
        }
      })
    },
    /**
     * 确认删除
     * */
    async deleteChange(ID) {
      const { success } = await this.$http({
        url: this.$api.goDeleteProjectCluster,
        params: {
          id: ID
        }
      })
      if (success) {
        this.$message.success('删除成功')
        this.pageIndex = 1
        await this.getProjectInfo()
      }
    }
  }
}
</script>
<style
  lang="scss"
  scoped
>
#projectOrganization {
  height: 100%;
}

::v-deep .el-link.el-link--primary {
  color: #005BAC;
}
</style>
<style lang="scss"></style>
