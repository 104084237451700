<template>
  <div class="standardization-notification-wrapper">
    <div
      class="flex flex1 justify-between item-center button-title__box"
    >
      <div class="flex justify-start">
        <GreenButton
          class="button__default"
          text="导入"
          @click="createChange"
        ></GreenButton>
      </div>
    </div>
    <el-table
      :data="projectData"
      :header-cell-style="{background: 'rgba(0,0,0,0.02)',borderTop:'1px solid rgba(0,0,0,0.1)'}"
      :loading="projectLoading"
      :span-method="objectSpanMethod"
      class="table-box"
      height="calc(100% - 165px)"
    >
      <el-table-column
        label="区域"
        prop="Area"
        width="160"
      ></el-table-column>
      <el-table-column
        label="作业类别"
        prop="FirstCategory"
        width="200"
      ></el-table-column>
      <el-table-column
        label="类型"
        prop="SecondCategory"
        width="200"
      ></el-table-column>
      <el-table-column
        label="公司"
        prop="Company"
      ></el-table-column>
    </el-table>
    <div
      class="table-box__pagination flex flex1 justify-end items-center"
    >
      <el-pagination
        :current-page.sync="pageIndex"
        :page-size.sync="pageSize"
        :total="allTotal"
        layout="total, prev, pager, next"
        @current-change="onPageIndexChange"
      ></el-pagination>
    </div>
    <ImportProjectStatus
      :visible="importDialogVisible"
      type="resources"
      @refresh="onRefreshDialogClose"
      @closeDialog="onImportDialogClose"
    ></ImportProjectStatus>
  </div>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'
import ImportProjectStatus from '@/components/dialog/ImportProjectStatus'

export default {
  name: 'subcontractingResources',
  components: {
    GreenButton,
    ImportProjectStatus
  },
  filters: {},
  data() {
    return {
      pageIndex: 1,
      pageSize: 11,
      allTotal: 0,
      projectLoading: false,
      projectData: [],
      importDialogVisible: false
    }
  },
  async mounted() {
    await this.getProjectInfo()
  },
  destroy() {
    // TODO document why this method 'destroy' is empty
  },
  methods: {
    /**
     * 获取 主要资源分包 数据
     * */
    async getProjectInfo() {
      this.projectLoading = true
      const { success, data } = await this.$http({
        url: this.$api.getPageQuerySubpackageResource,
        params: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
        }
      })
      if (success) {
        const { rows, total } = data
        if (data.hasOwnProperty('rows')) {
          this.projectData = [...rows]
          if (data.hasOwnProperty('total')) {
            this.allTotal = total
          }
        }
      }
      this.projectLoading = false
    },
    /**
     * 切换分页
     * */
    onPageIndexChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getProjectInfo()
    },
    /**
     * 导入
     * */
    createChange() {
      this.importDialogVisible = true
    },
    /**
     * 导入 关闭dialog回调
     * */
    onImportDialogClose() {
      this.importDialogVisible = false
    },
    /**
     * 导入 刷新dialog回调
     * */
    onRefreshDialogClose() {
      this.importDialogVisible = false
      this.pageIndex = 1
      this.getProjectInfo()
    },
    /**
     * table 根据条件进行合并列
     * */
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = (this.getMergeTableData(this.projectData).one)[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (columnIndex === 1) {
        const _row = (this.getMergeTableData(this.projectData).two)[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (columnIndex === 2) {
        const _row = (this.getMergeTableData(this.projectData).three)[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    /**
     * table 条件合并
     * */
    getMergeTableData() {
      let spanOneArr = [],
        spanTwoArr = [],
        spanThreeArr = [],
        concatOne = 0,
        concatTwo = 0,
        concatThree = 0
      this.projectData.forEach((item, index) => {
        if (index === 0) {
          spanOneArr.push(1)
          spanTwoArr.push(1)
          spanThreeArr.push(1)
        }
        else {
          if (item.Area === this.projectData[index - 1].Area) { //第一列需合并相同内容的判断条件 区域
            spanOneArr[concatOne] += 1
            spanOneArr.push(0)
          }
          else {
            spanOneArr.push(1)
            concatOne = index
          }
          if (item.FirstCategory === this.projectData[index - 1].FirstCategory) { //第二列需合并相同内容的判断条件 作业类别
            if (item.Area === this.projectData[index - 1].Area) { //第一列需合并相同内容的判断条件 区域
              spanTwoArr[concatTwo] += 1
              spanTwoArr.push(0)
            }
            else {
              spanTwoArr.push(1)
              concatTwo = index
            }
          }
          else {
            spanTwoArr.push(1)
            concatTwo = index
          }
          if (item.SecondCategory === this.projectData[index - 1].SecondCategory) { //第三列需合并相同内容的判断条件 类型
            if (item.Area === this.projectData[index - 1].Area) { //第一列需合并相同内容的判断条件 区域
              spanThreeArr[concatThree] += 1
              spanThreeArr.push(0)
            }
            else {
              spanThreeArr.push(1)
              concatThree = index
            }
          }
          else {
            spanThreeArr.push(1)
            concatThree = index
          }
        }
      })
      return {
        one: spanOneArr,
        two: spanTwoArr,
        three: spanThreeArr
      }
    }
  }
}
</script>
<style scoped>
.standardization-notification-wrapper {
  height: 100%
}
</style>
