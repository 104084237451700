var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'wo-calendar__button': true,
    'wo-calendar__button--disabled': _vm.disabled
  },style:({
    padding: '0 10px',
    minWidth: _vm.width,
    border: ("1px solid " + (_vm.options.borderColor)),
    background: 'linear-gradient(180deg, #00B377, #00931E)',
    borderRadius: _vm.options.borderRadius,
    height: _vm.options.height ? _vm.options.height : '28px'
  }),on:{"click":_vm.onClick}},[(_vm.loading)?_c('i',{staticClass:"el-icon-loading",staticStyle:{"margin-right":"5px","color":"#ffffff"}}):_vm._e(),(_vm.loading)?_c('span',{staticClass:"wo-calendar__button-text",style:({
    color: _vm.options.color,
  })},[_vm._v(" "+_vm._s(_vm.loadingText)+" ")]):_c('span',{staticClass:"wo-calendar__button-text",style:({
    color: _vm.options.color,
  })},[_vm._v(" "+_vm._s(_vm.text)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }