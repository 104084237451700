<template>
  <div
    :class="{
      'wo-calendar__button': true,
      'wo-calendar__button--disabled': disabled
    }"
    :style="{
      padding: '0 10px',
      minWidth: width,
      border: `1px solid ${options.borderColor}`,
      background: 'linear-gradient(180deg, #00B377, #00931E)',
      borderRadius: options.borderRadius,
      height: options.height ? options.height : '28px'
    }"
    @click="onClick"
  >
    <i
      v-if="loading"
      class="el-icon-loading"
      style="margin-right:5px;color:#ffffff"
    ></i>
    <span
      v-if="loading"
      class="wo-calendar__button-text"
      :style="{
      color: options.color,
    }"
    >
      {{ loadingText }}
    </span>
    <span
      v-else
      class="wo-calendar__button-text"
      :style="{
      color: options.color,
    }"
    >
      {{ text }}
    </span>
  </div>
</template>
<script>
// eslint-disable-next-line init-declarations
export default {
  name: 'GreenButton',
  components: {},
  filters: {},
  directives: {},
  mixins: [],
  props: {
    width: {
      type: String,
      default: 'auto'
    },
    loading: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: '确认'
    },
    loadingText: {
      type: String,
      default: '确认中'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: options => ({
        borderColor: 'rgba(0, 255, 36, 0.33)',
        borderRadius: '5px',
        height: '28px',
        ...options
      })
    }
  },
  data() {
    return {
      buttonClassName: ['wo-calendar__button']
    }
  },
  computed: {
    // ...mapState('', {}),
  },
  watch: {},
  created() {},
  mounted() {},
  destroy() {},
  methods: {
    // ...mapMutations('', []),
    onClick() {
      if (!this.disabled && !this.loading) {
        this.$emit('click')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wo-calendar__button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);

  .wo-calendar__button-text {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #ffffff;
    white-space: nowrap;
  }
}

.wo-calendar__button--disabled {
  cursor: not-allowed;
  opacity: 0.8;
}
</style>
