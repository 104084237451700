<template>
  <el-dialog
    :append-to-body="true"
    :before-close="onBeforeClose"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :modal="false"
    :title="title"
    :visible.sync="dialogVisible"
    custom-class="common-dialog"
    top="10vh"
    width="500px"
  >
    <div class="tab">
      <div class="tab__content">
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">处罚类别</span>
          <el-input
            v-model="categoryPunishment"
            class="flex1"
            placeholder="请输入处罚类别"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">处罚决定日期</span>
          <el-date-picker
            v-model="punishmentDate"
            class="flex1"
            placeholder="选择处罚决定日期"
            size="small"
            type="date"
          ></el-date-picker>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">处罚机关</span>
          <el-input
            v-model="punishmentGroup"
            class="flex1"
            placeholder="请输入处罚机关"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">罚款金额(万元)</span>
          <el-input
            v-model="penaltyAmount"
            class="flex1"
            placeholder="请输入罚款金额"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">处罚机关统一社会信用代码</span>
          <el-input
            v-model="documentCode"
            class="flex1"
            placeholder="请输入处罚机关统一社会信用代码"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">行政处罚决定书文号</span>
          <el-input
            v-model="documentNumber"
            class="flex1"
            placeholder="请输入行政处罚决定书文号"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between label-box__gap">
          <span class="dialog-label">处罚内容</span>
          <el-input
            v-model="punishmentContent"
            :autosize="{ minRows:  5}"
            class="flex1"
            placeholder="请输入处罚内容"
            type="textarea"
          ></el-input>
        </div>
        <div class="flex row justify-between label-box__gap">
          <span class="dialog-label">违法行为类型</span>
          <el-input
            v-model="illegalType"
            :autosize="{ minRows:  5}"
            class="flex1"
            placeholder="请输入违法行为类型"
            type="textarea"
          ></el-input>
        </div>
        <div class="flex row justify-between label-box__gap">
          <span class="dialog-label">违法事实</span>
          <el-input
            v-model="illegalReason"
            :autosize="{ minRows:  5}"
            class="flex1"
            placeholder="请输入违法事实"
            type="textarea"
          ></el-input>
        </div>
        <div class="flex row justify-between label-box__gap">
          <span class="dialog-label">处罚依据</span>
          <el-input
            v-model="punishmentArgument"
            :autosize="{ minRows:  5}"
            class="flex1"
            placeholder="请输入处罚依据"
            type="textarea"
          ></el-input>
        </div>
      </div>
      <div class="tab__footer flex row justify-end items-center">
        <GreenButton
          :options="{
                    borderColor: 'rgba(26,89,184,0.33)',
                    borderRadius: '5px',
                    color: '#1A59B8 !important'
          }"
          class="button__cancel button__gap"
          text="取消"
          @click="cancelClick"
        ></GreenButton>
        <GreenButton
          :loading="createLoading"
          class="button__default"
          loading-text="处理中"
          text="确定"
          @click="onCreateClick"
        ></GreenButton>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'

export default {
  name: 'CreditChinaDialog',
  mixins: [],
  components: {
    GreenButton
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: -1
    }
  },
  computed: {
    title() {
      return this.isEdit ? '信用中国编辑' : '信用中国填报'
    }
  },
  data() {
    return {
      dialogVisible: false,
      punishmentGroup: '',
      categoryPunishment: '',
      punishmentDate: '',
      penaltyAmount: '',
      documentCode: '',
      documentNumber: '',
      punishmentContent: '',
      illegalType: '',
      illegalReason: '',
      punishmentArgument: '',
      createLoading: false
    }
  },
  watch: {
    visible(visible) {
      if (visible) {
        this.dialogVisible = true
        this.punishmentGroup = ''
        this.punishmentDate = ''
        this.categoryPunishment = ''
        this.illegalReason = ''
        this.penaltyAmount = ''
        this.documentNumber = ''
        this.punishmentContent = ''
        this.illegalType = ''
        this.punishmentArgument = ''
        this.documentCode = ''
        if (this.isEdit) {
          this.getProgressInfo()
        }
      }
    }
  },
  methods: {
    onBeforeClose(done) {
      this.$emit('closeDialog')
      this.clearClick()
      done()
    },

    /**
     * 获取 信用中国 数据
     * */
    async getProgressInfo() {
      const { success, data } = await this.$http({
        url: this.$api.getQueryCreditHistoryDetail,
        params: {
          id: this.id
        }
      })
      if (success) {
        const {
          Category,
          DecideDate,
          Group,
          Amount,
          Code,
          DocumentNo,
          Content,
          BehaviorCategory,
          Description,
          Basis
        } = data
        this.categoryPunishment = Category === null ? '' : Category
        this.punishmentDate = DecideDate.split('T')[0]
        this.punishmentGroup = Group === null ? '' : Group
        this.penaltyAmount = Amount === null ? '' : Amount
        this.documentCode = Code === null ? '' : Code
        this.documentNumber = DocumentNo === null ? '' : DocumentNo
        this.punishmentContent = Content === null ? '' : Content
        this.illegalType = BehaviorCategory === null ? '' : BehaviorCategory
        this.illegalReason = Description === null ? '' : Description
        this.punishmentArgument = Basis === null ? '' : Basis
      }
    },
    /**
     * 创建 信用中国
     * */
    async onCreateClick() {
      if (this.categoryPunishment === '') {
        this.$message.error('请输入处罚类别')
        return
      }
      if (this.punishmentDate === '') {
        this.$message.error('请选择处罚决定日期')
        return
      }
      if (this.punishmentGroup === '') {
        this.$message.error('请输入处罚机关')
        return
      }
      if (this.penaltyAmount === '') {
        this.$message.error('请输入罚款金额')
        return
      }
      if (this.documentCode === '') {
        this.$message.error('请输入处罚机关统一社会信用代码')
        return
      }
      if (this.documentNumber === '') {
        this.$message.error('请输入行政处罚决定书文号')
        return
      }
      if (this.punishmentContent === '') {
        this.$message.error('请输入处罚内容')
        return
      }
      if (this.illegalType === '') {
        this.$message.error('请输入违法行为类型')
        return
      }
      if (this.illegalReason === '') {
        this.$message.error('请输入违法事实')
        return
      }
      if (this.punishmentArgument === '') {
        this.$message.error('请输入处罚依据')
        return
      }
      if (this.isEdit) {
        await this.updateChange()
      }
      else {
        await this.createChange()
      }
    },
    /**
     * 信用中国 事件
     * */
    async createChange() {
      this.createLoading = true
      const { success, data } = await this.$http({
        url: this.$api.goAddCreditHistory,
        method: 'POST',
        data: {
          Category: this.categoryPunishment,
          DecideDate: this.$dateFormat(this.punishmentDate, 'yyyy-MM-dd'),
          Group: this.punishmentGroup,
          Amount: this.penaltyAmount,
          Code: this.documentCode,
          DocumentNo: this.documentNumber,
          Content: this.punishmentContent,
          BehaviorCategory: this.illegalType,
          Description: this.illegalReason,
          Basis: this.punishmentArgument
        }
      })
      if (success) {
        this.$emit('refresh')
        this.clearClick()
        this.$message.success('填报成功')
      }
      this.createLoading = false
    },
    /**
     * 更新 信用中国
     * */
    async updateChange() {
      this.createLoading = true
      const { success, data } = await this.$http({
        url: this.$api.goUpdateCreditHistory,
        method: 'POST',
        data: {
          ID: this.id,
          Category: this.categoryPunishment,
          DecideDate: this.$dateFormat(this.punishmentDate, 'yyyy-MM-dd'),
          Group: this.punishmentGroup,
          Amount: this.penaltyAmount,
          Code: this.documentCode,
          DocumentNo: this.documentNumber,
          Content: this.punishmentContent,
          BehaviorCategory: this.illegalType,
          Description: this.illegalReason,
          Basis: this.punishmentArgument
        }
      })
      if (success) {
        this.$emit('refresh')
        this.clearClick()
        this.$message.success('编辑成功')
      }
      this.createLoading = false
    },
    /**
     *  取消 事件
     * */
    cancelClick() {
      this.$emit('closeDialog')
      this.clearClick()
    },
    /**
     *  清空 事件
     * */
    clearClick() {
      this.punishmentGroup = ''
      this.categoryPunishment = ''
      this.illegalReason = ''
      this.punishmentDate = ''
      this.penaltyAmount = ''
      this.documentNumber = ''
      this.punishmentContent = ''
      this.illegalType = ''
      this.punishmentArgument = ''
      this.documentCode = ''
      this.dialogVisible = false
    }
  }
}
</script>
<style
  lang="scss"
  scoped
>
$tabsDefaultColor: #FFFFFF;
$tabsActiveColor: #42DEFD;
$borderColor: rgba(3, 175, 252, 0.66);

.tab {
  height: 700px;

  &__content {
    overflow: auto;
    height: calc(100% - 74px);
  }

  &__footer {
    height: 59px;
    margin: 1em -2.4em 0;
    padding: 0 2em;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    background-color: #FFFFFF;
  }
}

//dialog
::v-deep.el-dialog__wrapper {
  background-color: rgba(0, 0, 0, .5);
}

::v-deep .el-dialog__title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.85);
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.02) !important;
}

::v-deep .el-dialog__body {
  padding: 30px 34px 0;
}

//dialog
::v-deep .el-tabs {
  margin-bottom: 1em;

  &__header {
    margin: 0;
  }

  &__nav-wrap::after {
    background-color: transparent;
  }

  &__item {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    height: 31px;
    padding: 0 1em;
    color: $tabsDefaultColor;

    &.is-active {
      color: $tabsActiveColor;
    }
  }

  &__active-bar {
    color: $tabsActiveColor;
  }

  &__content {
    display: none;
  }
}
</style>
