<template>
  <div class="month-output-wrapper">
    <div
      class="flex flex1 justify-between item-center"
      style="padding: 20px;background:#FFFFFF;"
    >
      <div class="flex justify-start">
        <GreenButton
          class="button__import"
          text="导入"
          @click="importChange"
        ></GreenButton>
        <!--          <GreenButton-->
        <!--            class="button__import"-->
        <!--            text="填报"-->
        <!--            @click="createChange"-->
        <!--          ></GreenButton>-->
      </div>
    </div>
    <el-table
      :data="projectData"
      :header-cell-style="{background: 'rgba(0,0,0,0.02)',borderTop:'1px solid rgba(0,0,0,0.1)'}"
      :loading="projectLoading"
      class="table-box"
      height="calc(100% - 165px)"
    >
      <el-table-column
        label="编号"
        prop="ProjectCode"
        width="60"
      ></el-table-column>
      <el-table-column
        label="项目名称"
        prop="ProjectName"
      ></el-table-column>
      <el-table-column
        label="本月计划产值(万)"
        prop="Plan"
        width="150"
      ></el-table-column>
      <el-table-column
        label="本月实际产值(万)"
        prop="Actual"
        width="150"
      ></el-table-column>
      <el-table-column
        label="完成率"
        prop="CompletionRate"
        width="120"
      >
        <template #default="scope">
          <span>{{ scope.row['CompletionRate'] | numberFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="累计完成"
        prop="ProjectCompleted"
        width="160"
      ></el-table-column>
      <el-table-column
        label="合同金额"
        prop="ProjectContractAmount"
        width="160"
      ></el-table-column>
      <el-table-column
        label="操作"
        width="120"
      >
        <template #default="scope">
          <!--            <el-link-->
          <!--              type="primary"-->
          <!--              @click="editInfo(scope.row)"-->
          <!--            >-->
          <!--              编辑-->
          <!--            </el-link>-->
          <el-link
            type="primary"
            @click="deleteInfo(scope.row)"
          >
            删除
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <div
      class="wot__footer flex flex1 justify-end items-center"
    >
      <el-pagination
        :current-page.sync="pageIndex"
        :page-size.sync="pageSize"
        :total="allTotal"
        layout="total, prev, pager, next"
        @current-change="onPageIndexChange"
      ></el-pagination>
    </div>
    <ImportDialog
      :visible="importDialogVisible"
      :importType="'monthOutput'"
      @closeDialog="onImportDialogClose('import')"
      @refresh="onRefreshDialogClose('import')"
    ></ImportDialog>
    <CreateDialog
      :id="currentID"
      :is-edit="isCreateEdit"
      :visible="createDialogVisible"
      @closeDialog="onImportDialogClose('create')"
      @refresh="onRefreshDialogClose('create')"
    ></CreateDialog>
  </div>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'
import StatusTag from '@/components/StatusTag'
import ImportDialog from '@/components/dialog/ImportPerformanceDialog'
import CreateDialog from '@/components/dialog/CreatePerformanceDialog'

export default {
  name: 'monthOutputTotal',
  components: {
    GreenButton,
    StatusTag,
    ImportDialog,
    CreateDialog
  },
  filters: {
    timeDateFilter(date) {
      return typeof date === 'string' ? date.split('T')[0] : ''
      //      return typeof date === 'string' ? date.replace('T', ' ') : ''
    },
    numberFilter(value) {
      return value === 1 ? '100%' : `${(value * 100).toFixed(2)}%`
    }
  },
  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
      allTotal: 0,
      projectLoading: false,
      projectData: [],
      importDialogVisible: false,
      createDialogVisible: false,
      isCreateEdit: false,
      currentID: -1
    }
  },
  mounted() {
    this.getProjectInfo()
    this.getMonthData()
  },
  destroy() {
  },
  methods: {
    /**
     * 获取 月度产值 数据
     * */
    async getProjectInfo() {
      this.projectLoading = true
      const { success, data } = await this.$http({
        url: this.$api.getPageQueryProjectMonthlyOutputValue,
        params: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
        }
      })
      if (success) {
        const { rows, Total } = data
        if (data.hasOwnProperty('rows')) {
          this.projectData = [...rows]
          if (data.hasOwnProperty('Total')) {
            this.allTotal = Total
          }
        }
      }
      this.projectLoading = false
    },
    /**
     * 切换分页
     * */
    onPageIndexChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getProjectInfo()
    },
    /**
     * 导入选择
     * */
    importChange() {
      this.importDialogVisible = true
    },
    /**
     * 新建选择
     * */
    createChange() {
      this.createDialogVisible = true
    },
    /**
     * 上传文件 关闭dialog回调
     * */
    onImportDialogClose(type) {
      if (type === 'import') {
        this.importDialogVisible = false
      }
      else if (type === 'create') {
        this.isCreateEdit = false
        this.createDialogVisible = false
      }
    },
    /**
     * 上传文件 刷新dialog回调
     * */
    onRefreshDialogClose(type) {
      if (type === 'import') {
        this.importDialogVisible = false
      }
      else if (type === 'create') {
        this.isCreateEdit = false
        this.createDialogVisible = false
      }
      this.pageIndex = 1
      this.getProjectInfo()
    },
    /**
     * 编辑信息
     * */
    editInfo(item) {
      const { ID } = item
      this.currentID = ID
      this.isCreateEdit = true
      this.createDialogVisible = true
    },
    /**
     * 删除信息
     * */
    deleteInfo(item) {
      const { ID } = item
      this.$confirm('是否确认删除', '删除月度产值', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '删除中...'
            setTimeout(async () => {
              await this.deleteChange(ID)
              done()
              setTimeout(() => {
                instance.confirmButtonLoading = false
              }, 300)
            }, 300)
          }
          else {
            done()
          }
        }
      })
    },
    /**
     * 确认删除
     * */
    async deleteChange(ID) {
      const { success } = await this.$http({
        url: this.$api.goDeleteProjectMonthlyOutputValue,
        params: {
          id: ID
        }
      })
      if (success) {
        this.$message.success('删除成功')
        this.pageIndex = 1
        await this.getProjectInfo()
      }
    },
    /**
     * 获取月份数据
     * */
    async getMonthData() {
      const { success, data } = await this.$http({
        url: this.$api.getQuerDate
      })
      if (success) {
        this.monthOptions = []
        const { rows } = { ...data }
        rows.map(({ Name: label, ID: value }) => {
          this.monthOptions.push({ label, value })
        })
      }
    },
  }

}
</script>
<style scoped>
.month-output-wrapper {
  height: 100%
}

.wot__footer {
  padding: 1em 0.1em;
  background: #FFFFFF;
}

.button__import {
  margin-right: 0.5em !important;
  border: 1px solid #005BAC !important;
  border-radius: 4px !important;
  background: #005BAC !important;
}

.button__delete {
  font-weight: 400 !important;
  margin-right: 0.5em !important;
  border: 1px solid rgba(26, 89, 184, 0.33) !important;
  border-radius: 4px !important;
  background: rgba(26, 89, 184, 0.05) !important;
}

::v-deep .el-link.el-link--primary {
  color: #005BAC;
}
</style>
