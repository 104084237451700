<template>
  <el-dialog
    :append-to-body="true"
    :before-close="onBeforeClose"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :modal="false"
    :title="titleName"
    :visible.sync="dialogVisible"
    custom-class="common-dialog"
    top="10vh"
    width="500px"
  >
    <div class="tab">
      <div class="tab__content">
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">类型</span>
          <el-select
            v-model="categoryName"
            class="flex flex1 justify-end items-center"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="({ label, value },categoryIndex) in categoryNameOptions"
              :key="`${label}-${value}-${categoryIndex}`"
              :label="label"
              :value="value"
            ></el-option>
          </el-select>
        </div>
        <div class="flex row justify-between label-box__gap">
          <span class="dialog-label">描述</span>
          <el-input
            v-model="description"
            :autosize="{ minRows:  5}"
            class="flex1"
            placeholder="请输入图片描述"
            type="textarea"
          ></el-input>
        </div>
        <div class="flex justify-start">
          <span class="dialog-label">附件</span>
          <el-upload
            ref="upload"
            :auto-upload="false"
            :before-upload="beforeAvatarUpload"
            :file-list="linksList"
            :http-request="uploadFile"
            :limit="1"
            action=""
            list-type="picture"
          >
            <div class="flex justify-start">
              <GreenButton
                slot="trigger"
                class="button__default"
                text="图片"
              ></GreenButton>
            </div>
            <div
              slot="tip"
            >只能上传jpg/jpeg/svg/png文件
            </div>
          </el-upload>
        </div>
      </div>
      <div class="tab__footer flex row justify-end items-center">
        <GreenButton
          :options="{
                    borderColor: 'rgba(26,89,184,0.33)',
                    borderRadius: '5px',
                    color: '#1A59B8 !important'
          }"
          class="button__cancel button__gap"
          text="取消"
          @click="cancelClick"
        ></GreenButton>
        <GreenButton
          :loading="createLoading"
          class="button__default"
          loading-text="处理中"
          text="确定"
          @click="onCreateClick"
        ></GreenButton>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateExposureDialog',
  mixins: [],
  components: {
    GreenButton
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      dialogVisible: false,
      createLoading: false,
      linksList: [],
      fileData: {},
      description: '',
      categoryName: 'Innovate',
      categoryNameOptions: [
        { label: '创新', value: 'Innovate' },
        { label: '曝光', value: 'Exposure' }
      ]
    }
  },
  computed: {
    titleName() {
      return this.isEdit ? '创新和曝光做法编辑' : '创新和曝光做法填报'
    }
  },
  watch: {
    visible: {
      handler: function (visible) {
        if (visible) {
          this.dialogVisible = true
          this.categoryName = 'Innovate'
          this.description = ''
          this.linksList = []
          if (this.isEdit) {
            this.getDataInfo()
          }
        }
      }
    }
  },
  methods: {
    ...mapGetters('user', {
      USER_INFO: 'USER_INFO',
    }),
    ...mapGetters('management', {
      EXPOSURE_INFO: 'getInnovationAndExposureInfo',
    }),
    onBeforeClose(done) {
      this.$emit('closeDialog')
      this.clearClick()
      done()
    },
    /**
     * 获取 创新和曝光做法 数据
     * */
    getDataInfo() {
      const { ID, Content, FileName, Category, FilePath } = this.EXPOSURE_INFO()
      this.categoryName = Category === '创新做法' ? 'Innovate' : Category === '曝光' ? 'Exposure' : 'Innovate'
      this.description = Content === null ? '' : Content
      if (FilePath !== '') {
        this.linksList.push({
          id: ID,
          name: FileName,
          url: `${WEB_CONFIG.BASE_URL}${FilePath}`,
          isServer: true
        })
        //        this.$refs.upload.uploadFiles = this.linksList
      }
    },
    /**
     * 创建 创新和曝光做法
     * */
    async onCreateClick() {
      if (this.description === '') {
        this.$message.error('请输入描述')
        return
      }
      if (this.$refs.upload.uploadFiles.length === 0) {
        this.$message.error('请上传附件')
      }
      else {
        let tempDta = this.fileData
        this.fileData = new FormData()
        await this.$refs.upload.submit()
        await this.uploadChange()
      }
    },
    /**
     *  取消 事件
     * */
    cancelClick() {
      this.$emit('closeDialog')
      this.clearClick()
    },
    /**
     *  清空 事件
     * */
    clearClick() {
      this.description = ''
      this.categoryName = 'Innovate'
      this.linksList = []
      this.dialogVisible = false
      this.$refs.upload.clearFiles()
    },
    /**
     * 上传附件之前
     */
    beforeAvatarUpload() {
    },
    /**
     * 上传附件
     */
    async uploadFile(files) {
      const { file } = files
      this.fileData.append('file', file)
    },
    /**
     * 上传 事件
     * */
    async uploadChange() {
      const { raw, isServer } = this.$refs.upload.uploadFiles[0]
      if (!isServer) {
        const testing = raw.name.substring(raw.name.lastIndexOf('.') + 1)
        const extension = testing === 'jpg'
        const extension1 = testing === 'jpeg'
        const extension2 = testing === 'png'
        const extension3 = testing === 'svg'
        if (!extension && !extension1 && !extension2 && !extension3) {
          this.$message.error('只能上传jpg、jpeg、svg与png文件')
          this.$refs.upload.clearFiles()
          return
        }
      }
      this.createLoading = true
      const { ID } = this.USER_INFO()
      const newUrl = this.isEdit ? this.$api.goUpdateConstructionStandard : this.$api.goConstructionStandard
      if (this.isEdit) {
        this.fileData.append('ID', this.id)
      }
      this.fileData.append('Category', this.categoryName)
      this.fileData.append('CreationUserID', ID)
      this.fileData.append('Content', this.description)
      const { success } = await this.$http({
        url: newUrl,
        method: 'POST',
        data: this.fileData
      })
      if (success) {
        this.$emit('refresh')
        this.$message.success(this.isEdit ? '编辑成功' : '填报成功')
        this.clearClick()
      }
      else {
        this.$refs.upload.clearFiles()
      }
      this.createLoading = false
    }
  }
}
</script>
<style
  lang="scss"
  scoped
>
$tabsDefaultColor: #FFFFFF;
$tabsActiveColor: #42DEFD;
$borderColor: rgba(3, 175, 252, 0.66);

.tab {
  height: 430px;

  &__content {
    overflow: auto;
    height: calc(100% - 74px);
  }

  &__footer {
    height: 59px;
    margin: 1em -2.4em 0;
    padding: 0 2em;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    background-color: #FFFFFF;
  }
}

//dialog
::v-deep.el-dialog__wrapper {
  background-color: rgba(0, 0, 0, .5);
}

::v-deep .el-dialog__title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.85);
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.02) !important;
}

::v-deep .el-dialog__body {
  padding: 30px 34px 0;
}

//dialog
::v-deep .el-tabs {
  margin-bottom: 1em;

  &__header {
    margin: 0;
  }

  &__nav-wrap::after {
    background-color: transparent;
  }

  &__item {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    height: 31px;
    padding: 0 1em;
    color: $tabsDefaultColor;

    &.is-active {
      color: $tabsActiveColor;
    }
  }

  &__active-bar {
    color: $tabsActiveColor;
  }

  &__content {
    display: none;
  }
}

::v-deep .el-upload-list__item-name {
  max-width: 180px;
}
</style>
