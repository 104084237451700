<template>
  <div id="progressPerformance">
    <div class="flex row top-bar_wrapper">
      <div
        class="progress-name  pointer"
        :class="{'progress-name-active':!isStatus}"
        @click="selectChange('progress')"
      >进度履约
      </div>
      <div
        class="progress-name  pointer"
        :class="{'progress-name-active':isStatus}"
        @click="selectChange('project')"
      >项目状态
      </div>
    </div>
    <div
      v-if="!isStatus"
      class="flex flex1 justify-between item-center button-title__box"
    >
      <div class="flex justify-start">
        <GreenButton
          class="button__default button__gap"
          text="导入"
          @click="importChange"
        ></GreenButton>
        <GreenButton
          class="button__default"
          text="填报"
          @click="createChange"
        ></GreenButton>
      </div>
      <div
        class="flex justify-end items-center "
      >
        <el-date-picker
          v-model="currentSelectMonth"
          class="flex justify-end items-center button__gap"
          size="small"
          placeholder="选择月份"
          type="month"
          @change="selectMonthChange"
        ></el-date-picker>
        <el-select
          v-model="selectCategory"
          placeholder="请选择"
          size="small"
          @change="statusChange"
        >
          <el-option
            v-for="item in categoryOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <el-table
      v-if="!isStatus"
      :data="projectData"
      :header-cell-style="{background: 'rgba(0,0,0,0.02)',borderTop:'1px solid rgba(0,0,0,0.1)'}"
      :loading="projectLoading"
      class="table-box"
      height="calc(100% - 165px)"
    >
      <el-table-column
        label="项目编号"
        prop="ProjectCode"
        width="110"
      ></el-table-column>
      <el-table-column
        label="项目名称"
        prop="ProjectName"
      ></el-table-column>
      <el-table-column
        label="实际开工时间"
        prop="ActualStartDate"
        width="110"
      >
        <template #default="scope">
          <span>{{ scope.row['ActualStartDate'] | timeDateFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="合同延期后竣工时间"
        prop="DelayCompleteDate"
        width="110"
      >
        <template #default="scope">
          <span>{{ scope.row['DelayCompleteDate'] | timeDateFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="当月25日"
        prop="MonthDate"
        width="110"
      >
        <template #default="scope">
          <span>{{ scope.row['MonthDate'] | timeDateFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="合同日期天数"
        prop="ContractDayCount"
        width="110"
      ></el-table-column>
      <el-table-column
        label="实际已用天数"
        prop="ActualUseCount"
        width="110"
      ></el-table-column>
      <el-table-column
        label="滞后天数"
        prop="DelayDayCount"
        width="80"
      ></el-table-column>
      <el-table-column
        label="工程关键线路"
        prop="Progress"
        width="150"
      ></el-table-column>
      <el-table-column
        label="滞后原因"
        prop="Reason"
        width="170"
      ></el-table-column>
      <el-table-column
        label="履约状态"
        width="90"
      >
        <template #default="scope">
          <StatusTag
            :text="scope.row.Status"
          ></StatusTag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="160"
      >
        <template #default="scope">
          <el-link
            type="primary"
            @click="editInfo(scope.row)"
          >
            编辑
          </el-link>
          <el-link
            style="margin-left: 1em;"
            type="primary"
            @click="deleteInfo(scope.row)"
          >
            删除
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <div
      v-if="!isStatus"
      class="table-box__pagination flex flex1 justify-end items-center"
    >
      <el-pagination
        :current-page.sync="pageIndex"
        :page-size.sync="pageSize"
        :total="allTotal"
        layout="total, prev, pager, next"
        @current-change="onPageIndexChange"
      ></el-pagination>
    </div>
    <div
      v-if="isStatus"
      class="com-wrapper"
    >
      <projectStatus></projectStatus>
    </div>
    <ImportPerformanceDialog
      :visible="importDialogVisible"
      @closeDialog="onImportDialogClose('import')"
      @refresh="onRefreshDialogClose('import')"
    ></ImportPerformanceDialog>
    <CreatePerformanceDialog
      :id="currentID"
      :is-edit="isCreateEdit"
      :visible="createDialogVisible"
      @closeDialog="onImportDialogClose('create')"
      @refresh="onRefreshDialogClose('create')"
    ></CreatePerformanceDialog>
  </div>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'
import StatusTag from '@/components/StatusTag'
import ImportPerformanceDialog from '@/components/dialog/ImportPerformanceDialog'
import CreatePerformanceDialog from '@/components/dialog/CreatePerformanceDialog'
import projectStatus from './projectStatus'

let _this = void 0
//import { mapState, mapMutations } from 'vuex'

export default {
  name: 'progressPerformance',
  mixins: [],
  components: { GreenButton, StatusTag, ImportPerformanceDialog, CreatePerformanceDialog, projectStatus },
  props: {},
  data() {
    return {
      categoryOptions: [
        {
          value: '',
          label: '全部状态'
        },
        {
          value: 'high',
          label: '严重滞后'
        }, {
          value: 'low',
          label: '轻微滞后'
        }, {
          value: 'middle',
          label: '一般滞后'
        }, {
          value: 'normal',
          label: '履约正常'
        }],
      selectCategory: '',
      pageIndex: 1,
      pageSize: 11,
      allTotal: 0,
      projectLoading: false,
      projectData: [],
      importDialogVisible: false,
      createDialogVisible: false,
      isCreateEdit: false,
      currentID: -1,
      currentSelectMonth: '',
      isStatus: false
    }
  },
  computed: {
    //...mapState('', {}),
  },
  watch: {},
  filters: {
    timeDateFilter(date) {
      return typeof date === 'string' ? date.split('T')[0] : ''
      //      return typeof date === 'string' ? date.replace('T', ' ') : ''
    },
    //    stationNameFilter(arr) {
    //      return arr.length > 0 ? arr.reduce((res, value) => `${res}${value.Name}  `, '') : ''
    //    }
  },
  directives: {},
  created() {
    _this = this
  },
  async mounted() {
    await this.getMonthInfo()
    await this.getProjectInfo()
  },
  methods: {
    //...mapMutations('', []),
    /**
     * 获取 进度履约 最大导入月份
     * */
    async getMonthInfo() {
      this.projectLoading = true
      const { success, data } = await this.$http({
        url: this.$api.getQueryDefaultDate
      })
      if (success) {
        this.currentSelectMonth = this.$dateFormat(data, 'yyyy-MM')
      }
    },
    /**
     * 获取 进度履约 数据
     * */
    async getProjectInfo() {
      this.projectLoading = true
      const { success, data } = await this.$http({
        url: this.$api.getPageQueryProgressPerformance,
        params: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          date: this.currentSelectMonth,
          delayCategory: this.selectCategory,
        }
      })
      if (success) {
        const { rows, total } = data
        if (data.hasOwnProperty('rows')) {
          this.projectData = [...rows]
          if (data.hasOwnProperty('total')) {
            this.allTotal = total
          }
        }
      }
      this.projectLoading = false
    },
    /**
     * 切换分页
     * */
    onPageIndexChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getProjectInfo()
    },
    /**
     *  月份 选择
     * */
    selectMonthChange(value) {
      this.pageIndex = 1
      this.currentSelectMonth = this.$dateFormat(value, 'yyyy-MM')
      this.getProjectInfo()
    },
    /**
     *  履约状态 下拉选择
     * */
    statusChange(value) {
      this.pageIndex = 1
      this.selectCategory = value
      this.getProjectInfo()
    },
    /**
     * 导入选择
     * */
    importChange() {
      this.importDialogVisible = true
    },
    /**
     * 填报 选择
     * */
    createChange() {
      this.createDialogVisible = true
    },
    /**
     * 导入或填报 关闭dialog回调
     * */
    onImportDialogClose(type) {
      if (type === 'import') {
        this.importDialogVisible = false
      }
      else if (type === 'create') {
        this.isCreateEdit = false
        this.createDialogVisible = false
      }
    },
    /**
     * 导入或填报 刷新dialog回调
     * */
    onRefreshDialogClose(type) {
      if (type === 'import') {
        this.importDialogVisible = false
      }
      else if (type === 'create') {
        this.isCreateEdit = false
        this.createDialogVisible = false
      }
      this.pageIndex = 1
      this.getProjectInfo()
    },
    /**
     * 编辑信息
     * */
    editInfo(item) {
      const { ID } = item
      this.currentID = ID
      this.isCreateEdit = true
      this.createDialogVisible = true
    },
    /**
     * 删除信息
     * */
    deleteInfo(item) {
      const { ID } = item
      this.$confirm('是否确认删除', '删除进度履约', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '删除中...'
            setTimeout(async () => {
              await this.deleteChange(ID)
              done()
              setTimeout(() => {
                instance.confirmButtonLoading = false
              }, 300)
            }, 300)
          }
          else {
            done()
          }
        }
      })
    },
    /**
     * 确认删除
     * */
    async deleteChange(ID) {
      const { success } = await this.$http({
        url: this.$api.goDeleteProgressPerformance,
        params: {
          id: ID
        }
      })
      if (success) {
        this.$message.success('删除成功')
        this.pageIndex = 1
        await this.getProjectInfo()
      }
    },
    /**
     * 切换tab
     * */
    selectChange(type) {
      if (type === 'progress') {
        this.isStatus = false
      }
      if (type === 'project') {
        this.isStatus = true
      }
    }
  }
}
</script>
<style
  lang="scss"
  scoped
>
#progressPerformance {
  height: 100%;
}

.com-wrapper {
  height: 100%;
}

::v-deep .el-link.el-link--primary {
  color: #005BAC;
}
</style>
<style lang="scss"></style>
