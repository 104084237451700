<template>
  <div class="stag">
    <div :class="tagContainerClass">
      <span class="stag__text">
        {{ text }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StatusTag',
  components: {},
  filters: {},
  directives: {},
  mixins: [],
  props: {
    text: {
      type: String,
      default: ''
    },
    tasks: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tagContainerClass: ['stag__container']
    }
  },
  computed: {
    // ...mapState('', {}),
  },
  watch: {
    text() {
      this.initClassName()
    }
  },
  created() {
  },
  mounted() {
    this.initClassName()
  },
  destroy() {
  },
  methods: {
    // ...mapMutations('', []),
    tagNameHandler() {
      return {
        严重滞后: 'high',
        轻微滞后: 'middle',
        一般滞后: 'low',
        履约正常: 'normal',
      }[this.text]
    },
    initClassName() {
      this.tagContainerClass = ['stag__container']
      this.tagContainerClass.push(`stag__container--${this.tagNameHandler()}`)
    }
  }
}
</script>
<style
  lang="scss"
  scoped
>
.stag {
  width: fit-content;
  height: fit-content;

  .stag__text {
    font-size: 12px;
    font-weight: 700;
    //color: #ffffff;
  }

  .stag__task {
    width: 6px;
    height: 6px;
    margin: 5px 5px 5px 0;
    opacity: 0.33;
    border-radius: 50%;
    background: #FFFFFF;
  }

  .stag__task--active {
    width: 6px;
    height: 6px;
    opacity: 1;
    border-radius: 50%;
    background: #42DEFD;
  }

  .stag__container {
    padding: 0 8px;
    border-radius: 4px;
  }

  .stag__container--high {
    background: #FFF1F0;
    border: 1px solid #FFA39E;
    border-radius: 2px;
    color: #FF4D4F;

  }

  .stag__container--middle {
    background: #FFFBE6;
    border: 1px solid #FFE58F;
    border-radius: 2px;
    color: #FAAD14;
  }

  .stag__container--low {
    background: rgba(250, 140, 22, 0.1);
    border: 1px solid rgba(250, 140, 22, 0.66);
    border-radius: 2px;
    color: #FA8C16;
  }

  .stag__container--normal {
    background: #F6FFED;
    border: 1px solid #B7EB8F;
    border-radius: 2px;
    color: #52C41A;
  }

  .stag__container--draft {
    border: 1px solid rgba(255, 255, 255, 0.33);
    background: rgba(0, 0, 0, 0.1);
  }

  .stag__container--copy {
    border: 1px solid rgba(255, 255, 255, 0.33);
    background: rgba(0, 0, 0, 0.1);
  }

  .stag__container--publish {
    border: 1px solid rgba(255, 185, 101, 0.8);
    background: #FF6600;
  }

  .stag__container--send {
    border: 1px solid #77C11C;
    background: rgba(106, 167, 0, 0.9);
  }

  .stag__container--order {
    border: 1px solid #00CACB;
    background: rgba(0, 170, 164, 0.9);
  }

  .stag__container--prepare {
    border: 1px solid #24B1FF;
    background: rgba(0, 168, 255, 0.66);
  }

  .stag__container--ongoing {
    border: 1px solid #3377FF;
    background: #004CFF;
  }

  .stag__container--submit {
    border: 1px solid #7045EE;
    background: rgba(163, 12, 199, 0.5);
  }

  .stag__container--approval {
    border: 1px solid #B5148F;
    background: rgba(178, 0, 136, 0.9);
  }

  .stag__container--complete {
    border: 1px solid rgba(255, 255, 255, 0.33);
    background: rgba(0, 0, 0, 0.33);
  }

  .stag__container--rework {
    border: 1px solid #FF4747;
    background: rgba(255, 0, 0, 0.8);
  }

  .stag__container--back {
    border: 1px solid #FFDD00;
    background: rgba(197, 197, 0, 0.9);
  }
}
</style>
