<template>
  <el-dialog
    :append-to-body="true"
    :before-close="onBeforeClose"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :modal="false"
    :title="title"
    :visible.sync="dialogVisible"
    custom-class="common-dialog"
    top="16vh"
    width="500px"
  >
    <div class="tab">
      <div class="tab__content">
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">角色名称</span>
          <el-input
            v-model="roleName"
            class="flex1"
            placeholder="请输入角色名称"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">描述</span>
          <el-input
            v-model="description"
            class="flex1"
            placeholder="请输入描述"
            size="small"
          ></el-input>
        </div>
      </div>
    </div>
    <div class="tab__footer flex row justify-end items-center">
      <GreenButton
        :options="{
                    borderColor: 'rgba(26,89,184,0.33)',
                    borderRadius: '5px',
                    color: '#1A59B8 !important'
          }"
        class="button__cancel button__gap"
        text="取消"
        @click="cancelClick"
      ></GreenButton>
      <GreenButton
        :loading="createLoading"
        class="button__default"
        loading-text="处理中"
        text="确定"
        @click="onCreateClick"
      ></GreenButton>
    </div>
  </el-dialog>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'

export default {
  name: 'CreateRoleDialog',
  mixins: [],
  components: {
    GreenButton
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      dialogVisible: false,
      exportLoading: false,
      roleName: '',
      description: '',
      createLoading: false
    }
  },
  computed: {
    title() {
      return this.isEdit ? '编辑用户' : '添加用户'
    }
  },
  watch: {
    visible(visible) {
      if (visible) {
        this.dialogVisible = true
        this.roleName = ''
        this.description = ''
        if (this.isEdit) {
          const { Name, Description } = JSON.parse(window.sessionStorage.getItem('ROLE_INFO'))
          this.roleName = Name
          this.description = Description
        }
      }
    }
  },
  methods: {
    onBeforeClose(done) {
      this.$emit('closeDialog')
      this.clearClick()
      done()
    },
    /**
     * 角色 确定
     * */
    async onCreateClick() {
      if (this.roleName === '') {
        this.$message.error('请输入角色名称')
        return
      }
      if (this.description === '') {
        this.$message.error('请输入描述')
        return
      }
      await this.createChange()
    },
    /**
     * 创建或更新 角色
     * */
    async createChange() {
      this.createLoading = true
      const { success, data } = await this.$http({
        url: this.$api.goAddOrUpdateRole,
        method: 'POST',
        data: {
          ID: this.isEdit ? this.id : 0,
          Name: this.roleName,
          Description: this.description,
        }
      })
      if (success) {
        this.$emit('refresh')
        this.$message.success(this.isEdit ? '更新成功' : '添加成功')
        this.clearClick()
      }
      this.createLoading = false
    },
    /**
     *  取消 事件
     * */
    cancelClick() {
      this.$emit('closeDialog')
      this.clearClick()
    },
    /**
     *  清空 事件
     * */
    clearClick() {
      this.roleName = ''
      this.description = ''
      this.dialogVisible = false
    }
  }
}
</script>
<style
  lang="scss"
  scoped
>
$tabsDefaultColor: #FFFFFF;
$tabsActiveColor: #42DEFD;
$borderColor: rgba(3, 175, 252, 0.66);

.tab {
  height: 90px;

  &__content {
    overflow: auto;
    height: calc(100%);
  }

  &__footer {
    height: 59px;
    margin: 1em -2.4em 0;
    padding: 0 2em;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    background-color: #FFFFFF;
  }
}

//dialog
::v-deep.el-dialog__wrapper {
  background-color: rgba(0, 0, 0, .5);
}

::v-deep .el-dialog__title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.85);
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.02) !important;
}

::v-deep .el-dialog__body {
  padding: 30px 34px 0;
}

//dialog
::v-deep .el-tabs {
  margin-bottom: 1em;

  &__header {
    margin: 0;
  }

  &__nav-wrap::after {
    background-color: transparent;
  }

  &__item {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    height: 31px;
    padding: 0 1em;
    color: $tabsDefaultColor;

    &.is-active {
      color: $tabsActiveColor;
    }
  }

  &__active-bar {
    color: $tabsActiveColor;
  }

  &__content {
    display: none;
  }
}
</style>
