<template>
  <el-dialog
    :append-to-body="true"
    :before-close="onBeforeClose"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :modal="false"
    :title="title"
    :visible.sync="dialogVisible"
    custom-class="common-dialog"
    top="10vh"
    width="500px"
  >
    <div class="tab">
      <div class="tab__content">
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">项目名称</span>
          <el-input
            v-model="projectName"
            class="flex1"
            placeholder="请输入项目名称"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">关键节点</span>
          <el-input
            v-model="keyNode"
            class="flex1"
            placeholder="请输入关键节点"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">计划完成时间</span>
          <el-date-picker
            v-model="planDate"
            class="flex1"
            placeholder="选择计划完成时间"
            size="small"
            type="date"
          ></el-date-picker>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">过程对比情况</span>
          <el-input
            v-model="processContrast"
            class="flex1"
            placeholder="请输入过程对比情况"
            size="small"
            type="number"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">实际完成时间</span>
          <el-date-picker
            v-model="actuallyCompleteDate"
            class="flex1"
            placeholder="选择实际完成时间"
            size="small"
            type="date"
          ></el-date-picker>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">滞后情况</span>
          <el-input
            v-model="lagCondition"
            class="flex1"
            placeholder="请输入滞后情况"
            size="small"
            type="number"
          ></el-input>
        </div>
      </div>
      <div class="tab__footer flex row justify-end items-center">
        <GreenButton
          :options="{
                    borderColor: 'rgba(26,89,184,0.33)',
                    borderRadius: '5px',
                    color: '#1A59B8 !important'
          }"
          class="button__cancel button__gap"
          text="取消"
          @click="cancelClick"
        ></GreenButton>
        <GreenButton
          :loading="createLoading"
          class="button__default"
          loading-text="处理中"
          text="确定"
          @click="onCreateClick"
        ></GreenButton>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'

export default {
  name: 'CreateProjectWarningDialog',
  mixins: [],
  components: {
    GreenButton
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: -1
    }
  },
  computed: {
    title() {
      return this.isEdit ? '项目进度履约预警编辑' : '项目进度履约预警填报'
    }
  },
  data() {
    return {
      dialogVisible: false,
      keyNode: '',
      projectName: '',
      planDate: '',
      actuallyCompleteDate:'',
      lagCondition: '',
      processContrast:'',
      createLoading: false
    }
  },
  watch: {
    visible(visible) {
      if (visible) {
        this.dialogVisible = true
        this.keyNode = ''
        this.planDate = ''
        this.actuallyCompleteDate = ''
        this.projectName = ''
        this.lagCondition = ''
        this.processContrast= ''
        if (this.isEdit) {
          this.getProjectWarningDetail()
        }
      }
    }
  },
  methods: {
    onBeforeClose(done) {
      this.$emit('closeDialog')
      this.clearClick()
      done()
    },

    /**
     * 获取详情数据
     * */
    async getProjectWarningDetail() {
      const { success, data } = await this.$http({
        url: this.$api.pageQueryProjectDetail,
        params: {
          id: this.id
        }
      })
      if (success) {
        const {
          ProjectName,
          DelayDay,
          KeyNodes,
          PlanDate,
          ActualDate,
          ProcessComparison,
        } = data
        this.projectName = ProjectName === null ? '' : ProjectName
        this.planDate = PlanDate.split('T')[0]
        this.actuallyCompleteDate = ActualDate.split('T')[0]
        this.keyNode = KeyNodes === null ? '' : KeyNodes
        this.lagCondition = DelayDay === null ? '' : DelayDay
        this.processContrast = ProcessComparison === null ? '' : ProcessComparison
      }
    },
    /**
     * 创建或者编辑提示信息
     * */
    async onCreateClick() {
      if (this.projectName === '') {
        this.$message.error('请输入项目名称')
        return
      }
      if (this.planDate === '') {
        this.$message.error('请选择计划完成时间')
        return
      }
//      if (this.actuallyCompleteDate === '') {
//        this.$message.error('请选择实际完成时间')
//        return
//      }
      if (this.keyNode === '') {
        this.$message.error('请输入关键节点')
        return
      }
//      if (this.lagCondition === '') {
//        this.$message.error('请输入滞后情况')
//        return
//      }
//      if (this.processContrast === '') {
//        this.$message.error('请输入过程对比')
//        return
//      }
      if (this.isEdit) {
        await this.updateChange()
      }
      else {
        await this.createChange()
      }
    },
    /**
     * 创建
     * */
    async createChange() {
      this.createLoading = true
      const { success, data } = await this.$http({
        url: this.$api.addProjectProgressWarn,
        method: 'POST',
        data: {
          ProjectName: this.projectName,
          ProjectCode:'',
          DelayDay:this.lagCondition,
          KeyNodes:this.keyNode,
          PlanDate:this.$dateFormat(this.planDate, 'yyyy-MM-dd'),
          ActualDate:this.$dateFormat(this.actuallyCompleteDate, 'yyyy-MM-dd'),
          ProcessComparison:this.processContrast
        }
      })
      if (success) {
        this.$emit('refresh')
        this.clearClick()
        this.$message.success('填报成功')
      }
      this.createLoading = false
    },
    /**
     * 编辑
     * */
    async updateChange() {
      this.createLoading = true
      const { success, data } = await this.$http({
        url: this.$api.updateProjectProgressWarn,
        method: 'POST',
        data: {
          ID: this.id,
          ProjectName: this.projectName,
          ProjectCode:'',
          DelayDay:this.lagCondition,
          KeyNodes:this.keyNode,
          PlanDate:this.$dateFormat(this.planDate, 'yyyy-MM-dd'),
          ActualDate:this.$dateFormat(this.actuallyCompleteDate, 'yyyy-MM-dd'),
          ProcessComparison:this.processContrast
        }
      })
      if (success) {
        this.$emit('refresh')
        this.clearClick()
        this.$message.success('编辑成功')
      }
      this.createLoading = false
    },
    /**
     *  取消 事件
     * */
    cancelClick() {
      this.$emit('closeDialog')
      this.clearClick()
    },
    /**
     *  清空 事件
     * */
    clearClick() {
      this.keyNode = ''
      this.projectName = ''
      this.planDate = ''
      this.actuallyCompleteDate = ''
      this.lagCondition = ''
      this.processContrast = ''
      this.dialogVisible = false
    }
  }
}
</script>
<style
  lang="scss"
  scoped
>
$tabsDefaultColor: #FFFFFF;
$tabsActiveColor: #42DEFD;
$borderColor: rgba(3, 175, 252, 0.66);

.tab {
  height: 500px;

  &__content {
    overflow: auto;
    height: calc(100% - 74px);
  }

  &__footer {
    height: 59px;
    margin: 1em -2.4em 0;
    padding: 0 2em;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    background-color: #FFFFFF;
  }
}
.label-box__gap{
  margin-bottom: 1.5em;
}
//dialog
::v-deep.el-dialog__wrapper {
  background-color: rgba(0, 0, 0, .5);
}

::v-deep .el-dialog__title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.85);
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.02) !important;
}

::v-deep .el-dialog__body {
  padding: 30px 34px 0;
}

//dialog
::v-deep .el-tabs {
  margin-bottom: 1em;

  &__header {
    margin: 0;
  }

  &__nav-wrap::after {
    background-color: transparent;
  }

  &__item {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    height: 31px;
    padding: 0 1em;
    color: $tabsDefaultColor;

    &.is-active {
      color: $tabsActiveColor;
    }
  }

  &__active-bar {
    color: $tabsActiveColor;
  }

  &__content {
    display: none;
  }
}
</style>
