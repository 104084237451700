<template>
  <div id="processInspection">
    <div class="flex row top-bar_wrapper">
      <div
        class="progress-name progress-name-active pointer"
      >项目进度履约预警
      </div>
    </div>
    <div
      class="flex flex1  item-center justify-between button-title__box"
    >
      <div class="flex">
        <div class="flex justify-start">
          <GreenButton
            class="button__default"
            text="导入"
            @click="importChange"
          ></GreenButton>
        </div>
        <div class="flex justify-start" style="margin-left: 1em">
          <GreenButton
            class="button__default"
            text="填报"
            @click="createChange"
          ></GreenButton>
        </div>
      </div>
      <div class="flex justify-start">
        <GreenButton
          class="button__default"
          text="小程序预警推送"
          @click="sendMessage"
        ></GreenButton>
      </div>
    </div>
    <el-table
      :data="dataList"
      :header-cell-style="{background: 'rgba(0,0,0,0.02)',borderTop:'1px solid rgba(0,0,0,0.1)'}"
      :loading="loading"
      class="table-box"
      height="calc(100% - 165px)"
    >
      <el-table-column
        type="index"
        prop="序号"
        :index="indexMethod"
      ></el-table-column>
      <el-table-column
        label="项目名称"
        prop="ProjectName"
      ></el-table-column>
      <el-table-column
        label="关键节点"
        prop="KeyNodes"
        width="250"
      ></el-table-column>
      <el-table-column
        label="计划完成时间"
        prop="PlanDate"
        width="150"
      >
        <template #default="scope">
          <span>{{ scope.row['PlanDate'] | timeDateFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="过程对比情况"
        prop="ProcessComparison"
        width="120"
      ></el-table-column>
      <el-table-column
        label="实际完成时间"
        prop="ActualDate"
        width="150"
      >
        <template #default="scope">
          <span>{{ scope.row['ActualDate'] | timeDateFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="滞后情况"
        prop="DelayDay"
        width="120"
      ></el-table-column>
      <!--      <el-table-column-->
      <!--        label="附件"-->
      <!--      >-->
      <!--        <template #default="scope">-->
      <!--          <div-->
      <!--            class="flex column"-->
      <!--            v-for="(urlItem,urlIndex) in scope.row['LinkFiles']"-->
      <!--            :key="urlIndex"-->
      <!--          >-->
      <!--            <div>{{ urlItem.Name }}</div>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column
        label="操作"
        width="160"
      >
        <template #default="scope">
          <el-link
            type="primary"
            @click="editInfo(scope.row)"
          >
            编辑
          </el-link>
          <el-link
            style="margin-left: 1em;"
            type="primary"
            @click="deleteInfo(scope.row)"
          >
            删除
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <div
      class="table-box__pagination flex flex1 justify-end items-center"
    >
      <el-pagination
        :current-page.sync="pageIndex"
        :page-size.sync="pageSize"
        :total="allTotal"
        layout="total, prev, pager, next"
        @current-change="onPageIndexChange"
      ></el-pagination>
    </div>
<!--   导入-->
    <ImportProjectWarning
      :visible="importDialogVisible"
      @closeDialog="onImportDialogClose"
      @refresh="onRefreshDialogClose"
    ></ImportProjectWarning>
<!--    添加或者编辑-->
    <CreateProjectWarningDialog
      :id="currentID"
      :is-edit="isCreateEdit"
      :visible="createDialogVisible"
      @closeDialog="onImportDialogClose"
      @refresh="onRefreshDialogClose"
    >
    </CreateProjectWarningDialog>
  </div>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'
import ImportProjectWarning from '@/components/dialog/ImportProjectWarning';
import CreateProjectWarningDialog from '@/components/dialog/CreateProjectWarningDialog'

let _this = void 0
//import { mapState, mapMutations } from 'vuex'
export default {
  name: 'projectWarning',
  mixins: [],
  components: { GreenButton, ImportProjectWarning ,CreateProjectWarningDialog},
  props: {},
  data() {
    return {
      pageIndex: 1,
      pageSize: 11,
      allTotal: 0,
      loading: false,
      dataList: [],
      createDialogVisible: false,
      isCreateEdit: false,
      currentID: -1,
      importDialogVisible: false
    }
  },
  computed: {
    //...mapState('', {}),
  },
  watch: {},
  filters: {
    timeDateFilter(date) {
      return typeof date === 'string' ? date.split('T')[0] : ''
    },
  },
  directives: {},
  created() {
    _this = this
  },
  mounted() {
    this.getDataInfo()
  },
  destroy() {
  },
  methods: {
    //...mapMutations('', []),
    /**
     * 获取 项目预警 数据
     * */
    async getDataInfo() {
      this.loading = true
      const { success, data } = await this.$http({
        url: this.$api.pageQueryProjectProgressWarn,
        params: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize
        }
      })
      if (success) {
        const { rows, total } = data
        if (data.hasOwnProperty('rows')) {
          this.dataList = [...rows]
          if (data.hasOwnProperty('total')) {
            this.allTotal = total
          }
        }
      }
      this.loading = false
    },
    /**
     * 切换分页
     * */
    onPageIndexChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getDataInfo()
    },
    /**
     * 创建
     * */
    createChange(){
      this.createDialogVisible = true;
      this.isCreateEdit = false
    },
    /**
     * 小程序发送消息
     * */
    sendMessage(){
      this.$confirm('是否确认发送', '小程序项目进度预警推送', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '推送中...'
            setTimeout(async () => {
              await this.commitSendMessage()
              done()
              setTimeout(() => {
                instance.confirmButtonLoading = false
              }, 300)
            }, 300)
          }
          else {
            done()
          }
        }
      })
    },
    // 提交消息推送
   async commitSendMessage(){
      const { success } = await this.$http({
        url: this.$api.pushMessage,
      })
      if (success) {
        this.$message.success('推送成功')
      }
    },
    /**
     * 填报 选择
     * */
    importChange() {
      this.importDialogVisible = true
    },
    /**
     * 填报 关闭dialog回调
     * */
    onImportDialogClose() {
      this.isCreateEdit = false
      this.createDialogVisible = false
      this.importDialogVisible = false
    },
    /**
     * 填报 刷新dialog回调
     * */
    onRefreshDialogClose() {
      this.isCreateEdit = false
      this.createDialogVisible = false
      this.importDialogVisible = false
      this.pageIndex = 1
      this.getDataInfo()
    },
    /**
     * 编辑信息
     * */
    editInfo(item) {
      const { ID } = item
      this.currentID = ID
      this.isCreateEdit = true
      this.createDialogVisible = true
    },
    /**
     * 删除信息
     * */
    deleteInfo(item) {
      const { ID } = item
      this.$confirm('是否确认删除', '删除项目履约预警数据', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '删除中...'
            setTimeout(async () => {
              await this.deleteChange(ID)
              done()
              setTimeout(() => {
                instance.confirmButtonLoading = false
              }, 300)
            }, 300)
          }
          else {
            done()
          }
        }
      })
    },
    /**
     * 确认删除
     * */
    async deleteChange(ID) {
      const { success } = await this.$http({
        url: this.$api.deleteProjectProgressWarn,
        params: {
          id: ID
        }
      })
      if (success) {
        this.$message.success('删除成功')
        this.pageIndex = 1
        await this.getDataInfo()
      }
    },
    indexMethod(index) {
      return index + 1
    }

  }
}
</script>
<style
  lang="scss"
  scoped
>
#processInspection {
  height: 100%;
}

::v-deep .el-link.el-link--primary {
  color: #005BAC;
}
</style>
<style lang="scss"></style>
