<template>
  <el-dialog
    :append-to-body="true"
    :before-close="onBeforeClose"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :modal="false"
    :title="titleName"
    :visible.sync="dialogVisible"
    custom-class="common-dialog"
    top="10vh"
    width="500px"
  >
    <div class="tab">
      <div class="tab__content">
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">标题</span>
          <el-input
            v-model="title"
            class="flex1"
            placeholder="请输入标题"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">检查人</span>
          <el-input
            v-model="checkStaff"
            class="flex1"
            placeholder="请输入检查人"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">检查日期</span>
          <el-date-picker
            v-model="checkDate"
            class="flex1"
            placeholder="选择日期"
            size="small"
            type="date"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>
      </div>
      <div class="tab__footer flex row justify-end items-center">
        <GreenButton
          :options="{
                    borderColor: 'rgba(26,89,184,0.33)',
                    borderRadius: '5px',
                    color: '#1A59B8 !important'
          }"
          class="button__cancel button__gap"
          text="取消"
          @click="cancelClick"
        ></GreenButton>
        <GreenButton
          :loading="createLoading"
          class="button__default"
          loading-text="处理中"
          text="确定"
          @click="onCreateClick"
        ></GreenButton>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'

export default {
  name: 'CreateProcessCheckResultDialog',
  mixins: [],
  components: {
    GreenButton
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      dialogVisible: false,
      checkStaff: '',
      title: '',
      checkDate: '',
      createLoading: false,
      linksList: [],
      fileData: {},
    }
  },
  computed: {
    titleName() {
      return this.isEdit ? '过程管控检查结果编辑' : '过程管控检查结果填报'
    }
  },
  watch: {
    visible(visible) {
      if (visible) {
        this.dialogVisible = true
        this.checkStaff = ''
        this.title = ''
        this.checkDate = ''
        if (this.isEdit) {
          this.getDataInfo()
        }
      }
    }
  },
  methods: {
    onBeforeClose(done) {
      this.$emit('closeDialog')
      this.clearClick()
      done()
    },

    /**
     * 获取 大型机械管理 数据
     * */
    async getDataInfo() {
      const { success, data } = await this.$http({
        url: this.$api.queryProcessControlCheckResult,
        params: {
          id: this.id
        }
      })
      if (success) {
        const {
          Title,
          CheckUser,
          CheckDate,
        } = data
        this.checkStaff = CheckUser || '';
        this.title = Title || '';
        this.checkDate = CheckDate?CheckDate.split('T')[0]:''
      }
    },
    /**
     * 点击确认按钮
     * */
    async onCreateClick() {
      if (this.title === '') {
        this.$message.error('请输入标题')
        return
      }
      if (this.checkStaff === '') {
        this.$message.error('请输入检查人')
        return
      }
      if (this.checkDate === '') {
        this.$message.error('请选择日期')
        return
      }
        await this.createOrUpdateCheckResult()
    },
    /**
     *  取消 事件
     * */
    cancelClick() {
      this.$emit('closeDialog')
      this.clearClick()
    },
    /**
     *  清空 事件
     * */
    clearClick() {
      this.checkStaff = ''
      this.title = ''
      this.checkDate = ''
      this.dialogVisible = false
    },
    /**
     * 创建、编辑
     * */
    async createOrUpdateCheckResult() {
      const { success } = await this.$http({
        url: this.$api.addOrUpdateProcessControlCheckResult,
        method: 'POST',
        data:{
          ID:this.id || 0,
          CheckUser:this.checkStaff,
          CheckDate:this.checkDate,
          Title:this.title
        }
      })
      if (success) {
        this.$emit('refresh')
        this.$message.success(this.isEdit ? '编辑成功' : '填报成功')
        this.clearClick()
      }
      this.createLoading = false
    },
  }
}
</script>
<style
  lang="scss"
  scoped
>
$tabsDefaultColor: #FFFFFF;
$tabsActiveColor: #42DEFD;
$borderColor: rgba(3, 175, 252, 0.66);

.tab {
  height: 300px;

  &__content {
    overflow: auto;
    height: calc(100% - 74px);
  }

  &__footer {
    height: 59px;
    margin: 1em -2.4em 0;
    padding: 0 2em;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    background-color: #FFFFFF;
  }
}

//dialog
::v-deep.el-dialog__wrapper {
  background-color: rgba(0, 0, 0, .5);
}

::v-deep .el-dialog__title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.85);
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.02) !important;
}

::v-deep .el-dialog__body {
  padding: 30px 34px 0;
}

//dialog
::v-deep .el-tabs {
  margin-bottom: 1em;

  &__header {
    margin: 0;
  }

  &__nav-wrap::after {
    background-color: transparent;
  }

  &__item {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    height: 31px;
    padding: 0 1em;
    color: $tabsDefaultColor;

    &.is-active {
      color: $tabsActiveColor;
    }
  }

  &__active-bar {
    color: $tabsActiveColor;
  }

  &__content {
    display: none;
  }
}

::v-deep .el-upload-list__item-name {
  max-width: 180px;
}
</style>
