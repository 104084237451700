<template>
  <div class="year-output-wrapper">
    <div class="year-select-wrapper flex items-center ">
      <div class="year-title">年份</div>
      <el-date-picker
        v-model="yearParam"
        type="year"
        @change="yearChange"
        format="yyyy"
        placeholder="选择年份"
      ></el-date-picker>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      :header-cell-style="{background: 'rgba(0,0,0,0.02)',borderTop:'1px solid rgba(0,0,0,0.1)'}"
      :loading="loading"
      row-key="ID"
      default-expand-all
      :tree-props="{children: 'Children'}"
    >
      <el-table-column
        label="时间"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.isParent"
            style="font-weight: 700;"
          > {{ scope.row.Year }}年</span>
          <span v-else> {{ scope.row.Year }}年{{ scope.row.Month }}月</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="Plan"
        label="产值指标（万）"
      >
        <template slot-scope="scope">
          <span> {{ scope.row.Plan }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="Total"
        label="累计产值 (万)"
      ></el-table-column>
      <el-table-column
        prop="CompletionRate"
        label="完成率"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.CompletionRate"> {{ sumRate(scope.row.CompletionRate) }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="90"
      >
        <template #default="scope">
          <el-link
            type="primary"
            @click="editRow(scope.row)"
          >
            编辑
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="年度产值编辑"
      :append-to-body="true"
      :destroy-on-close="true"
      :modal="false"
      :visible.sync="dialogVisible"
      width="500px"
      custom-class="common-dialog"
    >
      <div class="tab">
        <div
          class="tab__content"
          style="margin-bottom: 2em;"
        >
          <div class="flex row justify-between fault-box">
            <span class="import-box__label">产值指标</span>
            <el-input
              v-model="rowData.planVal"
              class="flex1"
              placeholder="请输入产值指标"
              size="small"
            ></el-input>
          </div>
          <div
            class="flex row justify-between fault-box"
            v-if="!isYearEdit"
          >
            <span class="import-box__label">累计产值</span>
            <el-input
              v-model="rowData.cumulativeVal"
              class="flex1"
              placeholder="请输累计产值"
              size="small"
            ></el-input>
          </div>
        </div>
        <div class="tab__footer flex row justify-end items-center">
          <GreenButton
            :options="{
             borderColor: 'rgba(26,89,184,0.33)',
             borderRadius: '5px',
             color: '#1A59B8 !important'
          }"
            class="button__delete"
            style="margin-right: 0.5em !important;"
            text="取消"
            @click="cancel"
          ></GreenButton>
          <GreenButton
            class="button__import"
            loading-text="处理中"
            text="确定"
            @click="commit"
          ></GreenButton>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'

export default {
  name: 'yearOutputTotal',
  components: {
    GreenButton
  },
  data() {
    return {
      tableData: [],
      loading: false,
      dialogVisible: false,
      rowData: {
        year: 0,  //年份
        planVal: 0, //计划产值
        cumulativeVal: 0, // 累计产值
        month: 0 //月份
      },
      // 是否选中第一行年份做编辑
      isYearEdit: false,
      rowID: 0,
      yearParam: new Date().getFullYear().toString()
    }
  },
  mounted() {
    this.getYearTotalData()
  },
  computed: {
    // 计算完成率，保留2位小数
    sumRate() {
      return num => {
        if (num === 0 || !num || num === '0') {
          return '0%'
        }
        else {
          return (num * 100).toFixed(2) + '%'
        }
      }
    }
  },
  methods: {
    /*
     获取年度列表数据
     * */
    async getYearTotalData() {
      this.loading = true
      this.tableData = []
      const { success, data } = await this.$http({
        url: this.$api.queryYearOutputValueManagement + '?year=' + this.yearParam,
      })
      if (success) {
        const { rows } = data
        let monthDataList = rows['MonthTotals']
        // 换成tree结构的数据
        let yearObj = {
          Year: rows.Year,
          Plan: rows.Plan,
          CompletionRate: rows.CompletionRate,
          Total: rows['SumTotals'],
          ID: rows.Year, // TODO 后端传来的年度ID会和月度的重复，现在定义年度的ID,确保唯一性
          isParent: true,
          Children: monthDataList,
        }
        this.tableData.push(yearObj)
        this.loading = false
      }
    },
    // 点击编辑按钮
    editRow(item) {
      const { ID, isParent, Plan, Total, Year, Month } = item
      this.isYearEdit = !!isParent
      let obj = { year: Year, planVal: Plan, cumulativeVal: Total, month: Month }
      this.rowData = { ...obj }
      this.rowID = ID
      this.dialogVisible = true
    },
    cancel() {
      this.dialogVisible = false
    },
    yearChange(time) {
      let year = new Date(time).getFullYear().toString()
      this.yearParam = year
      this.getYearTotalData()
    },
    // 提交编辑接口
    async commit() {
      const Url = this.isYearEdit ? this.$api.addYearPlanOutputValue : this.$api.addOrupdateMonthTotalOutputValue
      //编辑月度产值
      const { success } = await this.$http({
        url: Url,
        method: 'POST',
        data: {
          ID: this.rowID,
          Total: +this.rowData.cumulativeVal,
          Plan: +this.rowData.planVal,
          Month: this.rowData.month,
          Year: this.rowData.year,
        }
      })
      if (success) {
        this.$message({
          message: '编辑成功',
          duration: 15000,
          type: 'success'
        })
        this.dialogVisible = false
        await this.getYearTotalData()
      }
    }
  }
}
</script>
<style scoped>
.year-output-wrapper {
  padding: 1em
}

.year-select-wrapper {
  margin-bottom: 1em;
}

.year-title {
  margin-right: 10px;
  font-size: 14px;
}

/deep/ .has-gutter {
  background-color: rgba(0, 0, 0, 0.02) !important;
}

.button__import {
  border: 1px solid #005BAC !important;
  border-radius: 4px !important;
  background: #005BAC !important;
}

.button__delete {
  font-weight: 400 !important;
  margin-right: 0.5em !important;
  border: 1px solid rgba(26, 89, 184, 0.33) !important;
  border-radius: 4px !important;
  background: rgba(26, 89, 184, 0.05) !important;
}

.fault-box {
  margin-bottom: 1em;
}

.import-box__label {
  font-size: 13px;
  font-weight: 400;
  line-height: 32px;
  width: 100px;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 0.85);
}

.year-output-wrapper /deep/ .el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.02) !important;
}

.year-output-wrapper /deep/ .el-date-editor {
  width: 150px;
}

.year-output-wrapper /deep/ .el-input__inner {
  width: 150px;
  height: 32px;
  line-height: 32px;
}

.year-output-wrapper /deep/ .el-date-editor .el-input__icon {
  line-height: 32px;
}
</style>
