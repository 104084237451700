<template>
  <div
    id="login"
    class="flex flex1 justify-center items-center column"
  >
    <div class="login-header"></div>
    <div class="login__container">
      <el-form
        ref="ruleForm"
        :model="loginForm"
        :rules="rules"
        label-position="top"
        label-width="60px"
      >
        <el-form-item
          prop="name"
        >
          <el-input
            v-model="loginForm.name"
            :disabled="loading"
            clearable
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="password"
        >
          <el-input
            v-model="loginForm.password"
            :disabled="loading"
            clearable
            placeholder="请输入密码"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="flex flex1 justify-between items-center">
        <el-checkbox
          v-model="rememberMe"
          :disabled="loading"
        >
          记住密码
        </el-checkbox>
      </div>
      <div
        class="flex flex1 justify-end items-center"
        style="margin-top: 1em;"
      >
        <el-button
          :loading="loading"
          type="primary"
          @click="loginSubmit"
        >
          登录
        </el-button>
      </div>
    </div>
    <div class="login-footer"></div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'

let _this = void 0

export default {
  name: 'Login',
  mixins: [],
  data() {
    return {
      // loading状态
      loading: false,
      // 登录校验
      rules: {
        name: [{ required: true, message: '手机号不能为空', trigger: 'blur' }],
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }]
      },
      // 登录表单
      loginForm: {
        name: '',
        password: ''
      },
      // 是否记住密码
      rememberMe: false,
    }
  },
  computed: {},
  watch: {},
  created() {
    _this = this
  },
  mounted() {
    document.addEventListener('keydown', this.onEnterClick, false)
    this.rememberMe = window.localStorage.getItem('CRCC_REMEMBER_ME') === 'true'
    if (this.rememberMe) {
      try {
        this.loginForm = JSON.parse(window.localStorage.getItem('CRCC_USER_LOGIN'))
      }
      catch (e) {
        /**/
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onEnterClick, false)
  },
  methods: {
    ...mapMutations({
      setRouter: 'setRouter'
    }),
    ...mapMutations('user', ['setUserInfo']),
    /**
     * 监听回车事件
     * @param e {event} 事件
     */
    onEnterClick(e) {
      const event = e || window.event
      const key = event.which || event.keyCode || event.charCode
      if (key === 13) {
        this.loginSubmit()
      }
    },
    /**
     * 登录信息校验
     */
    loginSubmit() {
      this.loading = true
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          await _this.login()
        }
        else {
          _this.loading = false
          return false
        }
      })
    },
    /**
     * 登录
     * @return {Promise<void>}
     */
    async login(state, payload) {
      const { success, data } = await this.$http({
        url: this.$api.goLogin,
        method: 'post',
        data: {
          UserName: this.loginForm.name,
          Password: this.loginForm.password
        }
      })
      if (success) {
        window.localStorage.setItem('CRCC_REMEMBER_ME', this.rememberMe)
        window.localStorage.setItem('CRCC_USER_LOGIN', JSON.stringify(this.loginForm))
        // 设置用户信息
        await this.setUserInfo(data)
        // 路由跳转
        await this.setRouter({ name: 'OutputManagement' })
      }
      this.loading = false
    }
  }
}
</script>
<style
  lang="scss"
  scoped
>
#login {
  width: calc(100vw);
  height: calc(100vh);
  margin: 0;
  padding: 0;
  background: linear-gradient(180deg, #005099, #2C6DDC 50%, #31C4E1);
  background-size: 100% 100%;
  backdrop-filter: blur(10px);
  
  .login-header {
    width: 372px;
    height: 180px;
    background-image: url("../assets/bg/login_top.png");
  }
  
  .login-footer {
    width: 395px;
    height: 220px;
    margin-top: 40px;
    background-image: url("../assets/bg/login_bottom.png");
  }
  
  .login__container {
    position: relative;
    padding: 3em 3em;
    //background: linear-gradient(180deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2));
    //box-shadow: 4px 6px 2px 0 #00000033;
    //backdrop-filter: blur(12px);
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.12);
    
  }
  
  ::v-deep .el-form--label-top .el-form-item__label {
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    width: 100%;
    padding: 0 0 0.5em;
    text-align: left;
    color: #FFFFFF;
    text-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.33);
  }
  
  ::v-deep
  .el-form-item.is-required:not(.is-no-asterisk)
  .el-form-item__label-wrap
  > .el-form-item__label::before,
  ::v-deep .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label::before {
    display: none;
  }
  
  ::v-deep .el-input__inner {
    font-size: 14px;
    font-weight: 400;
    width: 320px;
    text-align: left;
    color: #333333;
    background-color: #F5F5F5;
  }
  
  ::v-deep .el-form-item__error {
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    position: absolute;
    top: 100%;
    left: 0;
    padding-top: 4px;
    color: #FA3D3D;
  }
  
  ::v-deep .el-button {
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    opacity: 1;
    border-radius: 4px;
    background: #00C0C7;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
  
  ::v-deep .el-checkbox {
    display: flex;
    align-items: center;
    flex-flow: row;
    justify-content: flex-start;
    color: #ECECEC;
    
    .el-checkbox__input {
      display: flex;
      color: #ECECEC;
    }
    
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #ECECEC;
    }
    
    .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      border-color: #52C41A;
      background-color: #52C41A;
    }
    
    
    .el-checkbox__label {
      line-height: 1;
      padding-left: 0.5em;
    }
  }
}
</style>
