<template>
  <div id="roleManagement">
    <div class="flex row top-bar_wrapper">
      <div
        class="progress-name progress-name-active pointer"
      >角色管理
      </div>
    </div>
    <div
      class="flex flex1 justify-between item-center button-title__box"
    >
      <div class="flex justify-start">
        <GreenButton
          class="button__default"
          text="添加角色"
          @click="createChange"
        ></GreenButton>
      </div>
      <div class="flex justify-end">
        <el-input
          v-model="searchKey"
          class="button__gap"
          placeholder="请输入关键字"
          size="small"
          @keyup.enter.native="searchChange"
        ></el-input>
        <GreenButton
          class="button__default"
          text="查询"
          @click="searchChange"
        ></GreenButton>
      </div>
    </div>
    <el-table
      :data="dataList"
      :header-cell-style="{background: 'rgba(0,0,0,0.02)',borderTop:'1px solid rgba(0,0,0,0.1)'}"
      :loading="loading"
      class="table-box"
      height="calc(100% - 165px)"
    >
      <el-table-column
        label="角色名称"
        prop="Name"
      ></el-table-column>
      <el-table-column
        label="描述"
        prop="Description"
      ></el-table-column>
      <el-table-column
        label="操作"
        width="160"
      >
        <template #default="scope">
          <el-link
            type="primary"
            @click="permissionInfo(scope.row)"
          >
            权限
          </el-link>
          <el-link
            type="primary"
            style="margin-left: 1em;"
            @click="editInfo(scope.row)"
          >
            编辑
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <div
      class="table-box__pagination flex flex1 justify-end items-center"
    >
      <el-pagination
        :current-page.sync="pageIndex"
        :page-size.sync="pageSize"
        :total="allTotal"
        layout="total, prev, pager, next"
        @current-change="onPageIndexChange"
      ></el-pagination>
    </div>
    <CreateRoleDialog
      :id="currentID"
      :is-edit="isCreateEdit"
      :visible="createDialogVisible"
      @closeDialog="onImportDialogClose"
      @refresh="onRefreshDialogClose"
    ></CreateRoleDialog>
    <PermissionManagementDialog
      :id="currentID"
      :visible="permissionDialogVisible"
      @closeDialog="onImportDialogClose('permission')"
      @refresh="onRefreshDialogClose('permission')"
    ></PermissionManagementDialog>
  </div>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'
import CreateRoleDialog from '@/components/dialog/CreateRoleDialog'
import PermissionManagementDialog from '@/components/dialog/PermissionManagementDialog'

let _this = void 0
export default {
  name: 'roleManagement',
  mixins: [],
  components: { GreenButton, CreateRoleDialog, PermissionManagementDialog },
  props: {},
  data() {
    return {
      pageIndex: 1,
      pageSize: 11,
      allTotal: 0,
      loading: false,
      dataList: [],
      createDialogVisible: false,
      isCreateEdit: false,
      currentID: -1,
      searchKey: '',
      permissionDialogVisible: false
    }
  },
  computed: {
    //...mapState('', {}),
  },
  watch: {},
  filters: {},
  directives: {},
  created() {
    _this = this
  },
  mounted() {
    this.getDataInfo()
  },
  destroy() {
  },
  methods: {
    //...mapMutations('', []),
    /**
     * 获取 用户管理 数据
     * */
    async getDataInfo() {
      this.loading = true
      const { success, data } = await this.$http({
        url: this.$api.getPageQuerySystemRole,
        params: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          search: this.searchKey
        }
      })
      if (success) {
        const { rows, total } = data
        if (data.hasOwnProperty('rows')) {
          this.dataList = [...rows]
          if (data.hasOwnProperty('total')) {
            this.allTotal = total
          }
        }
      }
      this.loading = false
    },
    /**
     * 切换分页
     * */
    onPageIndexChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getDataInfo()
    },
    /**
     * 填报 选择
     * */
    createChange() {
      this.createDialogVisible = true
    },
    /**
     * 填报和权限 关闭dialog回调
     * */
    onImportDialogClose(type) {
      if (type === 'permission') {
        this.permissionDialogVisible = false
      }
      else {
        this.isCreateEdit = false
        this.createDialogVisible = false
      }
    },
    /**
     * 填报和权限 刷新dialog回调
     * */
    onRefreshDialogClose(type) {
      if (type === 'permission') {
        this.permissionDialogVisible = false
      }
      else {
        this.isCreateEdit = false
        this.createDialogVisible = false
      }
      this.pageIndex = 1
      this.getDataInfo()
    },
    /**
     * 权限 信息
     * */
    permissionInfo(item) {
      const { ID } = item
      this.currentID = ID
      this.permissionDialogVisible = true
    },
    editInfo(item) {
      const { ID } = item
      window.sessionStorage.setItem('ROLE_INFO', JSON.stringify({ ...item }))
      this.currentID = ID
      this.isCreateEdit = true
      this.createDialogVisible = true
    },
    /**
     * 关键字查询
     * */
    searchChange() {
      this.pageIndex = 1
      this.getDataInfo()
    }
  }
}
</script>
<style
  lang="scss"
  scoped
>
#roleManagement {
  height: 100%;
}

::v-deep .el-link.el-link--primary {
  color: #005BAC;
}
</style>
<style lang="scss"></style>
