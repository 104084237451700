<template>
  <div id="region-machine-management">
    <div class="flex flex1 justify-between item-center button-title__box">
      <div class="flex justify-start">
        <GreenButton
          class="button__default"
          text="填报"
          @click="createChange"
        ></GreenButton>
      </div>
      <div class="flex items-center">
        <div class="year-select-wrapper flex items-center ">
          <div class="year-title">年份</div>
          <el-date-picker
            v-model="yearParam"
            type="year"
            @change="yearChange"
            format="yyyy"
            placeholder="选择年份"
          ></el-date-picker>
        </div>
        <div
          class="flex items-center year-select-wrapper"
          style="margin-left: 10px"
        >
          <div class="year-title">季度</div>
          <el-select
            v-model="quarterParam"
            placeholder="请选择季度"
            @change="quarterChange"
          >
            <el-option
              v-for="item in quarterOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <el-table
      :data="tableData"
      :header-cell-style="{background: 'rgba(0,0,0,0.02)',borderTop:'1px solid rgba(0,0,0,0.1)'}"
      :loading="loading"
      class="table-box"
      height="calc(100% - 165px)"
    >
      <el-table-column
        label="标题"
        prop="Title"
      ></el-table-column>
      <el-table-column
        label="在施项目"
        prop="InProgressCount"
      ></el-table-column>
      <el-table-column
        label="大型机械总数"
        prop="Count"
      ></el-table-column>
      <el-table-column
        label="各类隐患总数"
        prop="DangerCount"
      ></el-table-column>
      <el-table-column
        label="操作"
        width="220"
      >
        <template #default="scope">
          <el-link
            type="primary"
            @click="showDetail(scope.row)"
          >
            查看
          </el-link>
          <el-link
            type="primary"
            style="margin-left: 1em;"
            @click="editInfo(scope.row)"
          >
            编辑
          </el-link>
          <el-link
            style="margin-left: 1em;"
            type="primary"
            @click="deleteInfo(scope.row)"
          >
            删除
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <div
      class="table-box__pagination flex flex1 justify-end items-center"
    >
      <el-pagination
        :current-page.sync="pageIndex"
        :page-size.sync="pageSize"
        :total="totalNum"
        layout="total, prev, pager, next"
        @current-change="onPageIndexChange"
      ></el-pagination>
    </div>
    <CreateRegionMachineManagementDialog
      :visible="createDialogVisible"
      :status="status"
      :id="itemId"
      @closeDialog="closeDialog"
      @refresh="refresh"
    ></CreateRegionMachineManagementDialog>
  </div>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'
import CreateRegionMachineManagementDialog from '@/components/dialog/CreateRegionMachineManagementDialog'

export default {
  name: 'regionMachineManagement',
  components: {
    GreenButton,
    CreateRegionMachineManagementDialog
  },
  mounted() {
    this.getRegionMachineData()
  },
  data() {
    return {
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      loading: false,
      totalNum: 0,
      itemId: -1,
      createDialogVisible: false,
      // 操作类型 create detail edit
      status: 'create',
      yearParam: new Date().getFullYear().toString(),
      // 季度 分别是 1 2 3 4
      quarterParam: this.getCurrentQuarterByMonth(),
      quarterOptions: [
        {
          value: '1',
          label: '第一季度'
        },
        {
          value: '2',
          label: '第二季度'
        },
        {
          value: '3',
          label: '第三季度'
        },
        {
          value: '4',
          label: '第四季度'
        }
      ]
    }
  },
  methods: {
    async getRegionMachineData() {
      this.loading = true
      const { success, data } = await this.$http({
        url: this.$api.getPageQueryMachineManage,
        params: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          Year: this.yearParam,
          Quarter: this.quarterParam

        }
      })
      if (success) {
        this.loading = false
        const { rows, total } = data
        this.tableData = rows
        this.totalNum = total
      }
    },
    // 年度改变
    yearChange(time) {
      let year = new Date(time).getFullYear().toString()
      this.yearParam = year
      this.getRegionMachineData()
    },
    // 季度改变
    quarterChange(val) {
      this.getRegionMachineData()
    },
    // 填报
    createChange() {
      this.createDialogVisible = true
      this.status = 'create'
    },
    // 查看详情
    showDetail(item) {
      this.itemId = item.ID
      this.createDialogVisible = true
      this.status = 'detail'
    },
    /*
     * 编辑行
     * */
    editInfo(item) {
      this.itemId = item.ID
      this.createDialogVisible = true
      this.status = 'edit'
    },
    // 删除行，弹出提示框
    async deleteInfo(item) {
      const { ID } = item
      await this.$confirm('是否确认删除', '删除大型机械管理', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            await this.deleteChange(ID)
            done()
          }
          else {
            done()
          }
        }
      })
    },
    /**
     * 确认删除
     * */
    async deleteChange(ID) {
      const { success } = await this.$http({
        url: this.$api.getDeleteMachineManage,
        params: {
          id: ID
        }
      })
      if (success) {
        this.$message.success('删除成功')
        this.pageIndex = 1
        await this.getRegionMachineData()
      }
    },
    // 刷新数据
    refresh() {
      this.pageIndex = 1
      this.createDialogVisible = false
      this.getRegionMachineData()
    },
    // 关闭弹框
    closeDialog() {
      this.createDialogVisible = false
    },
    onPageIndexChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getRegionMachineData()
    },
    //获取当前的季度,传给后端
    getCurrentQuarterByMonth() {
      let date = new Date()
      let month = date.getMonth() + 1
      if (month >= 1 && month <= 3) {
        return '1'
      }
      else if (month >= 4 && month <= 6) {
        return '2'
      }
      else if (month >= 7 && month <= 9) {
        return '3'
      }
      else if (month >= 10 && month <= 12) {
        return '4'
      }
    }
  }
}
</script>
<style scoped>
#region-machine-management {
  height: 100%;
}

.year-select-wrapper /deep/ .el-date-editor {
  width: 150px;
}

.year-select-wrapper /deep/ .el-input__inner {
  width: 150px;
  height: 32px;
  line-height: 32px;
}

.year-select-wrapper /deep/ .el-date-editor .el-input__icon {
  line-height: 32px;
}

.year-title {
  margin: 0 10px
}
</style>
