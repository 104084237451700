<template>
  <div class="year-plan-wrapper">
    <div class="flex flex1 justify-between item-center button-title__box">
      <div class="flex justify-start">
        <GreenButton
          class="button__default"
          text="填报"
          @click="createChange"
        ></GreenButton>
      </div>
      <div class="flex items-center year-select-wrapper">
        <div class="year-title">年份</div>
        <el-date-picker
          v-model="yearParam"
          type="year"
          @change="yearChange"
          format="yyyy"
          placeholder="选择年份"
        ></el-date-picker>
      </div>
    </div>
    <el-table
      :data="tableData"
      :header-cell-style="{background: 'rgba(0,0,0,0.02)',borderTop:'1px solid rgba(0,0,0,0.1)',textAlign:'center'}"
      :loading="loading"
      height="calc(100% - 165px)"
      style="width: 100%"
    >
      <el-table-column
        prop="Total"
        label="营业收入指标"
        width="150"
      ></el-table-column>
      <el-table-column label="电">
        <el-table-column
          prop="ElectricTarget"
          label="能耗量指标（吨标准煤）"
        ></el-table-column>
        <el-table-column
          prop="ElectricConsume"
          label="折算消耗量指标（万度）"
        ></el-table-column>
      </el-table-column>

      <el-table-column label="汽油">
        <el-table-column
          prop="GasolineTarget"
          label="能耗量指标（吨标准煤）"
        ></el-table-column>
        <el-table-column
          prop="GasolineConsume"
          label="折算消耗量指标（升）"
        ></el-table-column>
      </el-table-column>

      <el-table-column label="液化石油气">
        <el-table-column
          prop="LPGTarget"
          label="能耗量指标（吨标准煤）"
        ></el-table-column>
        <el-table-column
          prop="LPGConsume"
          label="折算消耗量指标（千克）"
        ></el-table-column>
      </el-table-column>
      <el-table-column
        label="操作"
        width="100"
      >
        <template #default="scope">
          <el-link
            type="primary"
            style="margin-left: 1em;"
            @click="editInfo(scope.row)"
          >
            编辑
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <div
      class="table-box__pagination flex flex1 justify-end items-center"
    >
      <el-pagination
        :current-page.sync="pageIndex"
        :page-size.sync="pageSize"
        :total="totalNum"
        layout="total, prev, pager, next"
        @current-change="onPageIndexChange"
      ></el-pagination>
    </div>

    <CreateYearPlanDialog
      :yearProp="yearProp"
      :is-edit="isEdit"
      :visible="createDialogVisible"
      @closeDialog="onImportDialogClose"
      @refresh="onRefreshDialogClose"
    >
    </CreateYearPlanDialog>
  </div>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'
import CreateYearPlanDialog from '@/components/dialog/energyConservation/CreateYearPlanDialog'
export default {
  name: 'yearPlan',
  components: {
    GreenButton,
    CreateYearPlanDialog
  },
  data() {
    return {
      tableData: [],
      loading: false,
      yearParam: new Date().getFullYear().toString(),
      pageIndex:1,
      pageSize:10,
      totalNum:0,
      isEdit:false,
      createDialogVisible:false,
      yearProp:''

    }
  },
  mounted() {
    this.getYearPlanTableData()
  },
  methods: {
    //点击填报按钮
    createChange() {
      this.isEdit = false
      this.createDialogVisible = true
    },
    //年份改变
    yearChange(time) {
      let year = new Date(time).getFullYear().toString()
      this.yearParam = year;
      this.getYearPlanTableData()
    },
    // 获取年度计划数据
    async getYearPlanTableData() {
      this.loading = true
      const { success, data } = await this.$http({
        url: this.$api.pageQueryYearEnergyTarget,
        params: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          Year: this.yearParam,
        }
      })
      if (success) {
        this.loading = false
        const { rows, total } = data
        this.tableData = rows
        this.totalNum = total
      }
    },
    // 编辑功能
    editInfo(row){
      this.yearProp = row.Year.toString();
      this.createDialogVisible = true;
      this.isEdit = true;
    },
    // 分页改变事件
    onPageIndexChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getYearPlanTableData()
    },
    /**
     * 导入或填报 关闭dialog回调
     * */
    onImportDialogClose(type) {
      this.isEdit = false
      this.createDialogVisible = false
    },
    /**
     * 导入或填报 刷新dialog回调
     * */
    onRefreshDialogClose(type) {
      this.isEdit = false
      this.createDialogVisible = false
      this.pageIndex = 1
      this.getYearPlanTableData()
    },
  }
}
</script>
<style scoped>
.year-plan-wrapper {
  height: 100%;
  width: 100%;
}

::v-deep .el-table__header {
  width: 100% !important;
}

.year-select-wrapper /deep/ .el-date-editor {
  width: 150px;
}

.year-select-wrapper /deep/ .el-input__inner {
  width: 150px;
  height: 32px;
  line-height: 32px;
}

.year-select-wrapper /deep/ .el-date-editor .el-input__icon {
  line-height: 32px;
}

.year-title {
  margin: 0 10px
}
</style>
