<template>
  <div id="creditEvaluation">
    <div class="flex row top-bar_wrapper">
      <div
        class="progress-name progress-name-active pointer"
      >外部信用评价
      </div>
    </div>
    <div
      class="flex flex1 justify-between item-center button-title__box"
    >
      <div class="flex justify-start">
        <GreenButton
          class="button__default"
          text="导入"
          @click="importChange"
        ></GreenButton>
      </div>
    </div>
    <el-table
      :data="dataList"
      :header-cell-style="{background: 'rgba(0,0,0,0.02)',borderTop:'1px solid rgba(0,0,0,0.1)'}"
      :loading="loading"
      class="table-box"
      height="calc(100% - 165px)"
      @cell-dblclick="openDetailChange"
    >
      <el-table-column
        label="标题"
        prop="$year"
      ></el-table-column>
      <el-table-column
        label="参评项目"
        prop="Count"
      ></el-table-column>
      <el-table-column
        label="操作"
        width="120"
      >
        <template #default="scope">
          <el-link
            type="primary"
            @click="openDetailChange(scope.row)"
          >
            详情
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <ImportCreditEvaluation
      :visible="importDialogVisible"
      @closeDialog="onImportDialogClose"
      @refresh="onRefreshDialogClose"
    ></ImportCreditEvaluation>
  </div>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'
import ImportCreditEvaluation from '@/components/dialog/ImportCreditEvaluation'
import { mapMutations } from 'vuex'

let _this = void 0
//import { mapState, mapMutations } from 'vuex'

export default {
  name: 'creditEvaluation',
  mixins: [],
  components: { GreenButton, ImportCreditEvaluation },
  props: {},
  data() {
    return {
      pageIndex: 1,
      pageSize: 11,
      allTotal: 0,
      loading: false,
      dataList: [],
      importDialogVisible: false,
      currentID: -1
    }
  },
  computed: {
    //...mapState('', {}),
  },
  watch: {},
  filters: {
    timeDateFilter(date) {
      return typeof date === 'string' ? date.split('T')[0] : ''
    },
  },
  directives: {},
  created() {
    _this = this
  },
  mounted() {
    this.getDataInfo()
  },
  destroy() {
  },
  methods: {
    ...mapMutations({
      setRouter: 'setRouter'
    }),
    ...mapMutations('management', ['setCreditEvaluationInfo']),
    /**
     * 获取 外部信用评价 数据
     * */
    async getDataInfo() {
      this.loading = true
      const { success, data } = await this.$http({
        url: this.$api.getCreditEvaluationStatistics
      })
      if (success) {
        this.dataList = [...data]
        let items = []
        items = this.dataList
        for (let i = 0; i < items.length; i++) {
          const item = items[i]
          this.$set(item, '$year', item.Year + item.Type)
        }
      }
      this.loading = false
    },
    /**
     * 导入 选择
     * */
    importChange() {
      this.importDialogVisible = true
    },
    /**
     * 导入 关闭dialog回调
     * */
    onImportDialogClose() {
      this.importDialogVisible = false
    },
    /**
     * 导入 刷新dialog回调
     * */
    onRefreshDialogClose() {
      this.importDialogVisible = false
      this.pageIndex = 1
      this.getDataInfo()
    },
    /**
     * 双击或点击详情链接 进入详情
     * */
    async openDetailChange(item) {
      await this.setCreditEvaluationInfo(item)
      await this.setRouter({ name: 'CreditEvaluationDetail' })
    }
  }
}
</script>
<style
  lang="scss"
  scoped
>
#creditEvaluation {
  height: 100%;
}

::v-deep .el-link.el-link--primary {
  color: #005BAC;
}
</style>
<style lang="scss"></style>
