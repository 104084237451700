<template>
  <el-dialog
    :append-to-body="true"
    :before-close="onBeforeClose"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :modal="false"
    :title="titleName"
    :visible.sync="dialogVisible"
    custom-class="common-dialog"
    top="10vh"
    width="600px"
  >
    <div class="tab">
      <div class="tab__content">
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">年份</span>
          <el-date-picker
            v-model="year"
            type="year"
            format="yyyy"
            @change="yearChange"
            placeholder="选择年份"
          ></el-date-picker>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">季度</span>
          <el-select
            v-model="quarter"
            placeholder="请选择季度"
          >
            <el-option
              v-for="item in quarterOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">标题</span>
          <el-input
            v-model="title"
            class="flex1"
            placeholder="请输入标题"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">在施项目数量</span>
          <el-input
            v-model="projectCount"
            class="flex1"
            onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode)))"
            placeholder="请输入在施项目数量"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">大型机械总数</span>
          <el-input
            v-model="mechanicalCount"
            class="flex1"
            onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode)))"
            placeholder="请输入大型机械总数"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">各类隐患总数</span>
          <el-input
            v-model="dangerCount"
            class="flex1"
            onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode)))"
            placeholder="请输入各类隐患总数"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between label-box__gap">
          <span
            class="dialog-label table-title"
          >大型机械设备检测情况</span>
        </div>
        <div class="mechanical-table-wrapper">
          <div class="create-btn-box">
            <GreenButton
              class="button__default"
              loading-text="处理中"
              text="添加"
              @click="createRow"
            ></GreenButton>
          </div>
          <el-table
            :data="mechanicalList"
            :header-cell-style="{background: 'rgba(0,0,0,0.02)',textAlign:'center'}"
            border
            align="center"
            style="width: 100%"
          >
            <el-table-column
              label="序号"
              type="index"
              width="50"
              :index="indexMethod"
            ></el-table-column>
            <el-table-column
              prop="Name"
              label="大型机械名称"
              width="220"
            >
              <template #default="scope">
                <el-input v-model="scope.row.Name"></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="Count"
              label="数量"
            >
              <template #default="scope">
                <el-input v-model="scope.row.Count"></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="DangerCount"
              label="隐患数量"
            >
              <template #default="scope">
                <el-input v-model="scope.row.DangerCount"></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="80"
            >
              <template #default="scope">
                <el-link
                  style="margin-left: 1em;"
                  type="primary"
                  @click="deleteRow(scope.$index)"
                >
                  删除
                </el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div
        class="tab__footer flex row justify-end items-center"
        v-if="status!=='detail'"
      >
        <GreenButton
          :options="{
                    borderColor: 'rgba(26,89,184,0.33)',
                    borderRadius: '5px',
                    color: '#1A59B8 !important'
          }"
          class="button__cancel  button__gap"
          text="取消"
          @click="cancelClick"
        ></GreenButton>
        <GreenButton
          :loading="createLoading"
          class="button__default"
          loading-text="处理中"
          text="确定"
          @click="onCreateClick"
        ></GreenButton>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'

export default {
  name: 'CreateRegionMachineManagementDialog',
  mixins: [],
  components: {
    GreenButton
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: 'create'
    },
    id: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      dialogVisible: false,
      projectCount: '',
      mechanicalCount: '',
      dangerCount: '',
      year:new Date().getFullYear().toString(),
      title: '',
      createLoading: false,
      mechanicalList: [],
      quarter: '',
      quarterOptions: [
        {
          value: '1',
          label: '第一季度'
        },
        {
          value: '2',
          label: '第二季度'
        },
        {
          value: '3',
          label: '第三季度'
        },
        {
          value: '4',
          label: '第四季度'
        }
      ]

    }
  },
  computed: {
    titleName() {
      if (this.status === 'create') {
        return '大型机械管理填报'
      }
      else if (this.status === 'edit') {
        return '大型机械管理编辑'
      }
      else {
        return '大型机械管理详情'
      }
    }
  },
  watch: {
    visible(visible) {
      if (visible) {
        this.dialogVisible = true
        this.projectCount = ''
        this.mechanicalCount = ''
        this.dangerCount = ''
        this.mechanicalList = []
        this.title = ''
        this.year = new Date().getFullYear().toString()
        this.quarter = ''
        if (this.status !== 'create') {
          this.getDataInfo()
        }
      }
    }
  },
  methods: {
    onBeforeClose(done) {
      this.$emit('closeDialog')
      this.clearClick()
      done()
    },

    /**
     * 获取 大型机械管理 数据
     * */
    async getDataInfo() {
      const { success, data } = await this.$http({
        url: this.$api.getQueryMachineManageDetail,
        params: {
          id: this.id
        }
      })
      if (success) {
        const {
          Title,
          Count,
          InProgressCount,
          DangerCount,
          Item,
          Year,
          Quarter
        } = data
        this.projectCount = InProgressCount === null ? '' : InProgressCount
        this.mechanicalCount = Count === null ? '' : Count
        this.title = Title === null ? '' : Title
        this.year = Year?Year.toString():new Date().getFullYear().toString()
        this.quarter = Quarter?Quarter.toString():''
        this.dangerCount = DangerCount === null ? '' : DangerCount
        this.mechanicalList = Item || []
      }
    },
    /**
     *  取消 事件
     * */
    cancelClick() {
      this.$emit('closeDialog')
      this.clearClick()
    },
    /**
     *  清空 事件
     * */
    clearClick() {
      this.title = ''
      this.year = new Date().getFullYear().toString()
      this.quarter = ''
      this.projectCount = ''
      this.mechanicalCount = ''
      this.dangerCount = ''
      this.mechanicalList = []
      this.dialogVisible = false
    },
    // 创建或者编辑
    async onCreateClick() {
      if (this.title === '') {
        this.$message.error('请输入标题')
        return
      }
      if (this.year === '') {
        this.$message.error('请输入年份')
        return
      }
      if (this.quarter === '') {
        this.$message.error('请输入季度')
        return
      }
      const dataParam = {
        ID: this.id,
        Title: this.title,
        Year:this.year,
        Quarter:this.quarter,
        Count: this.mechanicalCount || 0,
        InProgressCount: this.projectCount || 0,
        DangerCount: this.dangerCount || 0,
        Item: this.mechanicalList
      }
      const url = this.status === 'create' ? this.$api.goAddMachineManage : this.$api.goUpdateMachineManage
      const { success } = await this.$http({
        url,
        method: 'post',
        data: dataParam
      })
      const textTip = this.status === 'create' ? '创建' : '编辑'
      if (success) {
        this.$message.success(`${textTip}成功`)
        this.dialogVisible = false
        this.$emit('refresh')
      }
      else {
        this.$message.error(`${textTip}失败`)
      }
    },
    // 新增行
    createRow() {
      const obj = {
        Name: '',
        Count: '',
        DangerCount: ''
      }
      this.mechanicalList.push(obj)
    },
    // 删除行
    deleteRow(index) {
      this.mechanicalList.splice(index, 1)
    },
    indexMethod(index) {
      return index + 1
    },
    //年份改变
    yearChange(time) {
      let year = new Date(time).getFullYear().toString()
      this.year = year;
    },
  }
}
</script>
<style
  lang="scss"
  scoped
>
$tabsDefaultColor: #FFFFFF;
$tabsActiveColor: #42DEFD;
$borderColor: rgba(3, 175, 252, 0.66);

.tab {
  height: 700px;

  &__content {
    overflow: auto;
    height: calc(100% - 74px);
  }

  &__footer {
    height: 59px;
    margin: 1em -2.4em 0;
    padding: 0 2em;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    background-color: #FFFFFF;
  }
}

//dialog
::v-deep.el-dialog__wrapper {
  background-color: rgba(0, 0, 0, .5);
}

::v-deep .el-dialog__title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.85);
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.02) !important;
}

::v-deep .el-dialog__body {
  padding: 30px 34px 0;
}

//dialog
::v-deep .el-tabs {
  margin-bottom: 1em;

  &__header {
    margin: 0;
  }

  &__nav-wrap::after {
    background-color: transparent;
  }

  &__item {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    height: 31px;
    padding: 0 1em;
    color: $tabsDefaultColor;

    &.is-active {
      color: $tabsActiveColor;
    }
  }

  &__active-bar {
    color: $tabsActiveColor;
  }

  &__content {
    display: none;
  }
}

::v-deep .el-upload-list__item {
  max-width: 320px;
}

.mechanical-table-wrapper ::v-deep .el-input__inner {
  height: 32px;
  line-height: 32px;
  border-radius: 0;
}

.mechanical-table-wrapper ::v-deep .el-input {
  border-radius: 0;
}

.table-title {
  width: 100%;
  margin-top: 1em;
  font-weight: 700;
}

.create-btn-box {
  width: 48px;
}
::v-deep .el-date-editor.el-input{
  width: 100%;
}
::v-deep .el-select{
  width:100%
}
</style>
