<template>
  <el-dialog
    :append-to-body="true"
    :before-close="onBeforeClose"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :modal="false"
    :visible.sync="dialogVisible"
    custom-class="common-dialog"
    title="导入"
    top="16vh"
    width="500px"
  >
    <div class="tab">
      <div class="tab__content">
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">年份</span>
          <el-date-picker
            v-model="selectDate"
            size="small"
            class="flex1"
            placeholder="选择年份"
            type="year"
          ></el-date-picker>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">类型</span>
          <el-select
            v-model="yearTypeName"
            class="flex1"
            placeholder="选择导入类型"
            size="small"
          >
            <el-option
              v-for="({ label, value }, yearIndex) in yearTypeOptions"
              :key="`${label}-${value}-${yearIndex}`"
              :label="label"
              :value="value"
            ></el-option>
          </el-select>
        </div>
        <div class="flex justify-start">
          <span class="dialog-label">附件</span>
          <el-upload
            ref="upload"
            :auto-upload="false"
            :before-upload="beforeAvatarUpload"
            :http-request="uploadFile"
            :limit="1"
            action=""
          >
            <div class="flex justify-start">
              <GreenButton
                slot="trigger"
                class="button__default"
                text="附件"
              ></GreenButton>
            </div>
          </el-upload>
        </div>
      </div>
      <div class="tab__footer flex row justify-end items-center">
        <GreenButton
          :options="{
             borderColor: 'rgba(26,89,184,0.33)',
             borderRadius: '5px',
             color: '#1A59B8 !important'
          }"
          class="button__cancel button__gap"
          text="取消"
          @click="cancelExportClick"
        ></GreenButton>
        <GreenButton
          :loading="exportLoading"
          class="button__default"
          loading-text="处理中"
          text="确定"
          @click="onExportClick"
        ></GreenButton>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'
//import base from 'components/work-order-form/mixins/base.js'

export default {
  name: 'ImportCreditEvaluation',
  mixins: [],
  components: {
    GreenButton
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false,
      exportLoading: false,
      selectDate: '',//选择的日期
      yearTypeName: '上半年',
      yearTypeOptions: [
        { label: '上半年', value: '上半年' },
        { label: '下半年', value: '下半年' }
      ]
    }
  },
  computed: {},
  watch: {
    visible(visible) {
      if (visible) {
        this.dialogVisible = true
      }
    }
  },
  methods: {
    onBeforeClose(done) {
      this.cancelExportClick()
      done()
    },
    /**
     * 上传 事件
     * */
    async onExportClick() {
      if (this.selectDate === '') {
        this.$message.error('请选择日期')
        return
      }
      if (this.yearTypeName === '') {
        this.$message.error('请选择类型')
        return
      }
      if (this.$refs.upload.uploadFiles.length === 0) {
        this.$message.error('请上传附件')
      }
      else {
        this.$refs.upload.submit()
      }
    },
    /**
     * 取消上传 事件
     * */
    cancelExportClick() {
      this.$refs.upload.clearFiles()
      this.$emit('closeDialog')
      this.selectDate = ''
      this.yearTypeName = '上半年'
      this.dialogVisible = false
    },
    /**
     * 上传附件之前
     */
    beforeAvatarUpload(file) {
      const testing = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testing === 'xls'
      const extension2 = testing === 'xlsx'
      if (!extension && !extension2) {
        this.$message.error('只能上传xls与xlsx文件')
        return false
      }
    },
    /**
     * 上传附件
     */
    async uploadFile(files) {
      this.exportLoading = true
      const { file } = files
      const currentYear = this.$dateFormat(this.selectDate, 'yyyy')
      const formData = new FormData()
      formData.append('Year', currentYear)
      formData.append('Type', this.yearTypeName)
      formData.append('files', file)
      const { success } = await this.$http({
        url: this.$api.goImportCreditEvaluation,
        method: 'POST',
        data: formData
      })
      if (success) {
        this.$refs.upload.clearFiles()
        this.$emit('refresh')
        this.dialogVisible = false
        this.selectDate = ''
        this.yearTypeName = '上半年'
        this.$message.success('导入成功')
      }
      else {
        this.$refs.upload.clearFiles()
      }
      this.exportLoading = false
    }
  }
}
</script>
<style
  lang="scss"
  scoped
>
$tabsDefaultColor: #FFFFFF;
$tabsActiveColor: #42DEFD;
$borderColor: rgba(3, 175, 252, 0.66);

.tab {
  height: 250px;

  &__content {
    height: calc(100% - 31px - 3em - 4px);
  }

  &__footer {
    height: 59px;
    margin: 1em -2.4em 0;
    padding: 0 2em;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    background-color: #FFFFFF;
  }
}

//dialog
::v-deep.el-dialog__wrapper {
  background-color: rgba(0, 0, 0, .5);
}

::v-deep .el-dialog__title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.85);
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.02) !important;
}

::v-deep .el-dialog__body {
  padding: 30px 34px 0;
}

//dialog
::v-deep .el-tabs {
  margin-bottom: 1em;

  &__header {
    margin: 0;
  }

  &__nav-wrap::after {
    background-color: transparent;
  }

  &__item {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    height: 31px;
    padding: 0 1em;
    color: $tabsDefaultColor;

    &.is-active {
      color: $tabsActiveColor;
    }
  }

  &__active-bar {
    color: $tabsActiveColor;
  }

  &__content {
    display: none;
  }
}

::v-deep .el-upload-list__item {
  max-width: 320px;
}
</style>
