<template>
  <el-dialog
    :append-to-body="true"
    :before-close="onBeforeClose"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :modal="false"
    :title="title"
    :visible.sync="dialogVisible"
    custom-class="common-dialog"
    top="10vh"
    width="500px"
  >
    <div class="tab">
      <div class="tab__content">
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">项目编号</span>
          <el-input
            v-model="projectCode"
            class="flex1"
            placeholder="请输入项目编号"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">项目名称</span>
          <el-input
            v-model="projectName"
            class="flex1"
            placeholder="请输入项目名称"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">实际开工时间</span>
          <el-date-picker
            v-model="selectStartDate"
            class="flex1"
            placeholder="选择实际开工时间"
            size="small"
            type="date"
          ></el-date-picker>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">合同延期后竣工时间</span>
          <el-date-picker
            v-model="selectEndDate"
            class="flex1"
            placeholder="选择合同延期后竣工时间"
            size="small"
            type="date"
          ></el-date-picker>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">填报日期</span>
          <el-date-picker
            v-model="selectCreateDate"
            class="flex1"
            placeholder="选择填报日期"
            size="small"
            type="date"
          ></el-date-picker>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">当月25日</span>
          <el-date-picker
            v-model="currentMonth"
            class="flex1"
            placeholder="选择当月25日"
            size="small"
            type="date"
          ></el-date-picker>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">合同日期天数</span>
          <el-input
            v-model="ContractDate"
            class="flex1"
            placeholder="请输入合同日期天数"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">实际已用天数</span>
          <el-input
            v-model="ActualUse"
            class="flex1"
            onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode)))"
            placeholder="请输入实际已用天数"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">滞后天数</span>
          <el-input
            v-model="DelayDay"
            class="flex1"
            onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode)))"
            placeholder="请输入滞后天数"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between label-box__gap">
          <span class="dialog-label">工程关键线路</span>
          <el-input
            v-model="Progress"
            :autosize="{ minRows:  5}"
            class="flex1"
            placeholder="请输入工程关键线路"
            type="textarea"
          ></el-input>
        </div>
        <div class="flex row justify-between label-box__gap">
          <span class="dialog-label">滞后原因</span>
          <el-input
            v-model="Reason"
            :autosize="{ minRows:  5}"
            class="flex1"
            placeholder="请输入滞后原因"
            type="textarea"
          ></el-input>
        </div>
      </div>
      <div class="tab__footer flex row justify-end items-center">
        <GreenButton
          :options="{
                    borderColor: 'rgba(26,89,184,0.33)',
                    borderRadius: '5px',
                    color: '#1A59B8 !important'
          }"
          class="button__cancel button__gap"
          text="取消"
          @click="cancelClick"
        ></GreenButton>
        <GreenButton
          :loading="createLoading"
          class="button__default"
          loading-text="处理中"
          text="确定"
          @click="onCreateClick"
        ></GreenButton>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'

export default {
  name: 'CreateDialog',
  mixins: [],
  components: {
    GreenButton
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      dialogVisible: false,
      exportLoading: false,
      projectCode: '',
      projectName: '',
      selectStartDate: '',
      selectEndDate: '',
      selectCreateDate: '',
      ContractDate: '',
      ActualUse: '',
      currentMonth: '',
      DelayDay: '',
      Progress: '',
      Reason: '',
      createLoading: false
    }
  },
  computed: {
    title() {
      return this.isEdit ? '进度履约编辑' : '进度履约填报'
    }
  },
  watch: {
    visible(visible) {
      if (visible) {
        this.dialogVisible = true
        this.projectCode = ''
        this.projectName = ''
        this.DelayDay = ''
        this.selectStartDate = ''
        this.selectEndDate = ''
        this.ContractDate = ''
        this.ActualUse = ''
        this.currentMonth = ''
        this.Progress = ''
        this.Reason = ''
        this.selectCreateDate = ''
        if (this.isEdit) {
          this.getProgressInfo()
        }
      }
    }
  },
  methods: {
    onBeforeClose(done) {
      this.$emit('closeDialog')
      this.clearClick()
      done()
    },

    /**
     * 获取进度履约数据
     * */
    async getProgressInfo() {
      const { success, data } = await this.$http({
        url: this.$api.getQueryDetailByID,
        params: {
          id: this.id
        }
      })
      if (success) {
        const {
          ProjectCode,
          ProjectName,
          DelayDayCount,
          ActualStartDate,
          DelayCompleteDate,
          StatisticsDate,
          ContractDayCount,
          MonthDate,
          ActualUseCount,
          Progress,
          Reason
        } = data
        this.projectCode = ProjectCode === null ? '' : ProjectCode
        this.projectName = ProjectName === null ? '' : ProjectName
        this.DelayDay = DelayDayCount === null ? '' : DelayDayCount
        this.selectStartDate = ActualStartDate.split('T')[0]
        this.selectEndDate = DelayCompleteDate.split('T')[0]
        this.selectCreateDate = StatisticsDate.split('T')[0]
        this.ContractDate = ContractDayCount === null ? '' : ContractDayCount
        this.ActualUse = ActualUseCount === null ? '' : ActualUseCount
        this.currentMonth = MonthDate.split('T')[0]
        this.Progress = Progress === null ? '' : Progress
        this.Reason = Reason === null ? '' : Reason
      }
    },
    /**
     * 创建进度履约
     * */
    async onCreateClick() {
      if (this.projectCode === '') {
        this.$message.error('请输入项目编号')
        return
      }
      if (this.projectName === '') {
        this.$message.error('请输入项目名称')
        return
      }
      if (this.selectStartDate === '') {
        this.$message.error('请选择实际开工时间')
        return
      }
      if (this.selectEndDate === '') {
        this.$message.error('请选择合同延期后竣工时间')
        return
      }
      if (this.selectCreateDate === '') {
        this.$message.error('请选择填报日期')
        return
      }
      if (this.currentMonth === '') {
        this.$message.error('请选择当月25日')
        return
      }
      if (this.ContractDate === '') {
        this.$message.error('请输入合同日期天数')
        return
      }
      if (this.ActualUse === '') {
        this.$message.error('请输入实际已用天数')
        return
      }
      if (this.DelayDay === '') {
        this.$message.error('请输入滞后天数')
        return
      }
      if (this.Progress === '') {
        this.$message.error('请输入工程关键线路')
        return
      }
      if (this.Reason === '') {
        this.$message.error('请输入滞后原因')
        return
      }
      if (this.isEdit) {
        await this.updateChange()
      }
      else {
        await this.createChange()
      }
    },
    /**
     * 进度履约 事件
     * */
    async createChange() {
      this.createLoading = true
      const { success } = await this.$http({
        url: this.$api.goAddProgressPerformance,
        method: 'POST',
        data: {
          ProjectCode: this.projectCode,
          ProjectName: this.projectName,
          ActualStartDate: this.$dateFormat(this.selectStartDate, 'yyyy-MM-dd'),
          DelayCompleteDate: this.$dateFormat(this.selectEndDate, 'yyyy-MM-dd'),
          StatisticsDate: this.$dateFormat(this.selectCreateDate, 'yyyy-MM-dd'),//填报日期
          MonthDate: this.$dateFormat(this.currentMonth, 'yyyy-MM-dd'),
          ContractDayCount: this.ContractDate,
          ActualUseCount: this.ActualUse,
          DelayDayCount: this.DelayDay,
          Progress: this.Progress,
          Reason: this.Reason
        }
      })
      if (success) {
        this.$emit('refresh')
        this.clearClick()
        this.$message.success('填报成功')
      }
      this.createLoading = false
    },
    /**
     * 更新进度履约
     * */
    async updateChange() {
      this.createLoading = true
      const { success } = await this.$http({
        url: this.$api.goUpdateProgressPerformance,
        method: 'POST',
        data: {
          ID: this.id,
          ProjectCode: this.projectCode,
          ProjectName: this.projectName,
          ActualStartDate: this.$dateFormat(this.selectStartDate, 'yyyy-MM-dd'),
          DelayCompleteDate: this.$dateFormat(this.selectEndDate, 'yyyy-MM-dd'),
          StatisticsDate: this.$dateFormat(this.selectCreateDate, 'yyyy-MM-dd'),//填报日期
          MonthDate: this.$dateFormat(this.currentMonth, 'yyyy-MM-dd'),
          ContractDayCount: this.ContractDate,
          ActualUseCount: this.ActualUse,
          DelayDayCount: this.DelayDay,
          Progress: this.Progress,
          Reason: this.Reason
        }
      })
      if (success) {
        this.$emit('refresh')
        this.clearClick()
        this.$message.success('编辑成功')
      }
      this.createLoading = false
    },
    /**
     *  取消 事件
     * */
    cancelClick() {
      this.$emit('closeDialog')
      this.clearClick()
    },
    /**
     *  清空 事件
     * */
    clearClick() {
      this.projectCode = ''
      this.projectName = ''
      this.DelayDay = ''
      this.selectStartDate = ''
      this.selectEndDate = ''
      this.ContractDate = ''
      this.ActualUse = ''
      this.currentMonth = ''
      this.Progress = ''
      this.Reason = ''
      this.selectCreateDate = ''
      this.dialogVisible = false
    }
  }
}
</script>
<style
  lang="scss"
  scoped
>
$tabsDefaultColor: #FFFFFF;
$tabsActiveColor: #42DEFD;
$borderColor: rgba(3, 175, 252, 0.66);

.tab {
  height: 700px;

  &__content {
    overflow: auto;
    height: calc(100% - 74px);
  }

  &__footer {
    height: 59px;
    margin: 1em -2.4em 0;
    padding: 0 2em;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    background-color: #FFFFFF;
  }
}

//dialog
::v-deep.el-dialog__wrapper {
  background-color: rgba(0, 0, 0, .5);
}

::v-deep .el-dialog__title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.85);
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.02) !important;
}

::v-deep .el-dialog__body {
  padding: 30px 34px 0;
}

//dialog
::v-deep .el-tabs {
  margin-bottom: 1em;

  &__header {
    margin: 0;
  }

  &__nav-wrap::after {
    background-color: transparent;
  }

  &__item {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    height: 31px;
    padding: 0 1em;
    color: $tabsDefaultColor;

    &.is-active {
      color: $tabsActiveColor;
    }
  }

  &__active-bar {
    color: $tabsActiveColor;
  }

  &__content {
    display: none;
  }
}
</style>
