<template>
  <el-dialog
    :append-to-body="true"
    :before-close="onBeforeClose"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :modal="false"
    :visible.sync="dialogVisible"
    custom-class="common-dialog"
    title="上传"
    top="16vh"
    width="500px"
  >
    <div class="tab">
      <div class="tab__content">
        <div
          v-if="type !== 'standardization'"
          class="flex row justify-between items-center label-box__gap"
        >
          <span class="dialog-label">类型</span>
          <el-select
            v-model="categoryName"
            class="flex1"
            placeholder="请选择类型"
            size="small"
          >
            <el-option
              v-for="({ label, value },categoryIndex) in categoryNameOptions"
              :key="`${label}-${value}-${categoryIndex}`"
              :label="label"
              :value="value"
            ></el-option>
          </el-select>
        </div>
        <div class="flex justify-start">
          <span class="dialog-label">附件</span>
          <el-upload
            ref="upload"
            :auto-upload="false"
            :before-upload="beforeAvatarUpload"
            :http-request="uploadFile"
            :multiple="true"
            action=""
          >
            <div class="flex justify-start">
              <GreenButton
                slot="trigger"
                class="button__default"
                text="附件"
              ></GreenButton>
            </div>
          </el-upload>
        </div>
      </div>
      <div class="tab__footer flex row justify-end items-center">
        <GreenButton
          :options="{
             borderColor: 'rgba(26,89,184,0.33)',
             borderRadius: '5px',
             color: '#1A59B8 !important'
          }"
          class="button__cancel button__gap"
          text="取消"
          @click="cancelExportClick"
        ></GreenButton>
        <GreenButton
          :loading="exportLoading"
          class="button__default"
          loading-text="处理中"
          text="确定"
          @click="onExportClick"
        ></GreenButton>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateSubcontractingManagement',
  mixins: [],
  components: {
    GreenButton
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    //    施工现场标准化相关文件 和分包管理
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: false,
      exportLoading: false,
      categoryName: '合格分包名册',
      categoryNameOptions: [
        { label: '合格分包名册', value: '合格分包名册' },
        { label: '分包考核评价等级', value: '分包考核评价等级' },
        { label: '拟合作产值任务量', value: '拟合作产值任务量' },
        { label: '分包约谈与通报', value: '分包约谈与通报' },
      ],
      fileData: {}
    }
  },
  computed: {},
  watch: {
    visible(visible) {
      if (visible) {
        this.dialogVisible = true
      }
    }
  },
  methods: {
    ...mapGetters('user', {
      USER_INFO: 'USER_INFO',
    }),
    onBeforeClose(done) {
      this.cancelExportClick()
      done()
    },
    /**
     * 上传 事件
     * */
    async onExportClick() {
      if (this.categoryName === '') {
        this.$message.error('请选择类型')
        return
      }
      if (this.$refs.upload.uploadFiles.length === 0) {
        this.$message.error('请上传附件')
      }
      else {
        let tempDta = this.fileData
        this.fileData = new FormData()
        await this.$refs.upload.submit()
        await this.uploadChange()
      }
    },
    /**
     * 取消上传 事件
     * */
    cancelExportClick() {
      this.$refs.upload.clearFiles()
      this.$emit('closeDialog')
      this.categoryName = '合格分包名册'
      this.dialogVisible = false
    },
    /**
     * 上传附件之前
     */
    beforeAvatarUpload() {
      //    beforeAvatarUpload(file) {
      //      const testing = file.name.substring(file.name.lastIndexOf('.') + 1)
      //      const extension = testing === 'xls'
      //      const extension2 = testing === 'xlsx'
      //      if (!extension && !extension2) {
      //        this.$message.error('只能上传xls与xlsx文件')
      //        return false
      //      }
    },
    /**
     * 上传附件
     */
    async uploadFile(files) {
      const { file } = files
      this.fileData.append('files', file)
    },
    /**
     * 上传 事件
     * */
    async uploadChange() {
      this.exportLoading = true
      const { ID } = this.USER_INFO()
      this.fileData.append('UserID', ID)
      if (this.createDialogVisible !== 'standardization') {
        this.fileData.append('Type', this.categoryName)
      }
      const url = this.type === 'standardization' ? this.$api.getUploadConstructionStandardFile
        : this.$api.goUploadFile
      const { success } = await this.$http({
        url,
        method: 'POST',
        data: this.fileData
      })
      if (success) {
        this.$refs.upload.clearFiles()
        this.$emit('refresh')
        this.dialogVisible = false
        this.categoryName = '合格分包名册'
        this.$message.success('导入成功')
      }
      else {
        this.$refs.upload.clearFiles()
      }
      this.exportLoading = false
    }
  }
}
</script>
<style
  lang="scss"
  scoped
>
$tabsDefaultColor: #FFFFFF;
$tabsActiveColor: #42DEFD;
$borderColor: rgba(3, 175, 252, 0.66);

.tab {
  height: 250px;

  &__content {
    overflow: auto;
    height: calc(100% - 74px);
    //height: calc(100% - 31px - 3em - 4px);
  }

  &__footer {
    height: 59px;
    margin: 1em -2.4em 0;
    padding: 0 2em;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    background-color: #FFFFFF;
  }
}

//dialog
::v-deep.el-dialog__wrapper {
  background-color: rgba(0, 0, 0, .5);
}

::v-deep .el-dialog__title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.85);
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.02) !important;
}

::v-deep .el-dialog__body {
  padding: 30px 34px 0;
}

//dialog
::v-deep .el-tabs {
  margin-bottom: 1em;

  &__header {
    margin: 0;
  }

  &__nav-wrap::after {
    background-color: transparent;
  }

  &__item {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    height: 31px;
    padding: 0 1em;
    color: $tabsDefaultColor;

    &.is-active {
      color: $tabsActiveColor;
    }
  }

  &__active-bar {
    color: $tabsActiveColor;
  }

  &__content {
    display: none;
  }
}

::v-deep .el-upload-list__item {
  max-width: 320px;
}
</style>
