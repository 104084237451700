<template>
  <div id="progressPerformance">
    <div class="flex row top-bar_wrapper" >
      <div
        class="progress-name pointer"
        :class="{'progress-name-active':currentTab === 'year'}"
        @click="changeTab('year')"
      >年度产值
      </div>
      <div
        class="progress-name pointer"
        :class="{'progress-name-active':currentTab === 'month'}"
        @click="changeTab('month')"
      >月度产值
      </div>
    </div>
    
    <div class="com-wrapper" v-if="currentTab === 'year'">
      <yearOutputTotal></yearOutputTotal>
    </div>
    <div class="com-wrapper" v-if="currentTab === 'month'">
      <monthOutputTotal></monthOutputTotal>
    </div>
    
  </div>
</template>
<script>
import yearOutputTotal from './yearOutputTotal';
import monthOutputTotal from './monthOutputTotal'
let _this = void 0

export default {
  name: 'outputManagement',
  mixins: [],
  components: {
    yearOutputTotal,
    monthOutputTotal
  },
  props: {},
  data() {
    return {
      currentTab: 'year',
    }
  },
  computed: {
    //...mapState('', {}),
  },
  watch: {},
  directives: {},
  created() {
    _this = this
  },
  mounted() {
  },
  destroy() {
  },
  methods: {
    /**
     * 切换tab
     * */
    changeTab(type) {
      this.currentTab = type
    },
  }
}
</script>
<style
  lang="scss"
  scoped
>
#progressPerformance {
  height: 100%;
}
.table-box {
  padding: 0 20px;
}

.wot__footer {
  padding: 1em 0.1em;
  background: #FFFFFF;
}

.button__import {
  margin-right: 0.5em !important;
  border: 1px solid #005BAC !important;
  border-radius: 4px !important;
  background: #005BAC !important;
}

.button__delete {
  font-weight: 400 !important;
  margin-right: 0.5em !important;
  border: 1px solid rgba(26, 89, 184, 0.33) !important;
  border-radius: 4px !important;
  background: rgba(26, 89, 184, 0.05) !important;
}

::v-deep .el-link.el-link--primary {
  color: #005BAC;
}
.com-wrapper{
  height: 100%;
}
</style>
<style lang="scss"></style>
