<template>
  <el-dialog
    :append-to-body="true"
    :before-close="onBeforeClose"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :modal="false"
    title="项目管理"
    :visible.sync="dialogVisible"
    custom-class="common-dialog"
    top="16vh"
    width="800px"
  >
    <div class="tab">
      <div class="flex justify-end flex1 search-box">
        <div class="flex justify-end items-center">
          <el-input
            v-model="searchKey"
            class="button__gap"
            placeholder="请输入关键字"
            size="small"
            @keyup.enter.native="searchChange"
          ></el-input>
          <GreenButton
            class="button__default"
            text="查询"
            @click="searchChange"
          ></GreenButton>
        </div>
      </div>
      <div class="tab__content">
        <el-table
          :data="dataList"
          :header-cell-style="{background: 'rgba(0,0,0,0.02)',borderTop:'1px solid rgba(0,0,0,0.1)'}"
          :loading="loading"
          height="calc(100%)"
        >
          <el-table-column
            label="项目名称"
            prop="Name"
          ></el-table-column>
          <el-table-column
            label="操作"
            width="70"
          >
            <template #default="scope">
              <el-switch
                v-model="scope.row.Checked"
                @change="checkChange($event,scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="tab__footer flex row justify-end items-center">
        <el-pagination
          :current-page.sync="pageIndex"
          :page-size.sync="pageSize"
          :total="allTotal"
          layout="total, prev, pager, next"
          @current-change="onPageIndexChange"
        ></el-pagination>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'

export default {
  name: 'ManagementProjectDialog',
  mixins: [],
  components: {
    GreenButton
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      dataList: [],
      isEdit: false,
      pageIndex: 1,
      pageSize: 10,
      allTotal: 0,
      searchKey: ''
    }
  },
  computed: {},
  watch: {
    visible(visible) {
      if (visible) {
        this.dialogVisible = true
        this.isEdit = false
        this.getDataInfo()
      }
    }
  },
  methods: {
    onBeforeClose(done) {
      this.dialogVisible = false
      if (this.isEdit) {
        this.$emit('refresh')
      }
      else {
        this.$emit('closeDialog')
      }
      this.isEdit = false
      //      this.clearClick()
      done()
    },
    /**
     * 获取 项目管理 数据
     * */
    async getDataInfo() {
      this.loading = true
      const { success, data } = await this.$http({
        url: this.$api.getPageQueryProjectList,
        params: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          clusterID: this.id,
          keyword: this.searchKey
        }
      })
      if (success) {
        const { rows, total } = data
        if (data.hasOwnProperty('rows')) {
          this.dataList = [...rows]
          if (data.hasOwnProperty('total')) {
            this.allTotal = total
          }
        }
      }
      this.loading = false
    },
    /**
     * 项目管理 确定
     * */
    checkChange(value, item) {
      const { ID } = item
      this.isEdit = true
      if (value) {
        this.bindChange(ID)
      }
      else {
        this.unBindChange(ID)
      }
    },
    /**
     * 绑定项目 项目管理
     * */
    async bindChange(ID) {
      const { success, data } = await this.$http({
        url: this.$api.goBindProjectAndCluster,
        params: {
          projectID: ID,
          clusterID: this.id,
        }
      })
      if (success) {
        this.$message.success('绑定成功')
      }
    },
    /**
     * 更新 项目管理
     * */
    async unBindChange(ID) {
      const { success, data } = await this.$http({
        url: this.$api.goUnBindProjectAndCluster,
        params: {
          projectID: ID,
          clusterID: this.id,
        }
      })
      if (success) {
        this.$message.success('解绑成功')
      }
    },
    /**
     * 切换分页
     * */
    onPageIndexChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getDataInfo()
    },
    searchChange() {
      this.pageIndex = 1
      this.getDataInfo()
    }
  }
}
</script>
<style
  lang="scss"
  scoped
>
$tabsDefaultColor: #FFFFFF;
$tabsActiveColor: #42DEFD;
$borderColor: rgba(3, 175, 252, 0.66);

.tab {
  height: 600px;

  &__content {
    overflow: auto;
    height: calc(100% - 74px);
  }

  &__footer {
    height: 59px;
    margin: 1em -2.4em 0;
    padding: 0 2em;
    background-color: #FFFFFF;
  }
}

//dialog
::v-deep.el-dialog__wrapper {
  background-color: rgba(0, 0, 0, .5);
}

::v-deep .el-dialog__title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.85);
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.02) !important;
}

::v-deep .el-dialog__body {
  padding: 30px 34px 0;
}

//dialog
::v-deep .el-tabs {
  margin-bottom: 1em;

  &__header {
    margin: 0;
  }

  &__nav-wrap::after {
    background-color: transparent;
  }

  &__item {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    height: 31px;
    padding: 0 1em;
    color: $tabsDefaultColor;

    &.is-active {
      color: $tabsActiveColor;
    }
  }

  &__active-bar {
    color: $tabsActiveColor;
  }

  &__content {
    display: none;
  }
}

.search-box {
  margin: -1em 0 1em;
}
</style>
