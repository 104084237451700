<template>
  <div id="reduceEmissions">
    <div class="flex row top-bar_wrapper" >
      <div
        class="progress-name pointer"
        :class="{'progress-name-active ':selectBar === item}"
        v-for="(item,index) in barList" :key="index"
        @click="selectBar = item"
      >{{item}}
      </div>
    </div>

    <year-plan v-if="selectBar === '年度指标' "></year-plan>

    <quarter-work-report v-if="selectBar === '季度节能环保工作' "></quarter-work-report>

    <process-check-result v-if="selectBar === '过程管控检查结果' "></process-check-result>

  </div>
</template>
<script>
import yearPlan from './yearPlan'
import quarterWorkReport from './quarterWorkReport';
import processCheckResult from './processCheckResult'

let _this = void 0
export default {
  name: 'reduceEmissions',
  mixins: [],
  components: {
    yearPlan,
    quarterWorkReport,
    processCheckResult
  },
  props: {},
  data() {
    return {
      barList:['年度指标','季度节能环保工作','过程管控检查结果'],
      selectBar:'年度指标',
    }
  },
  computed: {
    //...mapState('', {}),
  },
  watch: {},
  created() {
    _this = this
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style
  lang="scss"
  scoped
>
#reduceEmissions {
  height: 100%;
}
::v-deep .el-link.el-link--primary {
  color: #005BAC;
}
</style>
<style lang="scss"></style>
