<template>
  <el-dialog
    :append-to-body="true"
    :before-close="onBeforeClose"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :modal="false"
    :title="titleName"
    :visible.sync="dialogVisible"
    custom-class="common-dialog"
    top="10vh"
    width="620px"
  >
    <div class="tab">
      <div class="tab__content">
        <div
          v-for="(item,index) in checkList"
          :key="index"
          class="check-result-wrapper"
        >
          <div class="flex items-center justify-between">
            <div style="width: 94%">
              <div class="flex row justify-between items-center label-box__gap">
                <span class="dialog-label">{{ index + 1 }}.检查结果</span>
                <el-input
                  v-model="item.Description"
                  class="flex1"
                  placeholder="请输入检查结果"
                  size="small"
                ></el-input>
              </div>
              <div class="upload_box">
                <!--                <span class="dialog-label">上传检查照片</span>-->
                <el-upload
                  ref="upload"
                  :auto-upload="false"
                  :file-list="item.fileList"
                  :on-change="(file, fileList) => {handleChange(file, fileList, index)}"
                  :limit="9"
                  :on-remove=" (file, fileList) => {handleRemove(file, fileList, index)}"
                  action=""
                  list-type="picture-card"
                  :accept="acceptStr"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </div>
            </div>
            <i
              class="el-icon-remove-outline remove-row"
              @click="removeCheckResult(item,index)"
            ></i>
          </div>
        </div>
        <div class="flex justify-end add-btn">
          <GreenButton
            class="button__default"
            text="添加"
            @click="addCheckResult"
          ></GreenButton>
        </div>
      </div>
      <div class="tab__footer flex row justify-end items-center">
        <GreenButton
          :options="{
                    borderColor: 'rgba(26,89,184,0.33)',
                    borderRadius: '5px',
                    color: '#1A59B8 !important'
          }"
          class="button__cancel button__gap"
          text="取消"
          @click="cancelClick"
        ></GreenButton>
        <GreenButton
          :loading="createLoading"
          class="button__default"
          loading-text="处理中"
          text="确定"
          @click="onCreateClick"
        ></GreenButton>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'

export default {
  name: 'CreateCheckResultDialog',
  emits: {
    change: null
  },
  mixins: [],
  components: {
    GreenButton
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      dialogVisible: false,
      createLoading: false,
      checkList: [],
      linksList: [],
      // 限制文件类型
      acceptStr: '.jpg,.png,.svg,.jpeg'
    }
  },
  computed: {
    titleName() {
      return '检查结果填报'
    }
  },
  watch: {
    visible(visible) {
      if (visible) {
        this.dialogVisible = true
        this.getProcessControlCheckResultItems()
      }
    },
    //    id(val) {
    //      if (val) {
    //        this.getProcessControlCheckResultItems()
    //      }
    //    }
  },
  methods: {
    onBeforeClose(done) {
      this.$emit('closeDialog')
      this.clearClick()
      done()
    },
    // 添加检查项
    addCheckResult() {
      const obj = {
        ResultID: this.id,
        ID: 0,
        Description: '',
        fileList: []
      }
      this.checkList.push(obj)
    },
    /**
     * 获取检查项数据,整理数据，把图片列表回显在页面上,标记 isServer区分于已上传和未上传到服务器的图片
     * */
    async getProcessControlCheckResultItems() {
      this.checkList = []
      const { success, data } = await this.$http({
        url: this.$api.queryProcessControlCheckResultItems,
        params: {
          id: this.id
        }
      })
      if (success) {
        if (data.length > 0) {
          data.map(item => {
            let { LinkFiles, Description, ID } = item
            let itemObj = {
              Description,
              ID,
              ResultID: this.id,
              fileList: []
            }
            let imageList = []
            // 处理图片数组
            if (LinkFiles.length > 0) {
              LinkFiles.map(items => {
                const imageObj = {
                  ID: items.ID,
                  name: items.Name,
                  url: `${WEB_CONFIG.BASE_URL}${items['RemotePath']}`,
                  isServer: true
                }
                imageList.push(imageObj)
              })
              itemObj.fileList = [...imageList]
            }
            this.checkList.push(itemObj)
          })
        }
      }
    },
    /**
     * 点击确认按钮
     * */
    async onCreateClick() {
      let promiseList = []
      if (this.checkList.length > 0) {
        // 检查结果未填写
        const hasTempDescription= this.checkList.find( item=>{return !item.Description})
        // 检查结果照片未上传
        const hasTempFileList= this.checkList.find( item=>{return !item.fileList.length})
        if(hasTempDescription){
          this.$message.warning('请填写检查结果')
          return
        }
        if(hasTempFileList){
          this.$message.warning('请上传检查结果照片')
          return
        }
        this.createLoading = true;
        this.checkList.map(async item => {
          let p = new Promise(async (resolve, reject) => {
            await this.createOrUpdateCheckResult(item)
            resolve()
          })
          promiseList.push(p)
        })
        Promise.all(promiseList).then(res => {
          this.$emit('refresh')
          this.$message.success('填报成功')
          this.clearClick()
          this.createLoading = false
        }).catch((err) => {
          console.error(err)
        })
      }
    },
    /**
     * 循环创建、编辑检查项
     * */
    async createOrUpdateCheckResult(item) {
      let fd = new FormData()
      fd.append('ResultID', item.ResultID)
      fd.append('ID', item.ID)
      fd.append('Description', item.Description)
      // 对应多张图片文件,如果是已经存在服务的的图片，着不用上传
      item.fileList.map(fileItem => {
        const { isServer } = fileItem
        if (!isServer) {
          fd.append('files', fileItem.raw)
        }
      })
      const { success } = await this.$http({
        url: this.$api.addOrUpdateProcessControlCheckResultItem,
        method: 'POST',
        data: fd,
        noCancelToken: true,
      })
    },
    /**
     * 把文件放到数组里面，点击确定的时候，再做处理
     */
    async handleChange(file, fileList, index) {
      //console.log('file-change..', file, fileList, index)
      this.checkList[index]['fileList'].push(file)
    },
    /**
     * 移除文件。区分2种文件，已上传服务器的需要调用接口删除，未上传至服务器的需要在数组里面做删除对应的图片即可，这两种文件
     * 都需要经过在checkList数组上做处理，避免页面上重复显示和提交的时候再次出现的bug
     * */
    handleRemove(file, fileList, index) {
      const { ID, isServer, uid } = file
      // 删除checkList数组里面的图片
      let _fileList = this.checkList[index]['fileList']
      // 获取要删除文件的索引值
      let deleteIndex = null
      _fileList.map((item, index) => {
        if (item.uid === uid) {
          deleteIndex = index
        }
      })
      // 删除服务器上面的图片
      if (isServer) {
        this.deleteFileChange(ID, index, deleteIndex)
      }
      else {
        this.checkList[index]['fileList'].splice(deleteIndex, 1)
      }
    },
    /**
     * 删除检查项 如果保存过了的需要通过接口删除，如果没有保存过的，直接在checkList里面去掉即可，通过 ID来判断，如果有值，说明已经保存过
     * */
    async removeCheckResult(item, index) {
      const { ID } = item
      if (ID) {
        await this.deleteCheckResultItem(ID,index)
      }else{
        this.checkList.splice(index, 1)
      }

    },
    // 删除保存过的检查项
    deleteCheckResultItem(ID,index) {
      this.$confirm('是否确认删除', '确认删除检查项', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '删除中...'
            setTimeout(async () => {
              await this.commitDeleteCheckResultItem(ID,index)
              done()
              setTimeout(() => {
                instance.confirmButtonLoading = false
              }, 300)
            }, 300)
          }
          else {
            done()
          }
        }
      })
    },
    // 确认删除
    async commitDeleteCheckResultItem(ID,index) {
      const { success } = await this.$http({
        url: this.$api.deleteProcessControlCheckResultItem,
        params: {
          id: ID
        }
      })
      if (success) {
        this.$message.success('删除成功')
        this.checkList.splice(index, 1)
      }
    },
    /**
     * 删除服务器的文件
     * */
    async deleteFileChange(ID, index, deleteIndex) {
      const { success } = await this.$http({
        url: this.$api.goDeleteDocumentVersionsByVersionIDs,
        params: {
          ids: ID
        }
      })
      if (success) {
        this.$message.success('删除成功')
        this.checkList[index]['fileList'].splice(deleteIndex, 1)
      }
    },
    /**
     *  取消 事件
     * */
    cancelClick() {
      this.$emit('closeDialog')
      this.clearClick()
    },
    /**
     *  清空 事件
     * */
    clearClick() {
      this.dialogVisible = false
      this.checkList = []
    },
  }
}
</script>
<style
  lang="scss"
  scoped
>
$tabsDefaultColor: #FFFFFF;
$tabsActiveColor: #42DEFD;
$borderColor: rgba(3, 175, 252, 0.66);

.tab {
  height: 700px;
  overflow-y: hidden;

  &__content {
    overflow: auto;
    height: calc(100% - 74px);
  }

  &__footer {
    height: 59px;
    margin: 1em -2.4em 0;
    padding: 0 2em;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    background-color: #FFFFFF;
  }
}

//dialog
::v-deep.el-dialog__wrapper {
  background-color: rgba(0, 0, 0, .5);
}

::v-deep .el-dialog__title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.85);
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.02) !important;
}

::v-deep .el-dialog__body {
  padding: 30px 34px 0;
}

//dialog
::v-deep .el-tabs {
  margin-bottom: 1em;

  &__header {
    margin: 0;
  }

  &__nav-wrap::after {
    background-color: transparent;
  }

  &__item {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    height: 31px;
    padding: 0 1em;
    color: $tabsDefaultColor;

    &.is-active {
      color: $tabsActiveColor;
    }
  }

  &__active-bar {
    color: $tabsActiveColor;
  }

  &__content {
    display: none;
  }
}

::v-deep .el-upload-list__item-name {
  max-width: 180px;
}

.remove-row {
  cursor: pointer;
  margin: 0 10px;
  font-size: 24px;
  color: #CCCCCC;
}

.remove-row:hover {
  color: #FF4D4F;
}

.upload_box {
  margin: 10px 0
}

.check-result-wrapper {
  padding: 1.5em 0;
  border-bottom: 1px solid #BCBCBC;
}

.check-result-wrapper:last-child {
  border-bottom: none;
}

.tab__footer {
  margin: 1em -1.4em
}
::-webkit-scrollbar{
  background-color: #f5f5f5;
}
.add-btn{
  margin:1em 1em 5em 0
}
</style>
