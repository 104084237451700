<template>
  <el-dialog
    :append-to-body="true"
    :before-close="onBeforeClose"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :modal="false"
    :title="title"
    :visible.sync="dialogVisible"
    custom-class="common-dialog"
    top="10vh"
    width="620px"
  >
    <div class="tab">
      <div class="tab__content">
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">年份</span>
          <el-date-picker
            v-model="year"
            type="year"
            format="yyyy"
            @change="yearChange"
            placeholder="选择年份"
          ></el-date-picker>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">营业收入指标</span>
          <el-input
            v-model="totalVal"
            class="flex1"
            placeholder="请输入营业收入指标"
            size="small"
            type="number"
          ></el-input>
        </div>
<!--    电  -->
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">电-能耗量指标（吨标准煤）</span>
          <el-input
            v-model="electricTarget"
            class="flex1"
            placeholder="请输入电-能耗量指标"
            size="small"
            type="number"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">电-折算消耗量指标（万度）</span>
          <el-input
            v-model="electricConsume"
            class="flex1"
            placeholder="请输入电-折算消耗量指标"
            size="small"
            type="number"
          ></el-input>
        </div>
<!--    汽油   -->
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">汽油-能耗量指标（吨标准煤）</span>
          <el-input
            v-model="gasolineTarget"
            class="flex1"
            placeholder="请输入汽油-能耗量指标"
            size="small"
            type="number"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">汽油-折算消耗量指标（万度）</span>
          <el-input
            v-model="gasolineConsume"
            class="flex1"
            placeholder="请输入汽油-折算消耗量指标"
            size="small"
            type="number"
          ></el-input>
        </div>
<!--   液化石油气-->
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">液化石油气-能耗量指标（吨标准煤）</span>
          <el-input
            v-model="LPGTarget"
            class="flex1"
            placeholder="请输入液化石油气-能耗量指标"
            size="small"
            type="number"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">液化石油气-折算消耗量指标（万度）</span>
          <el-input
            v-model="LPGConsume"
            class="flex1"
            placeholder="请输入液化石油气-折算消耗量指标"
            size="small"
            type="number"
          ></el-input>
        </div>
      </div>
        <div class="tab__footer flex row justify-end items-center">
          <GreenButton
            :options="{
                    borderColor: 'rgba(26,89,184,0.33)',
                    borderRadius: '5px',
                    color: '#1A59B8 !important'
                     }"
            class="button__cancel button__gap"
            text="取消"
            @click="cancelClick"
          ></GreenButton>
          <GreenButton
            :loading="createLoading"
            class="button__default"
            loading-text="处理中"
            text="确定"
            @click="onCreateClick"
          ></GreenButton>
        </div>

    </div>
  </el-dialog>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'

export default {
  name: 'CreateYearPlanDialog',
  mixins: [],
  components: {
    GreenButton
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    yearProp: {
      type: String,
      default: new Date().getFullYear().toString()
    }
  },
  data() {
    return {
      dialogVisible: false,
      exportLoading: false,
      createLoading: false,
      //年度
      year:new Date().getFullYear().toString(),
      //	营业收入指标
      totalVal: '',
      //能耗量指标-电
      electricTarget:'',
      //折算消耗量指标-电
      electricConsume:'',
      //能耗量指标-汽油
      gasolineTarget:'',
      //折算消耗量指标-汽油
      gasolineConsume:'',
      //能耗量指标-液化石油气
      LPGTarget:'',
      //折算消耗量指标-液化石油气
      LPGConsume:'',
    }
  },
  computed: {
    title() {
      return this.isEdit ? '年度指标编辑' : '年度指标填报'
    }
  },
  watch: {
    visible(visible) {
      if (visible) {
        this.dialogVisible = true;
        this.year = new Date().getFullYear().toString();
        this.totalVal = '';
        this.electricTarget = '';
        this.electricConsume = '';
        this.gasolineTarget = '';
        this.gasolineConsume = '';
        this.LPGTarget = '';
        this.LPGConsume = '';
        if (this.isEdit) {
          this.getDataDetailInfo()
        }
      }
    }
  },
  methods: {
    onBeforeClose(done) {
      this.$emit('closeDialog')
      this.clearClick()
      done()
    },

    /**
     * 获取进度履约数据,表单赋值
     * */
    async getDataDetailInfo() {
      const { success, data } = await this.$http({
        url: this.$api.queryYearEnergyTarget,
        params: {
          year: this.yearProp
        }
      })
      if (success) {
        const {
          ElectricTarget,
          ElectricConsume,
          GasolineTarget,
          GasolineConsume,
          LPGTarget,
          LPGConsume,
          Year,
          Total
        } = data
        this.year = Year.toString();
        this.totalVal = Total || '';
        this.electricTarget = ElectricTarget || '';
        this.electricConsume = ElectricConsume || '';
        this.gasolineTarget = GasolineTarget || '';
        this.gasolineConsume = GasolineConsume || '';
        this.LPGTarget = LPGTarget  || '';
        this.LPGConsume = LPGConsume || '';
      }
    },
    /**
     * 点击确认按钮
     * */
    async onCreateClick() {
      if (this.totalVal === '') {
        this.$message.error('请输入营业收入指标')
        return
      }
      if (this.year === '') {
        this.$message.error('请输入年份')
        return
      }
      await this.createChange()
    },
    /**
     * 创建或者编辑
     * */
    async createChange() {
      this.createLoading = true
      const { success } = await this.$http({
        url: this.$api.addOrUpdateEnergyTarget,
        method: 'POST',
        data: {
          Year:this.year,
          ElectricTarget:this.electricTarget || 0,
          ElectricConsume:this.electricConsume || 0,
          GasolineTarget:this.gasolineTarget || 0,
          GasolineConsume:this.gasolineConsume || 0,
          LPGTarget:this.LPGTarget || 0,
          LPGConsume:this.LPGConsume || 0,
          Total:this.totalVal
        }
      })
      if (success) {
        this.$emit('refresh')
        this.clearClick()
        this.$message.success('填报成功')
      }
      this.createLoading = false
    },
    /**
     *  取消 事件
     * */
    cancelClick() {
      this.$emit('closeDialog')
      this.clearClick()
    },
    /**
     *  清空 事件
     * */
    clearClick() {
      this.year = new Date().getFullYear().toString();
      this.totalVal = '';
      this.electricTarget = '';
      this.electricConsume = '';
      this.gasolineTarget = '';
      this.gasolineConsume = '';
      this.LPGTarget = '';
      this.LPGConsume = '';
      this.dialogVisible = false
    },
    //年份改变
    yearChange(time) {
      let year = new Date(time).getFullYear().toString()
      this.year = year;
    },
  }
}
</script>
<style
  lang="scss"
  scoped
>
$tabsDefaultColor: #FFFFFF;
$tabsActiveColor: #42DEFD;
$borderColor: rgba(3, 175, 252, 0.66);

.tab {
  height: 520px;

  &__content {
    overflow: auto;
    height: calc(100% - 74px);
  }

  &__footer {
    height: 59px;
    margin: 1em -2.4em 0;
    padding: 0 2em;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    background-color: #FFFFFF;
  }
}

//dialog
::v-deep.el-dialog__wrapper {
  background-color: rgba(0, 0, 0, .5);
}

::v-deep .dialog-label{
  min-width: 260px;
}
::v-deep .el-dialog__title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.85);
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.02) !important;
}

::v-deep .el-dialog__body {
  padding: 30px 34px 0;
}
.label-box__gap{
  margin-bottom: 1.5em;
}
::v-deep .el-date-editor.el-input{
  width: 100%;
}

::v-deep .el-select{
  width:100%
}
//dialog
::v-deep .el-tabs {
  margin-bottom: 1em;

  &__header {
    margin: 0;
  }

  &__nav-wrap::after {
    background-color: transparent;
  }

  &__item {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    height: 31px;
    padding: 0 1em;
    color: $tabsDefaultColor;

    &.is-active {
      color: $tabsActiveColor;
    }
  }

  &__active-bar {
    color: $tabsActiveColor;
  }

  &__content {
    display: none;
  }
}

</style>
