<template>
  <div class="standardization-notification-wrapper">
    <div
      class="flex flex1 justify-between item-center button-title__box"
    >
      <div class="flex justify-start">
        <GreenButton
          class="button__default"
          text="导入"
          @click="createChange"
        ></GreenButton>
      </div>
      <div
        class="flex justify-end items-center"
      >
        <el-select
          v-model="selectStatus"
          placeholder="请选择状态"
          class="button__gap flex1"
          size="small"
          @change="searchChange"
        >
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <!--        <el-input-->
        <!--          v-model="searchKey"-->
        <!--          class="flex1 button__gap"-->
        <!--          placeholder="请输入关键字"-->
        <!--          size="small"-->
        <!--          @keyup.enter.native="searchKeyChange"-->
        <!--        ></el-input>-->
        <!--        <GreenButton-->
        <!--          class="button__default button__gap"-->
        <!--          text="查询"-->
        <!--          @click="searchKeyChange"-->
        <!--        ></GreenButton>-->
      </div>
    </div>
    <el-table
      :data="projectData"
      :header-cell-style="{background: 'rgba(0,0,0,0.02)',borderTop:'1px solid rgba(0,0,0,0.1)'}"
      :loading="projectLoading"
      class="table-box"
      height="calc(100% - 165px)"
    >
      <el-table-column
        label="序号"
        prop="$order"
        width="100"
      ></el-table-column>
      <el-table-column
        label="项目名称"
        prop="Name"
      ></el-table-column>
      <el-table-column
        label="项目状态"
        prop="Status"
        width="400"
      ></el-table-column>
    </el-table>
    <div
      class="table-box__pagination flex flex1 justify-end items-center"
    >
      <el-pagination
        :current-page.sync="pageIndex"
        :page-size.sync="pageSize"
        :total="allTotal"
        layout="total, prev, pager, next"
        @current-change="onPageIndexChange"
      ></el-pagination>
    </div>
    <ImportProjectStatus
      :visible="importDialogVisible"
      @refresh="onRefreshDialogClose"
      @closeDialog="onImportDialogClose"
    ></ImportProjectStatus>
  </div>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'
import ImportProjectStatus from '@/components/dialog/ImportProjectStatus'
import { mapMutations } from 'vuex'

export default {
  name: 'projectStatus',
  components: {
    GreenButton,
    ImportProjectStatus
  },
  filters: {},
  data() {
    return {
      pageIndex: 1,
      pageSize: 11,
      allTotal: 0,
      projectLoading: false,
      projectData: [],
      importDialogVisible: false,
      statusOptions: [],
      selectStatus: '',
      searchKey: ''
    }
  },
  async mounted() {
    await this.getProjectStatusInfo()
    await this.getProjectInfo()
  },
  destroy() {
  },
  methods: {
    ...mapMutations('management', ['setInnovationAndExposureObjectInfo']),
    /**
     * 获取 项目状态 数据
     * */
    async getProjectStatusInfo() {
      const { success, data } = await this.$http({
        url: this.$api.getQueryProjectStatus
      })
      if (success) {
        this.statusOptions = []
        data.map((item) => {
          this.statusOptions.push({ label: item, value: item })
        })
        this.statusOptions.unshift({ label: '全部状态', value: '' })
      }
    },
    /**
     * 获取 项目状态 数据
     * */
    async getProjectInfo() {
      this.projectLoading = true
      const { success, data } = await this.$http({
        url: this.$api.getPageQueryProjectStatus,
        params: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          keyword: this.searchKey,
          status: this.selectStatus
        }
      })
      if (success) {
        const { rows, total } = data
        if (data.hasOwnProperty('rows')) {
          this.projectData = [...rows]
          let arr = []
          arr = this.projectData
          for (let i = 0; i < arr.length; i++) {
            const item = arr[i]
            this.$set(item, '$order', i + 1)
          }
          if (data.hasOwnProperty('total')) {
            this.allTotal = total
          }
        }
      }
      this.projectLoading = false
    },
    /**
     * 切换分页
     * */
    onPageIndexChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getProjectInfo()
    },
    /**
     * 导入
     * */
    createChange() {
      this.importDialogVisible = true
    },
    /**
     * 导入 关闭dialog回调
     * */
    onImportDialogClose() {
      this.importDialogVisible = false
    },
    /**
     * 导入 刷新dialog回调
     * */
    onRefreshDialogClose() {
      this.importDialogVisible = false
      this.selectStatus = ''
      this.searchKey = ''
      this.pageIndex = 1
      this.getProjectInfo()
    },
    /**
     * 状态下拉 事件
     * */
    searchChange(value) {
      this.selectStatus = value
      this.pageIndex = 1
      this.getProjectInfo()
    },
    /**
     * 关键字 事件
     * */
    searchKeyChange() {
      this.pageIndex = 1
      this.getProjectInfo()
    }
  }
}
</script>
<style scoped>
.standardization-notification-wrapper {
  height: 100%
}
</style>
