<template>
  <el-dialog
    :append-to-body="true"
    :before-close="onBeforeClose"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :modal="false"
    :title="title"
    :visible.sync="dialogVisible"
    custom-class="common-dialog"
    top="16vh"
    width="500px"
  >
    <div class="tab">
      <div class="tab__content">
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">项目名称</span>
          <el-input
            v-model="projectName"
            class="flex1"
            placeholder="请输入项目名称"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">项目经理</span>
          <el-input
            v-model="projectManager"
            class="flex1"
            placeholder="请输入项目经理"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">本次得分</span>
          <el-input
            v-model="CurrentScore"
            class="flex1"
            placeholder="请输入本次得分"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">本次排名</span>
          <el-input
            v-model="CurrentRank"
            class="flex1"
            placeholder="请输入本次排名"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">上次得分</span>
          <el-input
            v-model="LastScore"
            class="flex1"
            placeholder="请输入上次得分"
            size="small"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">上次排名</span>
          <el-input
            v-model="LastRank"
            class="flex1"
            placeholder="请输入上次排名"
            size="small"
          ></el-input>
        </div>
      </div>
      <div class="tab__footer flex row justify-end items-center">
        <GreenButton
          :options="{
                    borderColor: 'rgba(26,89,184,0.33)',
                    borderRadius: '5px',
                    color: '#1A59B8 !important'
          }"
          class="button__cancel button__gap"
          text="取消"
          @click="cancelClick"
        ></GreenButton>
        <GreenButton
          :loading="createLoading"
          class="button__default"
          loading-text="处理中"
          text="确定"
          @click="onCreateClick"
        ></GreenButton>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'

export default {
  name: 'CreditEvaluationDialog',
  mixins: [],
  components: {
    GreenButton
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: -1
    },
    object: {
      type: Object,
      default: () => {
      }
    },
    category: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: false,
      exportLoading: false,
      projectName: '',
      projectManager: '',
      CurrentScore: '',
      CurrentRank: '',
      LastScore: '',
      LastRank: '',
      createLoading: false
    }
  },
  computed: {
    title() {
      return this.isEdit ? '外部信用评价编辑' : '外部信用评价填报'
    }
  },
  watch: {
    visible(visible) {
      if (visible) {
        this.dialogVisible = true
        this.projectName = ''
        this.LastScore = ''
        this.projectManager = ''
        this.CurrentScore = ''
        this.CurrentRank = ''
        this.LastRank = ''
        if (this.isEdit) {
          this.getProgressInfo()
        }
      }
    }
  },
  methods: {
    onBeforeClose(done) {
      this.$emit('closeDialog')
      this.clearClick()
      done()
    },

    /**
     * 获取进度履约数据
     * */
    async getProgressInfo() {
      const { success, data } = await this.$http({
        url: this.$api.getQueryCreditEvaluationDetail,
        params: {
          id: this.id
        }
      })
      if (success) {
        const {
          ProjectCode,
          ProjectName,
          LastScore,
          Manager,
          CurrentRank,
          CurrentScore,
          LastRank,
        } = data
        this.projectName = ProjectName === null ? '' : ProjectName
        this.LastScore = LastScore === null ? '' : LastScore
        this.projectManager = Manager === null ? '' : Manager
        this.CurrentScore = CurrentScore === null ? '' : CurrentScore
        this.CurrentRank = CurrentRank === null ? '' : CurrentRank
        this.LastRank = LastRank === null ? '' : LastRank
      }
    },
    /**
     * 创建进度履约
     * */
    async onCreateClick() {
      if (this.projectName === '') {
        this.$message.error('请输入项目名称')
        return
      }
      if (this.projectManager === '') {
        this.$message.error('请输入项目经理')
        return
      }
      if (this.CurrentScore === '') {
        this.$message.error('请输入本次得分')
        return
      }
      if (this.CurrentRank === '') {
        this.$message.error('请输入本次排名')
        return
      }
      if (this.LastScore === '') {
        this.$message.error('请输入上次得分')
        return
      }
      if (this.LastRank === '') {
        this.$message.error('请输入上次排名')
        return
      }
      if (this.isEdit) {
        await this.updateChange()
      }
      else {
        await this.createChange()
      }
    },
    /**
     * 进度履约 事件
     * */
    async createChange() {
      this.createLoading = true
      const { Type, Year } = this.object
      const { success, data } = await this.$http({
        url: this.$api.goAddCreditEvaluation,
        method: 'POST',
        data: {
          ProjectName: this.projectName,
          LastScore: this.LastScore,
          Manager: this.projectManager,
          DayCount: this.ContractDate,
          CurrentScore: this.CurrentScore,
          CurrentRank: this.CurrentRank,
          LastRank: this.LastRank,
          Category: this.category,
          Year: Year,
          Type: Type
        }
      })
      if (success) {
        this.$emit('refresh')
        this.clearClick()
        this.$message.success('填报成功')
      }
      this.createLoading = false
    },
    /**
     * 更新进度履约
     * */
    async updateChange() {
      this.createLoading = true
      const { Type, Year } = this.object
      const { success, data } = await this.$http({
        url: this.$api.goUpdateCreditEvaluation,
        method: 'POST',
        data: {
          ID: this.id,
          ProjectName: this.projectName,
          LastScore: this.LastScore,
          Manager: this.projectManager,
          DayCount: this.ContractDate,
          CurrentScore: this.CurrentScore,
          CurrentRank: this.CurrentRank,
          LastRank: this.LastRank,
          Category: this.category,
          Year: Year,
          Type: Type
        }
      })
      if (success) {
        this.$emit('refresh')
        this.clearClick()
        this.$message.success('编辑成功')
      }
      this.createLoading = false
    },
    /**
     *  取消 事件
     * */
    cancelClick() {
      this.$emit('closeDialog')
      this.clearClick()
    },
    /**
     *  清空 事件
     * */
    clearClick() {
      this.projectName = ''
      this.LastScore = ''
      this.projectManager = ''
      this.CurrentScore = ''
      this.CurrentRank = ''
      this.LastRank = ''
      this.dialogVisible = false
    }
  }
}
</script>
<style
  lang="scss"
  scoped
>
$tabsDefaultColor: #FFFFFF;
$tabsActiveColor: #42DEFD;
$borderColor: rgba(3, 175, 252, 0.66);

.tab {
  height: 350px;

  &__content {
    overflow: auto;
    height: calc(100% - 74px);
  }

  &__footer {
    height: 59px;
    margin: 1em -2.4em 0;
    padding: 0 2em;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    background-color: #FFFFFF;
  }
}

//dialog
::v-deep.el-dialog__wrapper {
  background-color: rgba(0, 0, 0, .5);
}

::v-deep .el-dialog__title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.85);
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.02) !important;
}

::v-deep .el-dialog__body {
  padding: 30px 34px 0;
}

//dialog
::v-deep .el-tabs {
  margin-bottom: 1em;

  &__header {
    margin: 0;
  }

  &__nav-wrap::after {
    background-color: transparent;
  }

  &__item {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    height: 31px;
    padding: 0 1em;
    color: $tabsDefaultColor;

    &.is-active {
      color: $tabsActiveColor;
    }
  }

  &__active-bar {
    color: $tabsActiveColor;
  }

  &__content {
    display: none;
  }
}
</style>
