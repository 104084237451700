<template>
  <el-dialog
    :append-to-body="true"
    :before-close="onBeforeClose"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :modal="false"
    :title="title"
    :visible.sync="dialogVisible"
    custom-class="common-dialog"
    top="10vh"
    width="620px"
  >
    <div class="tab">
      <div class="tab__content">
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">年份</span>
          <el-date-picker
            v-model="year"
            type="year"
            format="yyyy"
            @change="yearChange"
            placeholder="选择年份"
          ></el-date-picker>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">季度</span>
          <el-select
            v-model="quarter"
            placeholder="请选择季度"
          >
            <el-option
              v-for="item in quarterOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <!--  能耗总量指标完成情况      -->
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">电（万度）</span>
          <el-input
            v-model="electric"
            class="flex1"
            placeholder="请输入电数量"
            size="small"
            type="number"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">汽油（升）</span>
          <el-input
            v-model="gasoline"
            class="flex1"
            placeholder="请输入汽油数量"
            size="small"
            type="number"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">液化石油气（千克）</span>
          <el-input
            v-model="LPG"
            class="flex1"
            placeholder="请输入液化石油气数量"
            size="small"
            type="number"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">折合实际能耗总量（吨标准煤）</span>
          <el-input
            v-model="actualTotalConsume"
            class="flex1"
            placeholder="请输入折合实际能耗总量"
            size="small"
            type="number"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">年度能耗总量指标（吨标准煤）</span>
          <el-input
            v-model="yearTarget"
            class="flex1"
            placeholder="请输入年度能耗总量指标"
            size="small"
            type="number"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">折合实际能耗总量占年度能耗总量指标百分比</span>
          <el-input
            v-model="rate"
            class="flex1"
            placeholder="请输入百分比数字"
            size="small"
          ></el-input>
        </div>
        <!--        万元营业收入能耗总量指标完成情况-->
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">折算实际能耗总量（吨标准煤）</span>
          <el-input
            v-model="actualIncomeValuation"
            class="flex1"
            placeholder="请输入折算实际能耗总量"
            size="small"
            type="number"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">实际营业收入估值 （万元）</span>
          <el-input
            v-model="incomeTotalConsume"
            class="flex1"
            placeholder="请输入实际营业收入估值"
            size="small"
            type="number"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">实际万元营业收入能耗（吨标准煤/万元）</span>
          <el-input
            v-model="incomeConsume"
            class="flex1"
            placeholder="请输入实际万元营业收入能耗"
            size="small"
            type="number"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">万元营业收入能耗指标（吨标准煤/万元）</span>
          <el-input
            v-model="incomeConsumeTarget"
            class="flex1"
            placeholder="请输入万元营业收入能耗指标"
            size="small"
            type="number"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">实际万元营业收入能耗低于、等于或高于万元营业收入能耗指标</span>
          <el-select
            v-model="level"
            placeholder="请选择"
          >
            <el-option
              v-for="item in levelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="tab__footer flex row justify-end items-center">
        <GreenButton
          :options="{
                    borderColor: 'rgba(26,89,184,0.33)',
                    borderRadius: '5px',
                    color: '#1A59B8 !important'
                     }"
          class="button__cancel button__gap"
          text="取消"
          @click="cancelClick"
        ></GreenButton>
        <GreenButton
          :loading="createLoading"
          class="button__default"
          loading-text="处理中"
          text="确定"
          @click="onCreateClick"
        ></GreenButton>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'

export default {
  name: 'CreateQuarterWorkDialog',
  mixins: [],
  components: {
    GreenButton
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    yearProp: {
      type: String,
      default: new Date().getFullYear().toString()
    },
    quarterProp: {
      type: String,
      default: '0'
    }
  },
  data() {
    return {
      dialogVisible: false,
      exportLoading: false,
      createLoading: false,
      //年度
      year: new Date().getFullYear().toString(),
      //电  electricTarget
      electric: '',
      // 汽油
      gasoline: '',
      // 	液化石油气
      LPG: '',
      // 折合实际能耗总量
      actualTotalConsume: '',
      // 年度能耗总量指标
      yearTarget: '',
      // 折合实际能耗总量占年度能耗总量指标百分比
      rate: '',
      // 折算实际能耗总量
      actualIncomeValuation: '',
      // 实际营业收入估值
      incomeTotalConsume: '',
      //实际万元营业收入能耗
      incomeConsume: '',
      // 	万元营业收入能耗指标
      incomeConsumeTarget: '',
      // 实际万元营业收入能耗低于，等于或高于万元营业收入能耗指标
      level: '',
      levelOptions: [
        {
          value: '低于',
          label: '低于'
        },
        {
          value: '等于',
          label: '等于'
        },
        {
          value: '高于',
          label: '高于'
        }
      ],
     //季度
      quarter: '',
      quarterOptions: [
      {
        value: '1',
        label: '第一季度'
      },
      {
        value: '2',
        label: '第二季度'
      },
      {
        value: '3',
        label: '第三季度'
      },
      {
        value: '4',
        label: '第四季度'
      }
    ],
  }
  },
  computed: {
    title() {
      return this.isEdit ? '年度指标编辑' : '年度指标填报'
    }
  },
  watch: {
    visible(visible) {
      if (visible) {
        this.dialogVisible = true;
        this.year = new Date().getFullYear().toString();
        this.quarter = this.getCurrentQuarterByMonth()
        this.electric = '';
        this.gasoline = '';
        this.LPG = '';
        this.actualTotalConsume = '';
        this.yearTarget = '';
        this.rate = '';
        this.actualIncomeValuation = '';
        this.incomeTotalConsume = '';
        this.incomeConsume = '';
        this.incomeConsumeTarget = '';
        this.level = '';
        if (this.isEdit) {
          this.getDataDetailInfo()
        }
      }
    }
  },
  methods: {
    onBeforeClose(done) {
      this.$emit('closeDialog')
      this.clearClick()
      done()
    },

    /**
     * 获取进度履约数据,表单赋值
     * */
    async getDataDetailInfo() {
      const { success, data } = await this.$http({
        url: this.$api.queryQuarterEnvProWork,
        params: {
          year: this.yearProp,
          quarter:this.quarterProp
        }
      })
      if (success) {
        const {
          Electric,
          Gasoline,
          LPG,
          ActualTotalConsume,
          YearTarget,
          Rate,
          ActualIncomeValuation,
          IncomeTotalConsume,
          IncomeConsume,
          IncomeConsumeTarget,
          Level,
          Year,
          Quarter
        } = data
        this.year = Year.toString();
        this.quarter = Quarter.toString();
        this.electric = Electric || '';
        this.gasoline = Gasoline || '';
        this.LPG = LPG || '';
        this.actualTotalConsume = ActualTotalConsume || '';
        this.yearTarget = YearTarget || '';
        this.rate = Rate || '';
        this.actualIncomeValuation = ActualIncomeValuation || '';
        this.incomeTotalConsume = IncomeTotalConsume || '';
        this.incomeConsume = IncomeConsume || '';
        this.incomeConsumeTarget = IncomeConsumeTarget || '';
        this.level = Level || '';
      }
    },
    /**
     * 点击确认按钮
     * */
    async onCreateClick() {
      if (this.year === '') {
        this.$message.error('请输入年份')
        return
      }
      if (this.quarter === '') {
        this.$message.error('请选择季度')
        return
      }
      await this.createChange()
    },
    /**
     * 创建或者编辑
     * */
    async createChange() {
      this.createLoading = true
      const { success } = await this.$http({
        url: this.$api.addOrUpdateQuarterEnvProWork,
        method: 'POST',
        data: {
          Year: this.year,
          Quarter:this.quarter,
          Electric:this.electric || 0,
          Gasoline:this.gasoline || 0,
          LPG:this.LPG || 0,
          ActualTotalConsume:this.actualTotalConsume || 0,
          YearTarget:this.yearTarget || 0,
          Rate:this.rate || 0,
          ActualIncomeValuation:this.actualIncomeValuation || 0,
          IncomeTotalConsume:this.incomeTotalConsume || 0,
          IncomeConsume:this.incomeConsume || 0,
          IncomeConsumeTarget:this.incomeConsumeTarget || 0,
          Level:this.level || '',
        }
      })
      if (success) {
        this.$emit('refresh')
        this.clearClick()
        this.$message.success('填报成功')
      }
      this.createLoading = false
    },
    /**
     *  取消 事件
     * */
    cancelClick() {
      this.$emit('closeDialog')
      this.clearClick()
    },
    /**
     *  清空 事件
     * */
    clearClick() {
      this.year = new Date().getFullYear().toString();
      this.quarter = this.getCurrentQuarterByMonth()
      this.electric = '';
      this.gasoline = '';
      this.LPG = '';
      this.actualTotalConsume = '';
      this.yearTarget = '';
      this.rate = '';
      this.actualIncomeValuation = '';
      this.incomeTotalConsume = '';
      this.incomeConsume = '';
      this.incomeConsumeTarget = '';
      this.level = '';
      this.dialogVisible = false
    },
    //年份改变
    yearChange(time) {
      let year = new Date(time).getFullYear().toString()
      this.year = year
    },
    //获取当前的季度,传给后端
    getCurrentQuarterByMonth() {
      let date = new Date()
      let month = date.getMonth() + 1
      if (month >= 1 && month <= 3) {
        return '1'
      }
      else if (month >= 4 && month <= 6) {
        return '2'
      }
      else if (month >= 7 && month <= 9) {
        return '3'
      }
      else if (month >= 10 && month <= 12) {
        return '4'
      }
    }
  }
}
</script>
<style
  lang="scss"
  scoped
>
$tabsDefaultColor: #FFFFFF;
$tabsActiveColor: #42DEFD;
$borderColor: rgba(3, 175, 252, 0.66);

.tab {
  height: 700px;

  &__content {
    overflow: auto;
    height: calc(100% - 74px);
  }

  &__footer {
    height: 59px;
    margin: 1em -2.4em 0;
    padding: 0 2em;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    background-color: #FFFFFF;
  }
}

//dialog
::v-deep.el-dialog__wrapper {
  background-color: rgba(0, 0, 0, .5);
}

::v-deep .dialog-label {
  min-width: 300px;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.85);
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.02) !important;
}

::v-deep .el-dialog__body {
  padding: 30px 34px 0;
}

.label-box__gap {
  margin-bottom: 1.5em;
}

::v-deep .el-date-editor.el-input {
  width: 100%;
}

::v-deep .el-select {
  width: 100%
}

//dialog
::v-deep .el-tabs {
  margin-bottom: 1em;

  &__header {
    margin: 0;
  }

  &__nav-wrap::after {
    background-color: transparent;
  }

  &__item {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    height: 31px;
    padding: 0 1em;
    color: $tabsDefaultColor;

    &.is-active {
      color: $tabsActiveColor;
    }
  }

  &__active-bar {
    color: $tabsActiveColor;
  }

  &__content {
    display: none;
  }
}
</style>
