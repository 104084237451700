<template>
  <div id="creditChina">
    <div class="flex row top-bar_wrapper">
      <div
        class="progress-name progress-name-active pointer"
      >信用中国
      </div>
    </div>
    <div
      class="flex flex1 justify-between item-center button-title__box"
    >
      <div class="flex justify-start">
        <GreenButton
          class="button__default"
          text="填报"
          @click="createChange"
        ></GreenButton>
      </div>
    </div>
    <el-table
      :data="dataList"
      :header-cell-style="{background: 'rgba(0,0,0,0.02)',borderTop:'1px solid rgba(0,0,0,0.1)'}"
      :loading="loading"
      class="table-box"
      height="calc(100% - 165px)"
    >
      <el-table-column
        label="行政处罚决定书文号"
        prop="DocumentNo"
      ></el-table-column>
      <el-table-column
        label="处罚类别"
        prop="Category"
        width="200"
      ></el-table-column>
      <el-table-column
        label="罚款金额(万元)"
        prop="Amount"
        width="200"
      ></el-table-column>
      <el-table-column
        label="处罚决定日期"
        prop="DecideDate"
        width="120"
      >
        <template #default="scope">
          <span>{{ scope.row['DecideDate'] | timeDateFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="160"
      >
        <template #default="scope">
          <el-link
            type="primary"
            @click="editInfo(scope.row)"
          >
            编辑
          </el-link>
          <el-link
            style="margin-left: 1em;"
            type="primary"
            @click="deleteInfo(scope.row)"
          >
            删除
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <div
      class="table-box__pagination flex flex1 justify-end items-center"
    >
      <el-pagination
        :current-page.sync="pageIndex"
        :page-size.sync="pageSize"
        :total="allTotal"
        layout="total, prev, pager, next"
        @current-change="onPageIndexChange"
      ></el-pagination>
    </div>
    <CreditChinaDialog
      :id="currentID"
      :is-edit="isCreateEdit"
      :visible="createDialogVisible"
      @closeDialog="onImportDialogClose"
      @refresh="onRefreshDialogClose"
    ></CreditChinaDialog>
  </div>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'
import CreditChinaDialog from '@/components/dialog/CreditChinaDialog'

let _this = void 0
export default {
  name: 'creditChina',
  mixins: [],
  components: { GreenButton, CreditChinaDialog },
  props: {},
  data() {
    return {
      pageIndex: 1,
      pageSize: 11,
      allTotal: 0,
      loading: false,
      dataList: [],
      createDialogVisible: false,
      isCreateEdit: false,
      currentID: -1
    }
  },
  computed: {
    //...mapState('', {}),
  },
  watch: {},
  filters: {
    timeDateFilter(date) {
      return typeof date === 'string' ? date.split('T')[0] : ''
    },
  },
  directives: {},
  created() {
    _this = this
  },
  mounted() {
    this.getDataInfo()
  },
  destroy() {
  },
  methods: {
    //...mapMutations('', []),
    /**
     * 获取南方工程简报数据
     * */
    async getDataInfo() {
      this.loading = true
      const { success, data } = await this.$http({
        url: this.$api.getPageQueryCreditHistory,
        params: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize
        }
      })
      if (success) {
        const { rows, total } = data
        if (data.hasOwnProperty('rows')) {
          this.dataList = [...rows]
          if (data.hasOwnProperty('total')) {
            this.allTotal = total
          }
        }
      }
      this.loading = false
    },
    /**
     * 切换分页
     * */
    onPageIndexChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getDataInfo()
    },
    /**
     * 填报 选择
     * */
    createChange() {
      this.createDialogVisible = true
    },
    /**
     * 填报 关闭dialog回调
     * */
    onImportDialogClose() {
      this.isCreateEdit = false
      this.createDialogVisible = false
    },
    /**
     * 填报 刷新dialog回调
     * */
    onRefreshDialogClose() {
      this.isCreateEdit = false
      this.createDialogVisible = false
      this.pageIndex = 1
      this.getDataInfo()
    },
    /**
     * 编辑信息
     * */
    editInfo(item) {
      const { ID } = item
      this.currentID = ID
      this.isCreateEdit = true
      this.createDialogVisible = true
    },
    /**
     * 删除信息
     * */
    deleteInfo(item) {
      const { ID } = item
      this.$confirm('是否确认删除', '删除信用中国', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '删除中...'
            setTimeout(async () => {
              await this.deleteChange(ID)
              done()
              setTimeout(() => {
                instance.confirmButtonLoading = false
              }, 300)
            }, 300)
          }
          else {
            done()
          }
        }
      })
    },
    /**
     * 确认删除
     * */
    async deleteChange(ID) {
      const { success } = await this.$http({
        url: this.$api.goDeleteCreditHistory,
        params: {
          id: ID
        }
      })
      if (success) {
        this.$message.success('删除成功')
        this.pageIndex = 1
        await this.getDataInfo()
      }
    }
  }
}
</script>
<style
  lang="scss"
  scoped
>
#creditChina {
  height: 100%;
}

::v-deep .el-link.el-link--primary {
  color: #005BAC;
}
</style>
<style lang="scss"></style>
