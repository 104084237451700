<template>
  <div>
    <el-container>
      <el-header
        class="flex justify-between items-center"
      >
        <div
          class="flex justify-start items-center name-gap"
        >
          <span class="head-title">中铁建南方公司 工程管理运维系统</span>
          <div class="head-line"></div>
          <span class="head-name">后台管理</span>
        </div>
        <div class="flex justify-end items-center">
          <div
            class="flex items-center row user-gap"
          >
            <el-image
              :src="ICON_USER"
              class="user-image"
            ></el-image>
            <span class="head-name">{{ USER_INFO().DisplayName }}</span>
          </div>
          <span
            class="flex  pointer head-title"
            style="font-size: 12px"
            @click="loginOut"
          >退出</span>
        </div>
      </el-header>
    </el-container>
    <el-container>
      <el-aside
        width="200px"
      >
        <div class="modules-title">选择系统模块</div>
        <el-menu
          :default-active="currentPathName"
          @select="handleSelect"
        >
          <el-menu-item index="OutputManagement">
            <template slot="title">
              产值管理
            </template>
          </el-menu-item>
          <el-menu-item index="ProgressPerformance">
            <template slot="title">
              进度履约
            </template>
          </el-menu-item>
          <el-menu-item index="MainProject">
            <template slot="title">
              重点工程
            </template>
          </el-menu-item>
          <el-menu-item index="ProcessInspection">
            <template slot="title">
              过程检查
            </template>
          </el-menu-item>
          <el-menu-item index="ReduceEmissions">
            <template slot="title">
              节能环保
            </template>
          </el-menu-item>
          <el-menu-item index="MachineryManagement">
            <template slot="title">
              大型机械管理
            </template>
          </el-menu-item>
          <el-menu-item index="CreditEvaluation">
            <template slot="title">
              外部信用评价
            </template>
          </el-menu-item>
          <el-menu-item index="SiteStandardization">
            <template slot="title">
              施工现场标准化
            </template>
          </el-menu-item>
          <el-menu-item index="SubcontractingManagement">
            <template slot="title">
              分包管理
            </template>
          </el-menu-item>
          <el-menu-item index="SouthernEngineering">
            <template slot="title">
              南方工程简报
            </template>
          </el-menu-item>
          <el-menu-item index="CreditChina">
            <template slot="title">
              信用中国
            </template>
          </el-menu-item>
          <el-menu-item index="ProjectOrganization">
            <template slot="title">
              项目群管理
            </template>
          </el-menu-item>
          <el-menu-item index="ProjectWarning">
            <template slot="title">
              项目进度履约预警
            </template>
          </el-menu-item>
          <el-menu-item index="UserManagement">
            <template slot="title">
              用户管理
            </template>
          </el-menu-item>
          <el-menu-item index="RoleManagement">
            <template slot="title">
              角色管理
            </template>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>
<script>
let _this = void 0
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'mainIndex',
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      ICON_USER: require('@/assets/main/head-user.png'),
      currentPathName: ''
    }
  },
  computed: {
    //...mapState('', {}),
  },
  watch: {},
  filters: {},
  directives: {},
  created() {
    _this = this
  },
  mounted() {
    this.currentPathName = this.getRoutePathName() === 'CreditEvaluationDetail'
      ?
      'CreditEvaluation'
      :
      this.getRoutePathName()
  },
  destroy() {
  },
  methods: {
    ...mapMutations(['setRouter']),
    ...mapGetters('user', {
      USER_INFO: 'USER_INFO',
    }),
    ...mapGetters(['getRoutePathName']),
    //...mapMutations('', []),
    async handleSelect(key, keyPath) {
      await this.setRouter({ name: key })
      this.currentPathName = this.getRoutePathName()
    },
    async loginOut() {
      window.sessionStorage.clear()
      await this.setRouter({ name: 'Login' })
    }
  },
}
</script>
<style
  lang="scss"
  scoped
>
::v-deep .el-menu-item.is-active {
  font-size: 14px;
  color: #FFFFFF !important;
  background: linear-gradient(90deg, #1A59B8, rgba(0, 112, 223, 0.66)) !important;
  border-radius: 4px;
}
</style>
<style lang="scss">
.name-gap {
  margin-left: 60px;
}

.el-container {
  background-color: #F5F5F5;
}

.head-line {
  width: 1px;
  height: 20px;
  margin: 0 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.66);
}

.head-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #FFFFFF;
}

.head-name {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #FFFFFF;
}

.user-gap {
  margin-right: 30px;
}

.user-image {
  width: 24px;
  height: 24px;
  margin-right: 0.5em;
}

.modules-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  color: #888888;
  margin-left: -65px;
}

.el-header {
  line-height: 60px;
  color: #333333;
  background: linear-gradient(270deg, #005BAC, #0070DF);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.el-main {
  height: calc(100vh - 95px);
  overflow: hidden;
  margin: 1em;
  background-color: #FFFFFF;
  border-right: 20px;
  position: relative;
  padding-top: 32px;
}

.el-aside {
  height: calc(100vh - 95px) !important;
  overflow: hidden;
  background-color: #FFFFFF;
  margin-top: 1em;
}

.el-menu-item {
  height: 40px;
  line-height: 40px;
  text-align: left;
  margin: 10px 1em
}

.el-menu {
  border-right: none;
}
</style>
