var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"append-to-body":true,"before-close":_vm.onBeforeClose,"close-on-click-modal":false,"destroy-on-close":true,"modal":false,"visible":_vm.dialogVisible,"custom-class":"common-dialog","title":"上传","top":"16vh","width":"500px"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"tab"},[_c('div',{staticClass:"tab__content"},[(_vm.type !== 'standardization')?_c('div',{staticClass:"flex row justify-between items-center label-box__gap"},[_c('span',{staticClass:"dialog-label"},[_vm._v("类型")]),_c('el-select',{staticClass:"flex1",attrs:{"placeholder":"请选择类型","size":"small"},model:{value:(_vm.categoryName),callback:function ($$v) {_vm.categoryName=$$v},expression:"categoryName"}},_vm._l((_vm.categoryNameOptions),function(ref,categoryIndex){
var label = ref.label;
var value = ref.value;
return _c('el-option',{key:(label + "-" + value + "-" + categoryIndex),attrs:{"label":label,"value":value}})}),1)],1):_vm._e(),_c('div',{staticClass:"flex justify-start"},[_c('span',{staticClass:"dialog-label"},[_vm._v("附件")]),_c('el-upload',{ref:"upload",attrs:{"auto-upload":false,"before-upload":_vm.beforeAvatarUpload,"http-request":_vm.uploadFile,"multiple":true,"action":""}},[_c('div',{staticClass:"flex justify-start"},[_c('GreenButton',{staticClass:"button__default",attrs:{"slot":"trigger","text":"附件"},slot:"trigger"})],1)])],1)]),_c('div',{staticClass:"tab__footer flex row justify-end items-center"},[_c('GreenButton',{staticClass:"button__cancel button__gap",attrs:{"options":{
           borderColor: 'rgba(26,89,184,0.33)',
           borderRadius: '5px',
           color: '#1A59B8 !important'
        },"text":"取消"},on:{"click":_vm.cancelExportClick}}),_c('GreenButton',{staticClass:"button__default",attrs:{"loading":_vm.exportLoading,"loading-text":"处理中","text":"确定"},on:{"click":_vm.onExportClick}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }