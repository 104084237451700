<template>
  <div class="process-check-result-wrapper">
    <div class="flex flex1 justify-between item-center button-title__box">
      <div class="flex justify-start">
        <GreenButton
          class="button__default"
          text="填报"
          @click="createChange"
        ></GreenButton>
      </div>
    </div>
    <el-table
      :data="tableData"
      :header-cell-style="{background: 'rgba(0,0,0,0.02)',borderTop:'1px solid rgba(0,0,0,0.1)'}"
      :loading="loading"
      height="calc(100% - 165px)"
      style="width: 100%"
    >
      <el-table-column
        prop="Title"
        label="标题"
        min-width="200"
      ></el-table-column>
      <el-table-column
        prop="CheckUser"
        label="检查人"
      ></el-table-column>
      <el-table-column
        label="检查日期"
      >
        <template #default="scope">
          <div>{{scope.row['CheckDate'] | timeDateFilter(scope.row['CheckDate'] )}}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="ItemCount"
        label="隐患数量"
      ></el-table-column>
      <el-table-column
        label="检查结果"
      >
        <template #default="scope">
          <el-link
            type="primary"
            style="margin-left: 1em;"
            @click="checkAddOrEditInfo(scope.row)"
          >
            填写
          </el-link>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="200"
      >
        <template #default="scope">
          <el-link
            type="primary"
            style="margin-left: 1em;"
            @click="editInfo(scope.row)"
          >
            编辑
          </el-link>
          <el-link
            type="primary"
            style="margin-left: 1em;"
            @click="deleteInfo(scope.row)"
          >
            删除
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <div
      class="table-box__pagination flex flex1 justify-end items-center"
    >
      <el-pagination
        :current-page.sync="pageIndex"
        :page-size.sync="pageSize"
        :total="totalNum"
        layout="total, prev, pager, next"
        @current-change="onPageIndexChange"
      ></el-pagination>
    </div>
<!--  新增编辑列表数据dialog-->
    <CreateProcessCheckResultDialog
      :id="currentID"
      :is-edit="isEdit"
      :visible="createDialogVisible"
      @closeDialog="onImportDialogClose"
      @refresh="onRefreshDialogClose"
    ></CreateProcessCheckResultDialog>
<!--  新增编辑列表上的检查项dialog-->
    <CreateRowsCheckResultDialog
      :id="currentID"
      :is-edit="isRowEdit"
      :visible="createRowDialogVisible"
      @closeDialog="onImportDialogClose"
      @refresh="onRefreshDialogClose"
    ></CreateRowsCheckResultDialog>
  </div>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton';
import CreateProcessCheckResultDialog from '@/components/dialog/energyConservation/CreateProcessCheckResultDialog'
import CreateRowsCheckResultDialog from '@/components/dialog/energyConservation/CreateCheckResultDialog'
export default {
  name: 'processCheckResult',
  components:{
    GreenButton,
    CreateProcessCheckResultDialog,
    CreateRowsCheckResultDialog
  },
  data(){
    return{
      tableData: [],
      loading: false,
      pageIndex:1,
      pageSize:10,
      totalNum:0,
      isEdit:false,
      createDialogVisible:false,
      currentID:0,
      //检查项弹框dialog
      createRowDialogVisible:false,
      isRowEdit:false

    }
  },
  mounted() {
    this.getProcessCheckData()
  },
  filters: {
    timeDateFilter(date) {
      return typeof date === 'string' ? date.split('T')[0] : ''
    }
  },
  methods:{
    //获取表格数据
   async getProcessCheckData(){
     this.loading = true
     const { success, data } = await this.$http({
       url: this.$api.pageQueryProcessControlCheckResult,
       params: {
         pageIndex: this.pageIndex,
         pageSize: this.pageSize,
       }
     })
     if (success) {
       this.loading = false
       const { rows, total } = data
       this.tableData = rows
       this.totalNum = total
     }
    },
    // 点击填报按钮
    createChange(){
      this.isEdit = false;
      this.createDialogVisible = true;
      this.currentID = 0;
    },
    //列表编辑
    editInfo(row){
      this.isEdit = true;
      this.createDialogVisible = true;
      this.currentID = row.ID
    },
    // 检查项新增或者编辑
    checkAddOrEditInfo(row){
      this.createRowDialogVisible= true;
      this.currentID = row.ID
    },
    /**
     * 删除行数据
     * type === checkResult 删除检查结果  type === row 删除行数据
     * */
    deleteInfo(item) {
      const { ID } = item
      this.$confirm('是否确认删除', '删除过程管控检查', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '删除中...'
            setTimeout(async () => {
              await this.deleteChange(ID)
              done()
              setTimeout(() => {
                instance.confirmButtonLoading = false
              }, 300)
            }, 300)
          }
          else {
            done()
          }
        }
      })
    },
    /**
     * 确认行数据删除
     * */
    async deleteChange(ID, type) {
      const { success } = await this.$http({
        url:this.$api.deleteProcessControlCheckResult,
        params: {
          id: ID
        }
      })
      if (success) {
        this.$message.success('删除成功')
        this.pageIndex = 1
        await this.getProcessCheckData()
      }
    },
    // 分页改变事件
    onPageIndexChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getProcessCheckData()
    },
    /**
     * 填报 关闭dialog回调
     * */
    onImportDialogClose() {
      this.isEdit = false
      this.createDialogVisible = false;
      this.createRowDialogVisible = false
    },
    /**
     * 填报 刷新dialog回调
     * */
    onRefreshDialogClose() {
      this.isEdit = false
      this.createDialogVisible = false;
      this.createRowDialogVisible = false
      this.pageIndex = 1
      this.getProcessCheckData()
    },
  }
}
</script>
<style scoped>
.process-check-result-wrapper{
  height: 100%;
}
</style>
