<template>
  <div class="standardization-notification-wrapper">
    <div
      class="flex flex1 justify-between item-center button-title__box"
    >
      <div class="flex justify-start">
        <GreenButton
          class="button__default"
          text="导入"
          @click="createChange"
        ></GreenButton>
      </div>
      <div
        class="flex justify-end items-center"
      >
        <el-date-picker
          v-model="selectYear"
          size="small"
          class="flex1"
          placeholder="选择月份"
          type="year"
          @change="selectYearChange"
        ></el-date-picker>
      </div>
    </div>
    <el-table
      :data="projectData"
      :header-cell-style="{background: 'rgba(0,0,0,0.02)',borderTop:'1px solid rgba(0,0,0,0.1)'}"
      :loading="projectLoading"
      :span-method="objectSpanMethod"
      class="table-box"
      height="calc(100% - 165px)"
    >
      <el-table-column
        label="分包企业名称"
        prop="Company"
      ></el-table-column>
      <el-table-column
        label="分包商名称"
        prop="Leader"
        width="140"
      ></el-table-column>
      <el-table-column
        label="评价等级"
        prop="Level"
        width="100"
      ></el-table-column>
      <el-table-column
        label="作业类别"
        prop="Category"
        width="120"
      ></el-table-column>
      <el-table-column
        label="拟合作产值任务量(万元)"
        prop="Total"
        width="120"
      ></el-table-column>
      <el-table-column
        label="已签合同额(万元)"
        prop="ContractAmount"
        width="140"
      ></el-table-column>
      <el-table-column
        label="合同起止时间"
        prop="StartAndEndDate"
        width="180"
      ></el-table-column>
      <el-table-column
        label="所在项目"
        prop="Project"
        width="200"
      ></el-table-column>
      <el-table-column
        label="已结算金额"
        prop="SettlementTotalAmount"
        width="160"
      ></el-table-column>
    </el-table>
    <div
      class="table-box__pagination flex flex1 justify-end items-center"
    >
      <el-pagination
        :current-page.sync="pageIndex"
        :page-size.sync="pageSize"
        :total="allTotal"
        layout="total, prev, pager, next"
        @current-change="onPageIndexChange"
      ></el-pagination>
    </div>
    <ImportProjectStatus
      :visible="importDialogVisible"
      type="regional"
      @refresh="onRefreshDialogClose"
      @closeDialog="onImportDialogClose"
    ></ImportProjectStatus>
  </div>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'
import ImportProjectStatus from '@/components/dialog/ImportProjectStatus'

export default {
  name: 'subcontractingRegional',
  components: {
    GreenButton,
    ImportProjectStatus
  },
  filters: {},
  data() {
    return {
      pageIndex: 1,
      pageSize: 11,
      allTotal: 0,
      projectLoading: false,
      projectData: [],
      importDialogVisible: false,
      selectYear: ''
    }
  },
  async mounted() {
    this.selectYear = this.$dateFormat(new Date(), 'yyyy')
    await this.getProjectInfo()
  },
  destroy() {
    // TODO document why this method 'destroy' is empty
  },
  methods: {
    /**
     * 获取 区域战略级分包 数据
     * */
    async getProjectInfo() {
      this.projectLoading = true
      const { success, data } = await this.$http({
        url: this.$api.getPageQueryAreaSubcontractItem,
        params: {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          year: this.selectYear
        }
      })
      if (success) {
        const { rows, total } = data
        if (data.hasOwnProperty('rows')) {
          this.projectData = [...rows]
          let arr = []
          arr = this.projectData
          for (let i = 0; i < arr.length; i++) {
            const item = arr[i]
            this.$set(item, '$order', i + 1)
          }
          if (data.hasOwnProperty('total')) {
            this.allTotal = total
          }
        }
      }
      this.projectLoading = false
    },
    /**
     * 切换分页
     * */
    onPageIndexChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getProjectInfo()
    },
    /**
     * 导入
     * */
    createChange() {
      this.importDialogVisible = true
    },
    /**
     * 导入 关闭dialog回调
     * */
    onImportDialogClose() {
      this.importDialogVisible = false
    },
    /**
     * 导入 刷新dialog回调
     * */
    onRefreshDialogClose() {
      this.importDialogVisible = false
      this.pageIndex = 1
      this.getProjectInfo()
    },
    /**
     * 年份选择 事件
     * */
    selectYearChange(value) {
      this.selectYear = this.$dateFormat(value, 'yyyy')
      this.pageIndex = 1
      this.getProjectInfo()
    },
    /**
     * table 根据条件进行合并列
     * */
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = (this.getMergeTableData(this.projectData).one)[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (columnIndex === 1) {
        const _row = (this.getMergeTableData(this.projectData).two)[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (columnIndex === 2) {
        const _row = (this.getMergeTableData(this.projectData).three)[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (columnIndex === 3) {
        const _row = (this.getMergeTableData(this.projectData).four)[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
      if (columnIndex === 4) {
        const _row = (this.getMergeTableData(this.projectData).five)[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    /**
     * table 条件合并
     * */
    getMergeTableData() {
      let spanOneArr = [],
        spanTwoArr = [],
        spanThreeArr = [],
        spanFourArr = [],
        spanFiveArr = [],
        concatOne = 0,
        concatTwo = 0,
        concatThree = 0,
        concatFour = 0,
        concatFive = 0
      this.projectData.forEach((item, index) => {
        if (index === 0) {
          spanOneArr.push(1)
          spanTwoArr.push(1)
          spanThreeArr.push(1)
          spanFourArr.push(1)
          spanFiveArr.push(1)
        }
        else {
          if (item.Company === this.projectData[index - 1].Company) { //第一列需合并相同内容的判断条件 分包企业名称
            spanOneArr[concatOne] += 1
            spanOneArr.push(0)
          }
          else {
            spanOneArr.push(1)
            concatOne = index
          }
          if (item.Leader === this.projectData[index - 1].Leader) { //第二列需合并相同内容的判断条件 分包商名称
            if (item.Company === this.projectData[index - 1].Company) {
              spanTwoArr[concatTwo] += 1
              spanTwoArr.push(0)
            }
            else {
              spanTwoArr.push(1)
              concatTwo = index
            }
          }
          else {
            spanTwoArr.push(1)
            concatTwo = index
          }
          if (item.Level === this.projectData[index - 1].Level) { //第三列需合并相同内容的判断条件 评价等级
            if (item.Company === this.projectData[index - 1].Company) {
              spanThreeArr[concatThree] += 1
              spanThreeArr.push(0)
            }
            else {
              spanThreeArr.push(1)
              concatThree = index
            }
          }
          else {
            spanThreeArr.push(1)
            concatThree = index
          }
          if (item.Category === this.projectData[index - 1].Category) { //第三列需合并相同内容的判断条件 作业类型
            if (item.Company === this.projectData[index - 1].Company) {
              spanFourArr[concatFour] += 1
              spanFourArr.push(0)
            }
            else {
              spanFourArr.push(1)
              concatFour = index
            }
          }
          else {
            spanFourArr.push(1)
            concatFour = index
          }
          if (item.Total === this.projectData[index - 1].Total) { //第三列需合并相同内容的判断条件 拟合作产值任务
            if (item.Company === this.projectData[index - 1].Company) {
              spanFiveArr[concatFive] += 1
              spanFiveArr.push(0)
            }
            else {
              spanFiveArr.push(1)
              concatFive = index
            }
          }
          else {
            spanFiveArr.push(1)
            concatFive = index
          }
        }
      })
      return {
        one: spanOneArr,
        two: spanTwoArr,
        three: spanThreeArr,
        four: spanFourArr,
        five: spanFiveArr
      }
    }
  }
}
</script>
<style scoped>
.standardization-notification-wrapper {
  height: 100%
}
</style>
