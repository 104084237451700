<template>
  <el-dialog
    :append-to-body="true"
    :before-close="onBeforeClose"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :modal="false"
    :title="title"
    :visible.sync="dialogVisible"
    custom-class="common-dialog"
    top="16vh"
    width="500px"
  >
    <div class="tab">
      <div class="tab__content">
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">昵称</span>
          <el-input
            v-model="displayName"
            class="flex1"
            placeholder="请输入昵称"
            size="small"
          ></el-input>
        </div>
        <div
          v-if="!isEdit"
          class="flex row justify-between items-center label-box__gap"
        >
          <span class="dialog-label">密码</span>
          <el-input
            v-model="password"
            auto-complete="new-password"
            class="flex1"
            placeholder="请输入密码"
            size="small"
            type="password"
          ></el-input>
        </div>
        <div class="flex row justify-between items-center label-box__gap">
          <span class="dialog-label">手机号</span>
          <el-input
            v-model="iphone"
            class="flex1"
            placeholder="请输入手机号"
            size="small"
            onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode)))"
          ></el-input>
        </div>
      </div>
    </div>
    <div class="tab__footer flex row justify-end items-center">
      <GreenButton
        :options="{
                    borderColor: 'rgba(26,89,184,0.33)',
                    borderRadius: '5px',
                    color: '#1A59B8 !important'
          }"
        class="button__cancel button__gap"
        text="取消"
        @click="cancelClick"
      ></GreenButton>
      <GreenButton
        :loading="createLoading"
        class="button__default"
        loading-text="处理中"
        text="确定"
        @click="onCreateClick"
      ></GreenButton>
    </div>
  </el-dialog>
</template>
<script>
import GreenButton from '@/components/buttons/GreenButton'

export default {
  name: 'CreateUserDialog',
  mixins: [],
  components: {
    GreenButton
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      dialogVisible: false,
      exportLoading: false,
      displayName: '',
      password: '',
      iphone: '',
      createLoading: false
    }
  },
  computed: {
    title() {
      return this.isEdit ? '编辑用户' : '添加用户'
    }
  },
  watch: {
    visible(visible) {
      if (visible) {
        this.dialogVisible = true
        this.displayName = ''
        this.password = ''
        this.iphone = ''
        if (this.isEdit) {
          const { DisplayName, MobilePhone } = JSON.parse(window.sessionStorage.getItem('USER_MANAGEMENT_INFO'))
          this.displayName = DisplayName
          this.iphone = MobilePhone
        }
      }
    }
  },
  methods: {
    onBeforeClose(done) {
      this.$emit('closeDialog')
      this.clearClick()
      done()
    },
    /**
     * 用户 确定
     * */
    async onCreateClick() {
      if (this.displayName === '') {
        this.$message.error('请输入昵称')
        return
      }
      if (!this.isEdit) {
        if (this.password === '') {
          this.$message.error('请输入密码')
          return
        }
      }
      if (this.iphone === '') {
        this.$message.error('请输入手机号')
        return
      }
      await this.createChange()
    },
    /**
     * 创建或更新 用户
     * */
    async createChange() {
      let phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!phone.test(this.iphone)) {
        this.$message.error('请输入正确的手机格式')
        return
      }
      this.createLoading = true
      const newData = this.isEdit ? {
        ID: this.id,
        DisplayName: this.displayName,
        MobilePhone: this.iphone,
      } : {
        DisplayName: this.displayName,
        Password: this.password,
        MobilePhone: this.iphone,
      }
      const { success, data } = await this.$http({
        url: this.$api.goAddOrUpdateUser,
        method: 'POST',
        data: newData
      })
      if (success) {
        this.$emit('refresh')
        this.$message.success(this.isEdit ? '更新成功' : '添加成功')
        this.clearClick()
      }
      this.createLoading = false
    },
    /**
     *  取消 事件
     * */
    cancelClick() {
      this.$emit('closeDialog')
      this.clearClick()
    },
    /**
     *  清空 事件
     * */
    clearClick() {
      this.displayName = ''
      this.password = ''
      this.iphone = ''
      this.dialogVisible = false
    }
  }
}
</script>
<style
  lang="scss"
  scoped
>
$tabsDefaultColor: #FFFFFF;
$tabsActiveColor: #42DEFD;
$borderColor: rgba(3, 175, 252, 0.66);

.tab {
  height: 140px;

  &__content {
    overflow: auto;
    height: calc(100%);
  }

  &__footer {
    height: 59px;
    margin: 1em -2.4em 0;
    padding: 0 2em;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    background-color: #FFFFFF;
  }
}

//dialog
::v-deep.el-dialog__wrapper {
  background-color: rgba(0, 0, 0, .5);
}

::v-deep .el-dialog__title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.85);
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.02) !important;
}

::v-deep .el-dialog__body {
  padding: 30px 34px 0;
}

//dialog
::v-deep .el-tabs {
  margin-bottom: 1em;

  &__header {
    margin: 0;
  }

  &__nav-wrap::after {
    background-color: transparent;
  }

  &__item {
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    height: 31px;
    padding: 0 1em;
    color: $tabsDefaultColor;

    &.is-active {
      color: $tabsActiveColor;
    }
  }

  &__active-bar {
    color: $tabsActiveColor;
  }

  &__content {
    display: none;
  }
}
</style>
